<template>
  <!-- <v-card outlined dense class="pb-10"> -->
	  <div>
    <v-card-title
      color="blue"
      class="primary--text caption font-weight-bold  mx-2 mb-3 mt-2"
    >
      <v-icon left small class="white primary--text">mdi-clock-outline</v-icon>
      Resource utilization history
    </v-card-title>

		<div v-if="isMobile" >
    <v-row color="green" class="px-10">
      <v-divider></v-divider>
    </v-row>
	
    <div
      v-for="(resource, resourceIndex) in resourceHistory"
      :key="resourceIndex"
    >
      <v-row dense class="px-3 py-1 mt-3">
        <v-col lg="1" cols="4">
          <div class="caption grey--text ">Date</div>
          <div class="caption primary--text">
            {{ resource.utilizationDate | formatToLocalDate }}
          </div>
        </v-col>
        <v-col lg="1" cols="5">
          <div class="caption grey--text">Allocation</div>
          <div class="caption primary--text ">
            {{ resource.allocatedResourceName }}
          </div>
        </v-col>
        <v-col lg="1" cols="3">
          <div class="caption grey--text">Resource</div>
          <div class="caption primary--text ">
            {{ resource.resourceName }}
          </div>
        </v-col>
		<v-col lg="1" cols="3">
          <div class="caption grey--text">Rate</div>
          <div class="caption primary--text ">
            {{ resource.rate }} {{ `${resolveUnit(resource.utilization_unit)}` }}
          </div>
        </v-col>
        <v-col lg="1" cols="3">
          <div class="caption grey--text text-right mr-2">Duration</div>
          <div class="caption primary--text text-right mr-2">
            {{ resource.duration }}
          </div>
        </v-col>
        <v-col lg="2" cols="4">
          <div class="caption grey--text">Comments</div>
          <div class="caption primary--text ">
            {{ resource.comments }}
          </div>
        </v-col>
		<v-col xs="6" sm="4" md="1" lg="1" align-self="center" cols="1">
          <DeleteAction
            Message="Are you sure to delete this utilization ?"
            @delete="deleteResourceUtilitzation(resourceIndex)"
          />
        </v-col>
      </v-row>
	  <v-divider></v-divider>
    </div>
	</div>

	  <v-data-table
	  v-else
    :headers="headers"
    :items="resourceHistory"
	dense
	hide-default-footer
	calculate-widths
	class="mb-2 mt-2 ma-2 elevation-1 table-striped" >
	 <template #[`item.utilizationDate`]="{item}">
      {{ item.utilizationDate | formatToLocalDate }}
    </template>
	 <template #[`item.rate`]="{item}">
      {{ item.rate }} {{ `${resolveUnit(item.utilization_unit)}` }}
    </template>
	 <template #[`item.delete`]="{item}">
      <DeleteAction
            Message="Are you sure to delete this utilization ?"
            @delete="deleteResourceUtilitzation(resourceHistory.indexOf(item))"
          />
    </template>
  </v-data-table>
	</div>
  <!-- </v-card> -->
</template>
<script>
import { mapGetters } from 'vuex';
import DeleteAction from './DeleteAction2';
export default {
  props: {
    resourceHistory: Array,
  },
  components: {
    DeleteAction,
  },
  data() {
    return {
		headers: [
        { text: 'DATE', value: 'utilizationDate' },
        { text: 'ALLOCATION', value: 'allocatedResourceName' },
        { text: 'RESOURCE', value: 'resourceName' },
        { text: 'RATE', value: 'rate' },
        { text: 'DURATION', value: 'duration' },
        { text: 'COMMENTS', value: 'comments', sortable: false },
        { text: 'DELETE', value: 'delete', sortable: false },
      ],
	//   isMobile: false
	};
  },
  methods: {
    deleteResourceUtilitzation(rowIndex) {
      this.$emit('deleteUtilization', rowIndex);
    },
	resolveUnit(unit) {
		return unit === 1 ? 'Hourly' : 'Fixed'
	}
  },
  computed: {
    ...mapGetters(['userId']),
	isMobile() {
		return window.innerWidth < 600 ? true : false
	}
  },
};
</script>

<style>
/* v-row v-col:nth-of-type(even) {
background-color: rgba(236, 237, 237);
}

v-row v-col:nth-of-type(odd) {
background-color: rgb(250 ,250, 250);
} */

/* 
tbody tr:nth-of-type(even) {
background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
background-color: rgb(250 ,250, 250);
}

.v-data-table-header {
background-color: rgba(182, 183, 187);
color: white;
}

.v-data-footer {
background-color: rgb(250 ,250, 250);
}

.theme--light.v-data-table thead tr th {
color: white;
} */

/* .theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .03);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .5);
}
.theme--light.v-data-table-header {
    background-color: rgba(182, 183, 187);
    color: white;
}
.theme--dark.v-data-footer {
    background-color: rgb(250 ,250, 250);
} */

/* tbody tr:nth-of-type(odd) {
    background-color: #E0F2F1;
}
tbody tr:nth-of-type(even) {
    background-color: #FBE9E7;
} */

</style>
