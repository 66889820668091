<template>
  <div>
    <v-snackbar top v-model="displayStatus" :timeout="5000" color="success">
      <span>Qc status saved successfully</span>
      <v-btn class="mx-2 pl-2 " icon @click="displayStatus = false">
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>

    <v-row no-gutters>
      <v-col xs="12" sm="12" md="6" lg="6">
        <v-card outlined dense class="ma-2">
          <v-card-title
            color="blue"
            class="primary--text caption font-weight-bold  mx-2 "
          >
            <v-icon left small class="white primary--text">
              mdi-calendar-clock
            </v-icon>
            Schedule Details
          </v-card-title>
          <v-row dense class="pb-0 mx-4 mb-4">
            <v-col xs="8" sm="8" md="8" lg="8">
              <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
                <div class="caption grey--text">Workorder:</div>
                <div class="caption primary--text pb-2">
                  {{ workorderId }}
                </div>
                <div class="caption grey--text">Product Name:</div>
                <div class="caption primary--text pb-2">
                  {{ itemName }}
                </div>
                <div class="caption grey--text">Product Code:</div>
                <div class="caption primary--text pb-2">
                  {{ itemCode }}
                </div>
                <div class="caption grey--text">Customer:</div>
                <div class="caption primary--text pb-2">
                  {{ customerName }}
                </div>
                <span class="caption grey--text">Material Total:</span>
                <span class="caption primary--text pb-2">
                  {{ materialTotal }}
                </span>
                <br />
                <span class="caption grey--text">Labour Total:</span>
                <span class="caption primary--text pb-2">
                  {{ labourTotal }}
                </span>
              </v-card>
            </v-col>
            <v-col xs="4" sm="4" md="4" lg="4">
              <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
                <div class="caption grey--text">Stage:</div>
                <div class="caption primary--text pb-2">
                  {{ productionStageName }}
                </div>
                <div class="caption grey--text">Start Date:</div>
                <div class="caption primary--text pb-2">
                  {{ scheduleStartDate | formatToLocalDate }}
                </div>
                <div class="caption grey--text">End Date:</div>
                <div class="caption primary--text pb-2">
                  {{ scheduleEndDate | formatToLocalDate }}
                </div>
                <div class="caption grey--text">Quantity:</div>
                <div class="caption primary--text pb-2">
                  {{ itemQuantity }} {{ itemUnits }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="scheduleComments">
      <v-row no-gutters>
        <v-col xs="12" sm="12" md="6" lg="6">
          <Comments :commentText="scheduleComments" />
        </v-col>
      </v-row>
    </template>
    <v-row no-gutters class="">
      <v-col xs="12" sm="12" md="6" lg="6">
        <v-card outlined dense class="ma-2">
          <v-card-title
            color="blue"
            class="primary--text caption font-weight-bold  mx-2"
          >
            <v-icon left small class="white primary--text">
              mdi-checkbox-multiple-marked
            </v-icon>
            QC Checklist
          </v-card-title>
          <v-row dense class="mx-4 mb-2">
            <v-col auto>
              <v-select
                :items="allocations"
                item-text="allocationName"
                item-value="allocationCode"
                v-model="resourceAllocationId"
                class="caption mt-0 pt-0"
                dense
                outlined
                label="Resource"
                placeholder="Select resource"
                hide-details="false"
                id="resourceAllocationId"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row
            class="d-flex flex-row mx-5 pb-2 my-4 checklist"
            dense
            v-if="checklistStatus.length > 0"
          >
            <v-col auto class="ma-1 pa-0 caption primary--text">
              <v-checkbox
                v-for="(checklist, RowIndex) in checklistStatus"
                :key="RowIndex"
                v-model="selectedItems"
                :value="checklist.checklistId"
                dense
                hide-details
                color="success"
                class="caption"
              >
                <template v-slot:label>
                  <div class="caption black--text">
                    {{ checklist.checklistItem }}
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row mx-3" dense>
            <v-col auto class="ma-0 pa-0">
              <v-radio-group
                v-model="qcStatus"
                row
                class="caption primary--text  ma-0 pa-0 mt-2 ml-4"
              >
                <v-radio :value="qcPending">
                  <template v-slot:label>
                    <v-chip color="orange" outlined small>
                      QC Pending
                    </v-chip>
                  </template>
                </v-radio>
                <v-radio :value="qcPassed">
                  <template v-slot:label>
                    <v-chip color="green" outlined small>
                      QC Passed
                    </v-chip>
                    <!-- <div class="caption primary--text">QC Passed</div> -->
                  </template>
                </v-radio>
                <v-radio :value="qcFailed">
                  <template v-slot:label>
                    <v-chip color="red" outlined small>
                      QC Failed
                    </v-chip>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row mx-4" dense>
            <v-col auto class="mt-0 pt-1 px-1">
              <v-textarea
                outlined
                name="input-7-4"
                label="Comments"
                rows="1"
                dense
                class="caption"
                v-model="statusComment"
                hide-details="false"
                :disabled="commentsDisabled"
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- <v-row dense class="mx-4" v-if="errorMessage.length > 1">
            <v-col auto class="center">
              <h6 class="caption bold red--text pt-2 pb-2">
                {{ errorMessage }}
              </h6>
            </v-col>
          </v-row> -->
          <v-row dense class="mx-4" v-show="isNoResourseSelected">
            <v-col auto class="center">
              <template>
                <v-alert dense text type="warning" class="caption">
                  Select Resource
                </v-alert>
              </template>
            </v-col>
          </v-row>
          <v-row dense class="pb-4 mx-4">
            <v-col cols="9" class="center">
              <v-btn
                small
                depressed
                color="yellow accent-2"
                class="primary--text mb-0"
                @click.stop="showAlert()"
                :disabled="saveInProgress"
              >
                <v-icon left color="primary">
                  mdi-content-save
                </v-icon>
                Save Status
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-dialog
          v-model="isChecklistSaveDialog"
          persistent
          max-width="500px"
          height="500px"
        >
          <v-card>
            <v-card-title>
              Are you sure you want to update QC Status?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                outlined
                @click="isChecklistSaveDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn color="success" @click="saveQcChecklistStatus()">
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Workorders from '@/Services/workorders';
import { StatusCodes } from 'http-status-codes';
import Comments from './comments';
export default {
  name: 'AssignmentAllocation',
  props: {
    scheduleCode: String,
  },
  components: {
    Comments,
  },
  data() {
    return {
      snackbar: false,
      workorderId: null,
      scheduleStartDate: null,
      scheduleEndDate: null,
      itemQuantity: null,
      itemUnits: null,
      itemCode: null,
      itemName: null,
      productionStageName: null,
      workflowName: '',
      statusComment: '',
      scheduleComments: '',
      resourceCode: '',
      allocations: [],
      displayStatus: false,
      errorMessage: '',
      resourceAllocationId: null,
      allocatedQuantity: 0,
      qcStatus: 0,
      qcPending: 0,
      qcPassed: 1,
      qcFailed: -1,
      checklistStatus: [],
      selectedItems: [],
      commentsDisabled: true,
      saveInProgress: false,
      customerName: '',
      materialTotal: null,
      labourTotal: null,
      isChecklistSaveDialog: false,
      isNoResourseSelected: false,
    };
  },
  created() {
	this.$store.commit('showProgressCircular', 500)
	setTimeout(() => {
    	this.$store.dispatch('showMyAssignment', true);
	}, 0);
	this.getResourceAllocations();
  },
  methods: {
    async saveQcChecklistStatus() {
      try {
        if (!this.validatQcChecklistStatus()) return;
        this.saveInProgress = true;
        let qcChecklist = {
          scheduleId: this.getScheduleCode,
          stageAllocationId: this.resourceAllocationId,
          qcStatus: this.qcStatus,
          qcComments: this.statusComment,
          checklistItems: [],
        };
        this.selectedItems.forEach((item) => {
          qcChecklist.checklistItems.push({
            checklistMasterId: item,
          });
        });
        const response = await Workorders.saveQcChecklist(qcChecklist);

        if (response.data.header.success) {
			this.isChecklistSaveDialog = false;
			this.$store.commit('showProgressCircular', 2000)
			setTimeout(() => {
				this.displayStatus = true;
      		}, 2000)
        }
      } catch (error) {
        if (error.response.status == StatusCodes.PRECONDITION_FAILED) {
          this.errorMessage = error.response.data.header.message;
        }
      }
      this.saveInProgress = false;
    },
    openSaveCheckistDialog() {
      this.isChecklistSaveDialog = true;
    },
    closeDialog() {
      this.isChecklistSaveDialog = false;
    },
    async getResourceAllocations() {
      const response = await Workorders.getResourceAllocations(
        this.getScheduleCode
      );
      let workorder = response.data.data;
      this.materialTotal = workorder.materialCost;
      this.labourTotal = workorder.resourceCost;
      this.setupWorkorderDetails(workorder);
    },
    async getQcChecklist() {
      const response = await Workorders.getQcChecklist(
        this.getScheduleCode,
        this.resourceAllocationId
      );
      this.setupCheckList(response.data.data);
    },

    setupCheckList(checklistDetails) {
      this.checklistStatus = checklistDetails.checklist;
      this.checklistStatus.forEach((checklistItem) => {
        if (checklistItem.isSelected == 1) {
          this.selectedItems.push(checklistItem.checklistId);
        }
      });
      this.qcStatus = checklistDetails.qcStatus;
      this.statusComment = checklistDetails.qcComments;
    },
    setupWorkorderDetails(workorder) {
      this.workorderId = workorder.workorderId;
      this.productionStageName = workorder.productionStageName;
      this.scheduleStartDate = workorder.scheduledStartDate;
      this.scheduleEndDate = workorder.scheduledEndDate;
      this.itemQuantity = workorder.itemQuantity;
      this.itemUnits = workorder.itemUnits;
      this.itemCode = workorder.itemCode;
      this.itemName = workorder.itemDescription;
      this.resourceCode = workorder.resourceCode;
      this.workflowName = workorder.workflowName;
      this.scheduleComments = workorder.scheduleComments;
      this.customerName = workorder.custName;
      this.setupAllocations(workorder.allocation);
    },
    setupAllocations(allocations) {
      allocations.forEach((allocation) => {
        this.allocations.push({
          allocationCode: allocation.resourceAllocationId,
          allocationName: allocation.resourceName,
          allocatedQuantity: allocation.allocatedQuantity,
          resourceCode: allocation.resourceCode,
        });
      });
    },

    validatQcChecklistStatus() {
      this.errorMessage = '';

      if (!this.resourceAllocationId) {
        this.errorMessage = `Select resource`;
        document.getElementById('resourceAllocationId').focus();
        return false;
      }

      return true;
    },
    getAllocatedQuantity() {
      let allocation = this.allocations.filter((element) => {
        return element.allocationCode == this.resourceAllocationId;
      });
      this.allocatedQuantity =
        allocation.length > 0 ? allocation[0].allocatedQuantity : 0;
    },

    clearCheckList() {
      this.selectedItems = [];
      this.statusComment = '';
    },
    showAlert() {
      if (this.resourceAllocationId == null) {
        this.isNoResourseSelected = true;
      } else {
        this.isChecklistSaveDialog = true;
      }
    },
  },
  computed: {
	  getScheduleCode() {
		return localStorage.getItem('scheduleCode');
	}
  },
  watch: {
    resourceAllocationId: function() {
      this.clearCheckList();
      this.getQcChecklist();
      this.getAllocatedQuantity();
    },
    qcStatus: function() {
      if (this.qcStatus == this.qcPending) {
        this.commentsDisabled = true;
        this.statusComment = '';
      } else {
        this.commentsDisabled = false;
      }
    },
  },
};
</script>
<style scoped>
.checklist {
  background-color: #f0f8ff;
  border: 1px solid #618394;
  border-radius: 5px;
}
</style>
