<template>
  <div>
    <v-snackbar top v-model="displayStatus" :timeout="5000" color="success">
      <span>Assignment details saved successfully</span>
      <v-btn class="mx-2 pl-2 " icon @click="displayStatus = false">
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
    <v-row class="mb-0" no-gutters>
      <v-col xs="12" sm="12" md="6" lg="6">
        <v-card outlined dense class="ma-2">
          <v-card-title
            color="blue"
            class="primary--text caption font-weight-bold  mx-2 "
          >
            <v-icon left small class="white primary--text">
              mdi-calendar-clock
            </v-icon>
            Schedule Details
          </v-card-title>
          <v-row dense class="pb-0 mx-4 mb-4">
            <v-col xs="8" sm="8" md="8" lg="8">
              <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
                <div class="caption grey--text">Workorder:</div>
                <div class="caption primary--text pb-2">
                  {{ workorderId }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        color="yellow accent-4"
                        v-bind="attrs"
                        v-on="on"
                        v-if="scheduleComments"
                      >
                        mdi-android-messages
                      </v-icon>
                    </template>
                    <span class="caption">{{ scheduleComments }}</span>
                  </v-tooltip>
                </div>
                <div class="caption grey--text">Product Name:</div>
                <div class="caption primary--text pb-2">
                  {{ itemName }}
                </div>
                <div class="caption grey--text">Product Code:</div>
                <div class="caption primary--text pb-2">
                  {{ itemCode }}
                </div>
                <div class="caption grey--text">Customer:</div>
                <div class="caption primary--text pb-2">
                  {{ customerName }}
                </div>
              </v-card>
            </v-col>
            <v-col xs="4" sm="4" md="4" lg="4">
              <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
                <div class="caption grey--text">Stage:</div>
                <div class="caption primary--text pb-2">
                  {{ productionStageName }}
                </div>
                <div class="caption grey--text">Start Date:</div>
                <div class="caption primary--text pb-2">
                  {{ scheduleStartDate | formatToLocalDate }}
                </div>
                <div class="caption grey--text">End Date:</div>
                <div class="caption primary--text pb-2">
                  {{ scheduleEndDate | formatToLocalDate }}
                </div>
                <div class="caption grey--text">Quantity:</div>
                <div class="caption primary--text pb-2">
                  {{ itemQuantity }} {{ itemUnits }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="6">
        <v-card class="ma-2" outlined dense height="280px">
          <v-card-title
            color="blue"
            class="primary--text caption font-weight-bold  mx-2 pb-0 mb-0"
          >
            <v-icon left small class="white primary--text">mdi-thumb-up</v-icon>
            Accept\Reject Assignment
          </v-card-title>
          <hr class="hr_line" />
          <v-row dense class="mx-4 mt-n2">
            <v-col xs="12" sm="12" md="12" lg="12">
              <v-radio-group v-model="isAccepted" row class="caption">
                <v-radio :value="accept">
                  <template v-slot:label>
                    <v-chip color="green" outlined small>
                      Accept
                    </v-chip>
                  </template>
                </v-radio>
                <v-radio :value="reject">
                  <template v-slot:label>
                    <v-chip color="red" outlined small>
                      Reject
                    </v-chip>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense class="mx-4 mt-n2">
            <v-col xs="12" sm="12" md="12" lg="12">
              <v-textarea
                outlined
                name="input-7-4"
                label="Reason"
                rows="3"
                class="caption"
                v-model.trim="statusComment"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense class="pb-4 mx-4 mt-n6">
            <v-col cols="9" class="center">
              <v-btn
                small
                depressed
                color="yellow accent-2"
                class="primary--text"
                @click="saveMyAssignment"
                :disabled="saveInProgress"
              >
                <v-icon left color="primary">
                  {{ getButtonIcon }}
                </v-icon>
                {{ getButtonCaption }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="scheduleComments">
      <v-row class="mb-1" no-gutters>
        <v-col>
          <Comments :commentText="scheduleComments" />
        </v-col>
      </v-row>
    </template>
    <template v-if="bomItems.length > 0">
      <BomItems heading="BOM" :boomItems="bomItems" />
    </template>
    <template v-if="bomHistoryItems.length > 0">
      <BomItems heading="History" :boomItems="bomHistoryItems" />
    </template>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex';

import Workorders from '@/Services/workorders';
import BomItems from './BomItems';
import Comments from './comments';
// import User from "@/Services/user";

export default {
  name: 'workorder',
  props: {
    scheduleCode: String,
  },
  components: { BomItems, Comments },
  data() {
    return {
      snackbar: false,
      workorderId: null,
      workorderReference: null,
      workorderStartDate: null,
      workorderEndDate: null,
      scheduleStartDate: null,
      scheduleEndDate: null,
      itemQuantity: null,
      itemUnits: null,
      itemCode: null,
      itemName: null,
      productionStageName: null,
      prodStageCode: null,
      isAccepted: 1,
      acceptance: null,
      isClosed: 0,
      closed: null,
      productionStageColor: null,
      workorderStatusCode: null,
      workorderStatus: null,
      workorderStatusColor: null,
      productionStageProgress: 0,
      statusComment: '',
      scheduleComments: '',
      workflowName: '',
      resourceCode: '',
      bomItems: [],
      bomHistoryItems: [],
      displayStatus: false,
      accept: 1,
      reject: 0,
      saveInProgress: false,
      customerName: '',
    };
  },
  created() {
	this.$store.commit('showProgressCircular', 500)
	setTimeout(() => {
    	this.$store.dispatch('showMyAssignment', true);
	}, 0);
    this.getSchedule();
  },
  methods: {
    async saveMyAssignment() {
      try {
        this.saveInProgress = true;
        const response = await Workorders.saveMyAssignment({
          workorderId: this.workorderId,
          productionStageId: this.prodStageCode,
          isAccepted: this.isAccepted,
          statusComment: this.statusComment,
          resourceCode: this.resourceCode,
          productionStageProgress: this.productionStageProgress,
          scheduleId: this.getScheduleCode,
        });
        console.log(response);
        if (response.data.header.success) {
			this.$store.commit('showProgressCircular', 2000)
			setTimeout(() => {
				this.displayStatus = true;
      		}, 2000)
        }
      } catch (error) {
        console.log(error);
      }
      this.saveInProgress = false;
    },
    async getSchedule() {
      const response = await Workorders.getSchedule(this.getScheduleCode);
      let workorder = response.data.data;
      if (workorder.length > 0) this.setupWorkorderDetails(workorder[0]);
    },
    setupWorkorderDetails(workorder) {
      console.log(workorder);
      this.workorderId = workorder.workorderId;
      this.workorderReference = workorder.workorderReference;
      this.prodStageCode = workorder.productionStageId;
      this.productionStageName = workorder.productionStageName;
      this.workorderStartDate = workorder.woScheduleStartDate;
      this.workorderEndDate = workorder.woScheduleStartDate;
      this.scheduleStartDate = workorder.scheduleStartDate;
      this.scheduleEndDate = workorder.scheduleEndDate;
      this.itemQuantity = workorder.woQuantity;
      this.itemUnits = workorder.itemUnits;
      this.itemCode = workorder.itemCode;
      this.itemName = workorder.itemDescription;
      this.isAccepted = workorder.isAccepted;
      this.acceptance = workorder.acceptance;
      this.isClosed = workorder.isClosed;
      this.closed = workorder.closed;
      this.productionStageColor = workorder.productionStageColor;
      this.workorderStatusCode = workorder.workorderStatusCode;
      this.workorderStatus = workorder.workorderStatus;
      this.workorderStatusColor = workorder.workorderStatusColor;
      this.workflowName = workorder.workflowName;
      this.productionStageProgress = workorder.productionStageProgress;
      this.resourceCode = workorder.resourceCode;
      this.statusComment = workorder.statusComment;
      this.scheduleComments = workorder.scheduleComments;
      this.customerName = workorder.custName;
      this.bomItems = workorder.bomItems;
      this.bomHistoryItems = workorder.bomHistoryItems;
    },
    scheduleWorkorder() {
      this.$router.push({
        name: 'scheduleworkorder',
        params: { workorderId: this.workorderId },
      });
    },
  },
  computed: {
    // ...mapGetters(['dashboard', 'userId', 'userImage', 'name', 'fullName']),
    getCaption() {
      return `My Assignment: ${this.productionStageName} `;
    },
    getButtonCaption() {
      return this.isAccepted == this.accept
        ? `Accept Assignment`
        : 'Reject Assignment';
    },
    getButtonIcon() {
      return this.isAccepted == this.accept ? `mdi-thumb-up` : 'mdi-thumb-down';
    },
	getScheduleCode() {
		return localStorage.getItem('scheduleCode');
	}
  },
  watch: {},
};
</script>

<style scoped>
/* Popup container - can be anything you want */
span[data-descr] {
  position: relative;
}

span[data-descr]:hover::after,
span[data-descr]:focus::after {
  content: attr(data-descr);
  position: absolute;
  left: 0;
  top: 24px;
  min-width: 200px;
  border: 1px #aaaaaa solid;
  border-radius: 10px;
  background-color: #0644ec;
  padding: 12px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  z-index: 1;
}
.hr_line {
  border-top: 2px solid saddlebrown;
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 0px;
}
</style>
