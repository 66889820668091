<template>
  <div dense class="mx-4 pt-4 pb-4">
    <v-card-title color="blue" class="primary--text overline font-weight-bold ">
      <v-icon left small class="white primary--text">mdi-calendar</v-icon>
      Schedule calendar
    </v-card-title>
    <v-sheet tile height="54" class="d-flex">
      <v-btn icon class="ma-2" @click="changeToPrevMonth">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-select
        :items="workManagers"
        item-text="resourceName"
        item-value="resourceId"
        v-model="resourceCode"
        class="caption"
        dense
        outlined
        hide-details="false"
        @change="getWorkorderSchedules"
      ></v-select>
      <!-- <v-select
        v-model="mode"
        :items="modes"
        dense
        outlined
        hide-details
        label="event-overlap-mode"
        class="ma-2"
      ></v-select> -->
      <!-- <v-select
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="weekdays"
        class="ma-2"
      ></v-select> -->
      <v-spacer></v-spacer>
      <v-btn icon class="ma-2" @click="changeToNextMonth">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:more="viewDay"
      ></v-calendar>
    </v-sheet>
  </div>
</template>
<script>
import Workorders from '@/Services/workorders';
import moment from 'moment';
export default {
  props: {},
  data: () => ({
    workorderSchedules: [],
    workManagers: [],
    resourceCode: null,
    startOfMonth: moment()
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD'),
    endOfMonth: moment()
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD'),
    type: 'month',
    types: ['month', 'week', 'day', '4day'],
    mode: 'stack',
    modes: ['stack', 'column'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
      { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    value: '',
    events: [],
  }),
  created() {
    this.getWorkorderSchedules();
    this.getWorkManagers();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = 'month';
    },
    async changeToNextMonth() {
      this.$refs.calendar.next();
      this.startOfMonth = moment(this.startOfMonth)
        .add(1, 'M')
        .format('YYYY-MM-DD');
      this.endOfMonth = moment(this.startOfMonth)
        .endOf('month')
        .format('YYYY-MM-DD');
      this.getWorkorderSchedules();
    },
    async changeToPrevMonth() {
      this.$refs.calendar.prev();
      this.startOfMonth = moment(this.startOfMonth)
        .add(-1, 'M')
        .format('YYYY-MM-DD');
      this.endOfMonth = moment(this.startOfMonth)
        .endOf('month')
        .format('YYYY-MM-DD');
      this.getWorkorderSchedules();
    },
    async getWorkorderSchedules() {
      let resourceCode = this.resourceCode == -1 ? null : this.resourceCode;
      const response = await Workorders.getSchedules(
        resourceCode,
        this.startOfMonth,
        this.endOfMonth
      );
      this.workorderSchedules = response.data.data;
      this.refereshEvents();
    },
    async getWorkManagers() {
      const response = await Workorders.getWorkManagers();
      let managers = response.data.data;
      this.workManagers.push({
        resourceId: '-1',
        resourceName: 'All',
      });
      managers.forEach((manager) => {
        this.workManagers.push({
          resourceId: manager.resourceId,
          resourceName: manager.resourceName,
        });
      });
      // this.workManagers = response.data.data;

      this.resourceCode = '-1';
    },
    refereshEvents() {
      this.events = [];
      this.workorderSchedules.forEach((schedule) => {
        this.events.push({
          name: `${schedule.productionStageName} - ${schedule.resourceName}`,
          start: moment(schedule.scheduledStartDate).format('YYYY-MM-DD'),
          end: moment(schedule.scheduledEndDate).format('YYYY-MM-DD'),
          color: `#${schedule.productionStageColor}`,
          timed: false,
        });
      });
    },

    getEventColor(event) {
      return event.color;
    },
  },
};
</script>
