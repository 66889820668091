<template>
  <div>
    <Search
      v-on:FilterData="SearchData"
      @updateFilterText="updateFilterText($event)"
      @updateFilter="updateFilter($event)"
	  @clearFilter="clearFilter"
      :rowFilters="rowFilters"
      class="mb-6"
    />
    <v-card flat class="pb-10">
      <v-card-title
        color="blue"
        class="primary--text caption font-weight-bold  mx-2 mb-3"
      >
        <v-row>
          <v-col class="align-center d-flex">
            <v-icon
              left
              small
              class="white red--text mt-2"
              @click="setFaviourite"
            >
              {{ getFavouriteIcon(isFavourite) }}
            </v-icon>
            <span class="align-self-center mt-2"> My Assignments </span>
          </v-col>
          <v-col xs="4" sm="3" md="2" lg="2">
            <v-select
              :items="statusFilter"
              item-text="name"
              item-value="id"
              v-model="status"
              class="caption mt-0 pt-0"
              dense
              outlined
              label="Status"
              placeholder="Select status"
              hide-details="false"
              id="status"
              @change="getUserSchedules()"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row color="green" class="px-10">
        <v-divider></v-divider>
      </v-row>
		<v-container fluid v-if="isNoListandNoFilter">
    <v-row dense align="center" justify="center" align-content="center" class="pa-10">
	  <v-card-title class="text-no-wrap pt-1 ps-2">
		  Sorry No data available...!
        </v-card-title>
	</v-row>
		</v-container>
		<v-container fluid v-else-if="isNoListandFilter">
    <v-row dense align="center" justify="center" align-content="center" class="pa-10">
	  <v-card-title class="text-no-wrap pt-1 ps-2">
          Sorry, we couldn't find the workorder you are looking for!
        </v-card-title>
	</v-row>
		</v-container>
	<div v-else-if="isListandNoFilter">
      <div
        v-for="(schedule, scheduleIndex) in resourceSchedules"
        :key="scheduleIndex"
      >
        <v-row dense class="px-7 py-1 mt-3">
          <v-col sm="2" md="1" lg="1">
            <div class="caption grey--text">Workorder</div>
            <div class="caption primary--text ">
              <v-icon
                small
                color="red accent-4 mr-0 pr-0"
                left
                @click="setFaviouriteSchedule(scheduleIndex)"
              >
                {{ getFavouriteIcon(schedule.isFavourite) }}
              </v-icon>
              {{ schedule.workorderId }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="yellow accent-4"
                    v-bind="attrs"
                    v-on="on"
                    v-if="schedule.scheduleComments"
                  >
                    mdi-android-messages
                  </v-icon>
                </template>
                <span class="caption">{{ schedule.scheduleComments }}</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col sm="2" md="2" lg="2">
            <div class="caption grey--text ">Customer</div>
            <div class="caption primary--text">
              {{ schedule.custName }}
            </div>
          </v-col>
          <v-col sm="2" md="2" lg="1">
            <div class="caption grey--text">Progress</div>
            <v-progress-linear
              :value="schedule.productionStageProgress"
              height="15"
              :color="schedule.workorderStatusColor"
              :background-color="schedule.stageBackgroundColor"
            >
              <strong class="caption white--text"
                >{{ schedule.productionStageProgress }}%</strong
              >
            </v-progress-linear>
            <ScheduleStatus
              :woProgress="schedule.woProgress"
              class="mt-1"
            ></ScheduleStatus>
          </v-col>

          <v-col sm="2" md="1" lg="1">
            <div class="caption grey--text">Start Date</div>
            <div class="caption primary--text">
              {{ schedule.scheduledStartDate | formatToLocalDate }}
            </div>
          </v-col>
          <v-col sm="2" lg="1">
            <div class="caption grey--text">End Date</div>
            <div class="caption primary--text">
              {{ schedule.scheduledEndDate | formatToLocalDate }}
            </div>
          </v-col>
          <v-col sm="2" md="2" lg="1">
            <div class="caption grey--text ">Stage</div>
            <div class="caption primary--text">
              {{ schedule.productionStageName }}
            </div>
          </v-col>
          <v-col sm="8" md="2" lg="2">
            <div class="caption grey--text">Item</div>
            <ProductIcon
              :name="schedule.itemName"
              :productCode="schedule.itemCode"
            />
          </v-col>
          <v-col sm="2" md="1" lg="1">
            <div class="caption grey--text ">Quanity</div>
            <div class="caption primary--text">
              {{ schedule.itemQuantity }} {{ schedule.itemUnits }}
            </div>
          </v-col>

          <v-col sm="2" lg="2" class="d-flex">
            <div class="">
              <div class="caption grey--text">Status</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="white primary--text mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ getQcStatusIcon(schedule.scheduleQcStatus) }}
                  </v-icon>
                </template>
                <span class="caption">{{ schedule.scheduleQc }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="white red--text mr-2" v-bind="attrs" v-on="on">
                    {{ getStatusIcon(schedule.isAccepted) }}
                  </v-icon>
                </template>
                <span class="caption">{{ schedule.acceptance }}</span>
              </v-tooltip>

              <v-btn
                icon
                class="mt-n2"
                :disabled="schedule.workorderStatusCode == workorderOnHold"
              >
                <!-- <v-menu top :close-on-click="closeOnClick">
                  <template v-slot:activator="{ on, attrs }"> -->
                    <v-btn
                      icon
                      :color="schedule.acceptanceColor"
					  @click="scheduleAssignment(scheduleIndex)"
                    >
                      <v-icon class="green--text mt-2">
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  <!-- </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, index) in items"
                      :key="index"
                      link
                      v-show="
                        isShowMenuItem(
                          item.title,
                          schedule.isAccepted,
                          schedule.isQc
                        )">
                      <v-list-item-title
                        @click="goToRoute(item.id, scheduleIndex)"
                        class="caption"
                        color="teal darken-2--text"
                      >
                        <v-icon left small color="teal darken-2">
                          {{ item.icon }}
                        </v-icon>
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-divider></v-divider>
        </v-row>
      </div>
      <WoPagination
        :TotalPages="totalPages"
        :TotalRows="totalNumberOfRows"
        v-on:pagechange="ChangePage"
        v-show="totalPages > 1"
        :selectedFilter="selectedFilter"
        :isFilterActive="isFilterActive"
      />
	  </div>
    </v-card>
  </div>
</template>
<script>
import Workorders from '@/Services/workorders';
import ProductIcon from './ProductIcon';
import { mapGetters } from 'vuex';
import WoPagination from '@/components/Workorder/Pagination';
import ScheduleStatus from '@/components/Workorder/ScheduleStatus';
import Search from '@/components/Workorder/Search';
import Utils from '@/Services/utils';

const Filters = {
  REJECTED: 0,
  PENDING: -1,
  ACCEPTED: 1,
  ALL: 2,
  QCPASSED: 3,
  QCFAILED: 4,
  QCPENDING: 5,
  CENTPERCENT: 6,
  ONHOLD: 7,
  ONGOING: 8,
};

export default {
  name: 'MyAssignment',
  components: {
    ProductIcon,
    WoPagination,
    Search,
    ScheduleStatus,
  },
  data() {
    return {
      resourceSchedules: [],
      progress: 60,
      items: [
        {
          id: 0,
          title: 'View',
          icon: 'mdi-view-list',
          to: 'viewmyassignments',
        },
        {
          id: 1,
          title: 'Allocate',
          icon: 'mdi-ballot',
          to: 'allocateassignments',
        },
        {
          id: 2,
          title: 'Material',
          icon: 'mdi-office-building-marker',
          to: 'materialissue',
        },
        {
          id: 3,
          title: 'Resource',
          icon: 'mdi-account-plus-outline',
          to: 'resourceissue',
        },
        {
          id: 4,
          title: 'Status',
          icon: 'mdi-list-status',
          to: 'allocationprogress',
        },
        {
          id: 5,
          title: 'QC',
          icon: 'mdi-checkbox-multiple-marked',
          to: 'checklist',
        },
      ],
      offset: true,
      closeOnClick: true,
      totalPages: 0,
      pageNumber: 1,
      numberOfRows: 25,
      totalNumberOfRows: 0,
      workorderOnHold: 3,
      dataFilters: {},
      pageInfo: {},
      status: Filters.ALL,
      statusFilter: [
        { id: Filters.ALL, name: 'All' },
        { id: Filters.ACCEPTED, name: 'Accepted' },
        { id: Filters.PENDING, name: 'Pending' },
        { id: Filters.REJECTED, name: 'Rejected' },
        { id: Filters.QCPASSED, name: 'QC Passed' },
        { id: Filters.QCFAILED, name: 'QC Failed' },
        { id: Filters.QCPENDING, name: 'QC Pending' },
        { id: Filters.CENTPERCENT, name: '100% Complete' },
        { id: Filters.ONHOLD, name: 'On Hold' },
        { id: Filters.ONGOING, name: 'In Progress' },
      ],
      isFavourite: false,
      rowFilters: null,
	  isNoData: true,
	  selectedFilter: '',
	  isFilterActive: false
    };
  },
  created() {
	  this.$store.commit('showProgressCircular', 500);
	  this.$store.dispatch('showWorkorders', false);
		this.rowFilters = Utils.getDataFilters();
		this.loadFilters();
	  this.getUserSchedules();
  },
  beforeDestroy() {
    this.$store.dispatch('updateScheduleDataFilters', this.dataFilters);
  },
  methods: {
	scheduleAssignment(index) {
		this.$router.push({ name: 'assignments-schedule'})
		localStorage.setItem('scheduleCode', this.resourceSchedules[index].schedule.toString());
	},
    async saveFavouriteSchedule(scheduleId, isFavourite) {
      try {
        await Workorders.saveFavouriteSchedule({
          scheduleId: scheduleId,
          isFavourite: isFavourite ? true : false,
        });
        if (this.isFavourite) {
          this.getUserSchedules();
        }
      } catch (error) {
        // if (error.response.status == StatusCodes.PRECONDITION_FAILED) {
        //   this.errorMessage = error.response.data.header.message;
        // }
        console.log(error);
      }
    },
    async getUserSchedules() {
      this.setScheduleFilters();
	  this.$store.commit('showProgressCircular', 300)
      const response = await Workorders.getUserSchedules(
        this.userId,
        this.pageNumber,
        this.numberOfRows,
        this.dataFilters
      );
      this.resourceSchedules = response.data.data;
      if (Object.prototype.hasOwnProperty.call(response.data, 'pageinfo')) {
        this.pageInfo = response.data.pageinfo;
        this.totalPages = this.pageInfo.totalPages;
        this.totalNumberOfRows = this.pageInfo.totalRecords;
      }
    },
    setScheduleFilters() {
      this.deleteStatusFilters();
      this.addStatusFilter(Filters.REJECTED, 'accepted', this.status);
      this.addStatusFilter(Filters.PENDING, 'accepted', this.status);
      this.addStatusFilter(Filters.ACCEPTED, 'accepted', this.status);
      this.addStatusFilter(Filters.QCPASSED, 'qcPassed', 1);
      this.addStatusFilter(Filters.QCFAILED, 'qcFailed', 1);
      this.addStatusFilter(Filters.QCPENDING, 'qcPending', 1);
      this.addStatusFilter(Filters.CENTPERCENT, 'centPercent', 1);
      this.addStatusFilter(Filters.ONHOLD, 'onHold', 1);
      this.addStatusFilter(Filters.ONGOING, 'ongoing', 1);
      if (this.isFavourite) {
        this.dataFilters = {
          ...this.dataFilters,
          isFavourite: this.isFavourite,
        };
      }
    },
    addStatusFilter(status, filterName, filterValue) {
      if (this.status == status) {
        this.dataFilters = {
          ...this.dataFilters,
          [filterName]: filterValue,
        };
      }
    },
    deleteStatusFilters() {
      delete this.dataFilters.accepted;
      delete this.dataFilters.qcPassed;
      delete this.dataFilters.qcFailed;
      delete this.dataFilters.qcPending;
      delete this.dataFilters.centPercent;
      delete this.dataFilters.onHold;
      delete this.dataFilters.ongoing;
      delete this.dataFilters.isFavourite;
    },
    ChangePage(moveToPage, numberOfRows) {
      this.pageNumber = moveToPage;
      this.numberOfRows = numberOfRows;
      this.getUserSchedules();
    },
    SearchData(searchConditions) {
      this.dataFilters = searchConditions;
      this.getUserSchedules();
    },
    goToRoute(id, index) {
      this.$router.push({
        name: this.items[id].to,
        params: {
          scheduleCode: this.resourceSchedules[index].schedule.toString(),
        },
      });
	  localStorage.setItem('scheduleCode', this.resourceSchedules[index].schedule.toString());
    },
    isShowMenuItem(itemName, isAccepted, isQc) {
      switch (isQc) {
        case 1:
          if (itemName == 'View') return true;
          return isAccepted == 1 && itemName == 'QC' ? true : false;
        default:
          return isAccepted == 1 || itemName == 'View' ? true : false;
      }
    },
    getStatusIcon(isAccepted) {
      switch (isAccepted) {
        case 1:
          return 'mdi-thumb-up';
        case 0:
          return 'mdi-thumb-down';
        default:
          return 'mdi-timer-sand-full';
      }
    },
    getQcStatusIcon(isQc) {
      switch (isQc) {
        case 1:
          return 'mdi-checkbox-marked';
        case 0:
          return 'mdi-timer-sand-full';
        case -1:
          return 'mdi-close-box';
      }
    },
    getFavouriteIcon(isFavourite) {
      switch (isFavourite) {
        case 1:
          return ' mdi-star';
        case 0:
          return 'mdi-star-outline';
        default:
          return 'mdi-star-outline';
      }
    },
    setFaviourite() {
      this.isFavourite = this.isFavourite ? 0 : 1;
    },
    setFaviouriteSchedule(rowIndex) {
      this.resourceSchedules[rowIndex].isFavourite = this.resourceSchedules[
        rowIndex
      ].isFavourite
        ? 0
        : 1;
      this.saveFavouriteSchedule(
        this.resourceSchedules[rowIndex].schedule,
        this.resourceSchedules[rowIndex].isFavourite
      );
    },
    loadFilters() {
      if (this.scheduleDataFilters) {
        this.dataFilters = this.scheduleDataFilters;
      }
      if (this.dataFilters.isFavourite) {
        this.isFavourite = this.dataFilters.isFavourite;
      }
      if (
        this.dataFilters.accepted == Filters.ACCEPTED ||
        this.dataFilters.accepted == Filters.PENDING ||
        this.dataFilters.accepted == Filters.REJECTED
      ) {
        this.status = this.dataFilters.accepted;
      }
      if (this.dataFilters.qcPassed) {
        this.status = Filters.QCPASSED;
      }
      if (this.dataFilters.qcFailed) {
        this.status = Filters.QCFAILED;
      }
      if (this.dataFilters.qcPending) {
        this.status = Filters.QCPENDING;
      }
      if (this.dataFilters.centPercent) {
        this.status = Filters.CENTPERCENT;
      }
      if (this.dataFilters.onHold) {
        this.status = Filters.ONHOLD;
      }
      if (this.dataFilters.ongoing) {
        this.status = Filters.ONGOING;
      }
      for (var key in this.dataFilters) {
        this.updateFilterValue(key, this.dataFilters[key]);
      }
    },
    updateFilterValue(filterName, filterText) {
      this.rowFilters = this.rowFilters.map((element) => {
        if (element.code == filterName) {
          return {
            name: element.name,
            code: element.code,
            value: filterText,
          };
        }
        return element;
      });
    },
	updateFilterText(selectedFilter) {
      this.selectedFilter = selectedFilter;
    },
	updateFilter() {
      this.isFilterActive = true;
    },
	clearFilter() {
      this.isFilterActive = false;
    },
  },

  computed: {
    ...mapGetters(['userId', 'scheduleDataFilters']),
	isNoListandNoFilter() {
		return this.resourceSchedules.length < 0 && Object.keys(this.dataFilters).length === 0  ? true : false
	},
	isListandNoFilter() {
		return this.resourceSchedules.length > 0 ? true : false
	},
	isNoListandFilter() {
		return this.resourceSchedules.length === 0 && Object.keys(this.dataFilters).length > 0 ? true : false
	},
  },
  watch: {
    isFavourite() {
      this.getUserSchedules();
    },
	['dataFilters.accepted'](value){
		// handler() {
			console.log('filter changed', value)
			this.pageNumber = 1
		// },
		// deep: true
		
	}
  },
  updated () {
	  this.pageNumber = 1
  }
};
</script>
