<template>
  <div class="d-inline-flex align-start">
    <v-icon @click.stop="dialog = true" color="green">mdi-image</v-icon>
    <div class="caption primary--text ml-2">{{ productCode }} - {{ name }}</div>
    <v-dialog v-model="dialog" max-width="400">
      <product :name="name" :productCode="productCode"></product>
    </v-dialog>
  </div>
</template>
<script>
import product from './Product';

export default {
  name: 'ProductIcon',
  components: { product },
  props: {
    name: String,
    productCode: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
