import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';
import Login from '../components/Login.vue';
import Dashboard from '../components/Dashboard.vue';
import Dashboards from '../components/DashboardCopy';
import ChangePassword from '../components/User/ChangePassword.vue';
import ScheduleWorkorder from '../components/Workorder/WorkorderSchedule';
import ScheduleWorkorders from '../components/Workorder/WorkorderSchedule2';
import MyAssignments from '../components/Workorder/ResourceSchedule';
import Assignments from '../components/Workorder/Assignments';
import ScheduleAssignment from '../components/Workorder/ScheduleAssignment';
import MyAssignmentsView from '../components/Workorder/ViewMyAssignment';
import AllocateAssignments from '../components/Workorder/AllocateMyAssignment';
import MaterialIssue from '../components/Workorder/MaterialIssue';
import AllocationProgress from '../components/Workorder/AllocationProgress';
import Checklist from '../components/Workorder/Checklist';
import ResourceIssue from '../components/Workorder/ResourceIssue';
import OutstandingWO from '../components/Workorder/OutstandingWO';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'app',
    component: Home,
  },
  {
    path: '/scheduleworkorder',
    name: 'scheduleworkorder',
    component: ScheduleWorkorder,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/scheduleworkorders',
    name: 'scheduleworkorders',
    component: ScheduleWorkorders,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/viewmyassignments',
    name: 'viewmyassignments',
    component: MyAssignmentsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/allocateassignments',
    name: 'allocateassignments',
    component: AllocateAssignments,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/materialissue',
    name: 'materialissue',
    component: MaterialIssue,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/resourceissue',
    name: 'resourceissue',
    component: ResourceIssue,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/allocationprogress',
    name: 'allocationprogress',
    component: AllocationProgress,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/checklist',
    name: 'checklist',
    component: Checklist,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/myassignments',
    name: 'myassignments',
    component: MyAssignments,
    meta: { requiresAuth: true },
  },
  {
    path: '/assignments',
    name: 'assignments',
    component: Assignments,
    meta: { requiresAuth: true },
  },
  {
    path: '/schedule',
    name: 'assignments-schedule',
    component: ScheduleAssignment,
    meta: { requiresAuth: true },
  },

  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboards',
    name: 'dashboards',
    component: Dashboards,
    meta: { requiresAuth: true },
  },
  {
    path: '/outstanding-workorders',
    name: 'OutstandingWO',
    component: OutstandingWO,
    meta: { requiresAuth: true },
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: ChangePassword,
    meta: { requiresAuth: true },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  base: 'https://berrydtaleapi.turqosoft.com/api/v1',
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    if (
      to.matched.some((route) => route.meta.requiresAuth) &&
      !store.getters.loggedIn
    ) {
      await store.dispatch('refreshToken');
    }
    if (
      to.matched.some((route) => route.meta.requiresAuth) &&
      !store.getters.loggedIn
    ) {
      next('/login');
    }
    next();
  } catch {
    next('/login');
  }
});

export default router;
