<template>
  <v-row justify="center">
    <v-overlay v-show="$store.state.progressCircular.show">
      <v-progress-circular
        :size="100"
        :width="8"
        color="primary"
        indeterminate
        :value="50"
      ></v-progress-circular>
	  <p class="ml-4 mt-4 text-center text-3xl overline">Loading...</p>
    </v-overlay>
  </v-row>
</template>

<script>
export default {
	data() {
		return {
			absolute: true,
			overlay: true,
		}
	},

}
</script>

<style>

</style>
