<template>
  <v-card outlined dense class="pb-10">
    <v-card-title
      color="blue"
      class="primary--text caption font-weight-bold  mx-2 mb-2 "
    >
      <v-icon left small class="white primary--text">mdi-history</v-icon>
      Issue\Return History
    </v-card-title>

    <v-row color="green" class="px-10">
      <v-divider></v-divider>
    </v-row>
    <div
      v-for="(material, scheduleIndex) in materialsHistory"
      :key="scheduleIndex"
    >
      <v-row dense class="px-7 py-1 mt-3">
        <v-col lg="1">
          <div class="caption grey--text ">Date</div>
          <div class="caption primary--text">
            {{ material.issueDate | formatToLocalDate }}
          </div>
        </v-col>
        <v-col lg="1">
          <div class="caption grey--text">Type</div>
          <div class="caption primary--text ">
            {{ material.issueType }}
          </div>
        </v-col>
        <v-col lg="2">
          <div class="caption grey--text ">Resource</div>
          <div class="caption primary--text">
            {{ material.resourceName }}
          </div>
        </v-col>
        <v-col lg="2">
          <div class="caption grey--text">Component Code</div>
          <div class="caption primary--text ">
            {{ material.componentCode }}
          </div>
        </v-col>
        <v-col lg="2">
          <div class="caption grey--text">Component Name</div>
          <div class="caption primary--text ">
            {{ material.componentName }}
          </div>
        </v-col>
        <v-col lg="2">
          <div class="caption grey--text">Quantity</div>
          <div class="caption primary--text ">
            {{ material.componentQuantity }} {{ material.componentUnit }}
          </div>
        </v-col>
		<v-col lg="2">
          <div class="caption grey--text">Rate</div>
          <div class="caption primary--text ">
            {{ material.unitCost }}
          </div>
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-divider></v-divider>
      </v-row>
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    materialsHistory: Array,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['userId']),
  },
};
</script>
