export default class ItemList {
  constructor(
    SlNo = null,
    ItemCode = null,
    ItemName = null,
    StockQuantity = 0,
    UnitQuantity = 0,
    IssueQuantity = 0,
    Unit = null,
    Price = 0,
    MaterialFrom = 3
  ) {
    this.SlNo = SlNo;
    this.ItemCode = ItemCode;
    this.ItemName = ItemName;
    this.StockQuantity = StockQuantity;
    this.UnitQuantity = UnitQuantity;
    this.IssueQuantity = IssueQuantity;
    this.Unit = Unit;
    this.Price = Price;
    this.MaterialFrom = MaterialFrom;
  }
}
