<template>
  <div>
    <v-snackbar top v-model="displayStatus" :timeout="5000" color="success">
      <span>Resource utilization saved successfully</span>
      <v-btn class="mx-2 pl-2 " icon @click="displayStatus = false">
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
    
    <!-- <v-card outlined dense> -->
      <v-card-title
        color="blue"
        class="primary--text caption font-weight-bold  mx-2 "
      >
        <v-row class="d-inline-flex ">
          <v-col cols="12" md="10" lg="10">
            <div class="align-self-center pt-3">
              <v-icon left small class="white primary--text "
                >mdi-account-box</v-icon
              >
              Resource Utilization
            </div>
          </v-col>
          <v-col xs="6" sm="4" md="2" lg="2" class="" cols="12">
            <WorkorderDate
              label="Utilization Date"
              :woDate="utilizationDate"
              v-on:dateChange="updateUtilizationDate($event)"
              class="caption"
              :id="getRef('utilizationDate')"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <hr class="hr_line" />

      <v-row
        :id="RowIndex"
        v-for="(resource, RowIndex) in resources"
        :key="RowIndex"
        dense
        class="pb-3 mx-4 pt-1"
      >
        <!-- <v-col xs="12" sm="12" md="12" lg="12">
          <v-card dense flat>
            <v-row dense class="pb-3 mx-4 mt-3"> -->
        <v-col xs="6" sm="4" md="2" lg="2" class="caption" cols="12">
          <v-select
            :items="allocations"
            item-text="allocationName"
            item-value="allocationCode"
            v-model="resource.AllocationCode"
            class="caption"
            dense
            outlined
            label="Allocation"
            placeholder="Select Allocation"
            hide-details="false"
            :id="getRef('allocation', RowIndex)"
            @change="clearErrorMessage"
          ></v-select>
        </v-col>
        <v-col xs="6" sm="4" md="2" lg="2" class="caption" cols="12">
          <v-autocomplete
            :items="workers"
            item-text="resourceName"
            item-value="resourceCode"
            v-model="resource.WorkerCode"
            class="caption"
            dense
            outlined
            label="worker"
            placeholder="Select worker"
            hide-details="false"
            :id="getRef('worker', RowIndex)"
            @change="updateRate(resource.WorkerCode, RowIndex)"
          ></v-autocomplete>
        </v-col>

        <v-col xs="6" sm="4" md="1" lg="1" class="" cols="6">
          <v-text-field
            label="No of Hours"
            outlined
            dense
            hide-details
            reverse
            v-model="resource.Hours"
            class="caption"
            :id="getRef('Hours', RowIndex)"
            @keypress="clearErrorMessage"
          >
          </v-text-field>
        </v-col>
        <v-col xs="6" sm="4" md="1" lg="1" class="caption" cols="6">
          <v-text-field
            label="Rate"
            outlined
            dense
            reverse
            hide-details
            v-model.number="resource.Rate"
            class="caption"
            :id="getRef('Rate', RowIndex)"
			@click="clearErrorMessage()"
			@keypress="changeInput"
          >
          </v-text-field>
        </v-col>
        <v-col xs="6" sm="4" md="1" lg="1" class="caption" cols="6">
          <v-text-field
            label="Rate Type"
            outlined
            dense
            readonly
            hide-details
            v-model="resource.RateType"
            class="caption"
            :id="getRef('RateType', RowIndex)"
          >
          </v-text-field>
        </v-col>
        <v-col xs="6" sm="4" md="2" lg="2" class="caption" cols="6">
          <v-text-field
            label="Comments"
            outlined
            dense
            hide-details
            v-model="resource.Comments"
            class="caption"
            :id="getRef('Comments', RowIndex)"
			@keypress="changeInput"
          >
          </v-text-field>
        </v-col>
        <v-col xs="6" sm="4" md="1" lg="1" align-self="start" cols="3">
          <div class="caption grey--text text-center mt-n2">Delete</div>
          <div class="caption red--text text-center">
            <v-icon @click="deleteRow(RowIndex)" class="red--text">
              mdi-delete
            </v-icon>
          </div>
        </v-col>
        <v-col
          xs="6"
          sm="4"
          md="1"
          lg="1"
          align-text="center"
          v-show="RowIndex == resources.length - 1"
		  cols="2"
        >
          <div class="caption grey--text text-left mt-n2">New</div>
          <div class="caption green--text text-left">
            <v-icon @click="addNewRow" class="green--text">
              mdi-plus-box
            </v-icon>
          </div>
          <!-- </v-col>
            </v-row>
          </v-card> -->
        </v-col>
      </v-row>

      <v-row dense class="pb-4 mx-4 ">
        <v-col>
          <div v-show="errorMessage">
            <v-alert dense text type="error" class="caption">
              {{ errorMessage }}
            </v-alert>
          </div>
          <v-btn
            small
            depressed
            color="yellow accent-2"
            class="black--text"
            @click="saveResourceUtlization"
			:disabled="saveInProgress"
          >
            Save Utilization
          </v-btn>
        </v-col>
      </v-row>
    <!-- </v-card> -->
    <template v-if="resourceHistory.length > 0">
	<v-divider></v-divider>
      <ResourceHistory
        :resourceHistory="resourceHistory"
        v-on:deleteUtilization="deleteResourceUtilization($event)"
        class="mt-3"
      />
    </template>
  </div>
</template>
<script>
import Workorders from '@/Services/workorders';
import WorkorderDate from './WorkorderDate';
import ItemList from '@/Utils/Resources.js';
import moment from 'moment';
// import Label from './Label';
import ResourceHistory from './ResourceIssueHistory2';
import { StatusCodes } from 'http-status-codes';
export default {
  name: 'ResourceIssue',
  props: {
    scheduleCode: String,
  },
  components: {
    WorkorderDate,
    // Label,
    ResourceHistory,
  },
  data() {
    return {
      snackbar: false,
      workorderId: null,
      productionStageId: null,
      productionStageProgress: 0,
      scheduledStartDate: null,
      scheduledEndDate: null,
      isAccepted: -1,
      resourceCode: null,
      acceptance: null,
      isClosed: 0,
      closed: null,
      productionStageName: null,
      productionStageColor: null,
      workorderReference: null,
      itemCode: null,
      workorderStatusCode: 0,
      itemQuantity: null,
      workorderStatus: null,
      workorderStatusColor: null,
      itemName: null,
      itemUnits: null,
      workflowName: null,
      workorderStartDate: null,
      workorderEndDate: null,
      allocations: [],
      displayStatus: false,
      workers: [],
      errorMessage: '',
      utilizationDate: moment().format('YYYY-MM-DD'),
      resources: [],
      resourceHistory: [],
	  saveInProgress: false
    };
  },
  created() {
	// this.$store.commit('showProgressCircular', 500)
	// setTimeout(() => {
    	this.$store.dispatch('showMyAssignment', true);
	// }, 0);
	this.getResourceAllocations();
    this.getResources();
    this.addNewRow();
    this.getResourceUtilization();
  },
  methods: {
	changeInput() {
		this.$emit('userChange')
	},
    async getResources() {
      const response = await Workorders.getResources();
      this.workers = response.data.data;
    },
    async getResourceUtilization() {
      const response = await Workorders.getResourceUtilization(
        this.getScheduleCode
      );
      this.resourceHistory = response.data.data;
    },

    async saveResourceUtlization() {
      try {
        if (!this.validateResources()) return;
		this.saveInProgress = true;
        let resourceIssues = {
          scheduleId: this.getScheduleCode,
          utilizationDate: this.utilizationDate,
          utilization: [],
        };
        this.resources.forEach((resource) => {
          resourceIssues.utilization.push({
            stageAllocationId: resource.AllocationCode,
            resourceCode: resource.WorkerCode,
            duration: resource.Hours,
            comments: resource.Comments,
            rate: resource.Rate,
          });
        });

        const response = await Workorders.saveResourceUtilization(
          resourceIssues
        );

        console.log(response);
        if (response.data.header.success) {
			this.$store.commit('showProgressCircular', 2000)
			setTimeout(() => {
				this.displayStatus = true;
      		}, 2000)
			this.getResourceUtilization();
			this.resetUtilization();
        }
      } catch (error) {
        if (error.response.status == StatusCodes.PRECONDITION_FAILED) {
          this.errorMessage = error.response.data.header.message;
        }
        console.log(error);
      }
	  this.saveInProgress = false;
	  this.$emit('saveDone')
    },
    async deleteResourceUtilization(rowIndex) {
		console.log(rowIndex);
      console.log('inside delete');
      let utilizationToDelete = {
        woScheduleId: this.resourceHistory[rowIndex].woScheduleId,
        stageAllocationId: this.resourceHistory[rowIndex].stageAllocationId,
        resourceCode: this.resourceHistory[rowIndex].resourceCode,
        utilizationDate: moment(
          this.resourceHistory[rowIndex].utilizationDate
        ).format('YYYY-MM-DD'),
      };
      console.log(utilizationToDelete);
      const response = await Workorders.deleteResourceUtilization(
        utilizationToDelete
      );
      console.log(response);
      if (response.data.header.success) {
        this.getResourceUtilization();
      }
    },
    async getResourceAllocations() {
      //   this.scheduleCode = 118;
      const response = await Workorders.getResourceAllocations(
        this.getScheduleCode
      );
      let workorder = response.data.data;
      console.log(workorder);
      this.setupWorkorderDetails(workorder);
    },
    setupWorkorderDetails(workorder) {
      this.workorderId = workorder.workorderId;
      this.productionStageId = workorder.productionStageId;
      this.productionStageProgress = workorder.productionStageProgress;
      this.scheduledStartDate = moment(workorder.scheduledStartDate).format(
        'YYYY-MM-DD'
      );
      this.scheduledEndDate = moment(workorder.scheduledEndDate).format(
        'YYYY-MM-DD'
      );
      this.isAccepted = workorder.isAccepted;
      this.resourceCode = workorder.resourceCode;
      this.acceptance = workorder.acceptance;
      this.isClosed = workorder.isClosed;
      this.closed = workorder.closed;
      this.productionStageName = workorder.productionStageName;
      this.productionStageColor = workorder.productionStageColor;
      this.workorderReference = workorder.workorderReference;
      this.itemCode = workorder.itemCode;
      this.workorderStatusCode = workorder.workorderStatusCode;
      this.itemQuantity = workorder.itemQuantity;
      this.workorderStatus = workorder.workorderStatus;
      this.workorderStatusColor = workorder.workorderStatusColor;
      this.itemName = workorder.itemDescription;
      this.itemUnits = workorder.itemUnits;
      this.setupAllocations(workorder.allocation);
    },
    setupAllocations(allocations) {
      allocations.forEach((allocation) => {
        this.allocations.push({
          allocationCode: allocation.resourceAllocationId,
          allocationName: allocation.resourceName,
          allocatedQuantity: allocation.allocatedQuantity,
        });
      });
    },
    setupResources(items = []) {
      console.log(items);
      if (items.length == 0) {
        this.resources.push(this.getNewResourceItem());
      } else {
        items.forEach((item) => {
          let SlNo = 1;
          this.resources.push(
            new ItemList(
              SlNo,
              item.AllocationCode,
              item.WorkerCode,
              item.Hours,
              item.Rate,
              item.RateType,
              item.Comments
            )
          );
          SlNo += 1;
        });
      }
    },
    getNewResourceItem(slno = 1) {
      let SlNo = slno;
      let AllocationCode = 0;
      let WorkerCode = 0;
      let Hours = 0;
      let Rate = 0;
      let RateType = '';
      let Comments = '';
      return new ItemList(
        SlNo,
        AllocationCode,
        WorkerCode,
        Hours,
        Rate,
        RateType,
        Comments
      );
    },
    addNewRow() {
      this.resources.push(this.getNewResourceItem(this.resources.length + 1));
	//   this.changeInput()
    },
    deleteRow(index) {
      this.resources.splice(index, 1);
      if (this.resources.length == 0) {
        this.addNewRow();
      }
	  this.changeInput()
    },

    resetUtilization() {
      this.resources = [];
      this.addNewRow();
    },
    getRef(fieldname, rowIndex) {
      return `${fieldname}-${rowIndex}`;
    },
    updateUtilizationDate(updatedDate) {
      this.errorMessage = '';
      this.utilizationDate = updatedDate;
	  this.changeInput()
    },

    updateRate(resourceCode, RowIndex) {
      this.clearErrorMessage();
      let worker = this.workers.filter((worker) => {
        return worker.resourceCode == resourceCode;
      });
      this.resources[RowIndex].Rate = worker[0].resourceCost;
      this.resources[RowIndex].RateType = worker[0].resourceUtilizationUnit;
    },
    validateResources() {
      this.errorMessage = '';

      for (let index = 0; index < this.resources.length; index++) {
        const resource = this.resources[index];

        if (!resource.AllocationCode) {
          let fieldname = this.getRef('allocation', index);
          this.errorMessage = `Select allocation`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (!resource.WorkerCode) {
          let fieldname = this.getRef('worker', index);
          this.errorMessage = `Select worker`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (!resource.Hours) {
          let fieldname = this.getRef('Hours', index);
          this.errorMessage = `Enter hours`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (isNaN(resource.Hours)) {
          let fieldname = this.getRef('Hours', index);
          this.errorMessage = `Invalid hours`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (!resource.Rate) {
          let fieldname = this.getRef('Rate', index);
          this.errorMessage = `Enter Rate`;
          document.getElementById(fieldname).focus();
          return false;
        }
        if (isNaN(resource.Rate) || Math.sign(resource.Rate) == -1) {
          let fieldname = this.getRef('Rate', index);
          this.errorMessage = `Invalid Rate`;
          document.getElementById(fieldname).focus();
          return false;
        }
      }

      return true;
    },
    clearErrorMessage() {
      this.errorMessage = '';
	  this.changeInput()
    },
  },
  computed: {
    getCaption() {
      return `Schedule: ${this.productionStageName} `;
    },
    setRate(rate) {
      return rate;
    },
	getScheduleCode() {
		return localStorage.getItem('scheduleCode');
	}
  },
};
</script>
<style scoped>
.hr_line {
  border-top: 3px solid saddlebrown;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 10px;
}
</style>
