<template>
  <div>
    <v-card dense flat>
      <v-row no-gutters class="pt-6 ma-1 d-flex">
        <v-col xs="4" sm="4" md="3" lg="3" class="d-inline-flex pl-4 mb-2" cols="7">
          <v-select
            dense
            outlined
            v-model="selectedFilter"
            :items="fields"
            label="Search"
            flat
            hide-details="true"
            class="caption primary--text px-0"
            prepend-inner-icon="mdi-filter"
			@change="updateFilterText(selectedFilter)"
          >
          </v-select>
        </v-col>
        <v-col auto class="mx-3" cols="4">
          <v-text-field
            v-model="filterText"
            :label="selectedFilter"
			type="text"
            clearable
            dense
            outlined
            hide-details="true"
            prepend-inner-icon="mdi-magnify"
            class="caption primary--text px-0"
            v-on:keyup.enter="updateFilterValue(selectedFilter, filterText)"
			@focus="updateFilter"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="ma-3 ml-5 d-flex">
        <v-col auto>
          <v-chip
            v-show="dataFilter.value"
            close
            label
            color="blue-grey darken-1"
            text-color="white"
            v-for="dataFilter in dataFilters"
            :key="dataFilter.code"
            class="caption mb-4 mr-2"
            v-on:click:close="clearFilter(dataFilter.name)"
          >
            {{ `${dataFilter.name} : ${dataFilter.value}` }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    rowFilters: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      selectedFilter: 'Workorder ID',
      filterText: '',
      fields: [
        'Workorder ID',
        'Workorder No',
        'Item Name',
        'Item Code',
        'Customer',
        'Salesman',
      ],
      dataFilters: null,
    };
  },
  created() {
    this.dataFilters = this.rowFilters;
  },
  methods: {
    updateFilterValue(filterName, filterText) {
      this.dataFilters = this.dataFilters.map((element) => {
        if (element.name == filterName) {
          return {
            name: element.name,
            code: element.code,
            value: filterText,
          };
        }
        return element;
      });

      this.filterData();
      this.filterText = '';
    },
    clearFilter(filterName) {
      this.updateFilterValue(filterName, null);
	  this.$emit('clearFilter')
    },
    filterData() {
      let filters = {};
      this.dataFilters.forEach((element) => {
        if (element.value) {
          filters = {
            ...filters,
            [element.code]: element.value,
          };
        }
      });
      this.$emit('FilterData', filters);
    },
	updateFilterText(selectedFilter) {
		this.$emit('updateFilterText', selectedFilter);
	},
	updateFilter() {
		this.$emit('updateFilter');
	}
  },
};
</script>
