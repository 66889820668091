export default class ItemList {
  constructor(
    SlNo = null,
    AllocationCode = null,
    WorkerCode = null,
    Hours = 0,
    Rate = 0,
    RateType = '',
    Comments = ''
  ) {
    this.SlNo = SlNo;
    this.AllocationCode = AllocationCode;
    this.WorkerCode = WorkerCode;
    this.Hours = Hours;
    this.Rate = Rate;
    this.RateType = RateType;
    this.Comments = Comments;
  }
}
