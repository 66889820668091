<template>
  <div>
    <v-container>
      <v-row justify="space-between" class="px-0 mx-2 d-flex align-start">
        <v-col
          xs="12"
          sm="12"
          md="5"
          lg="5"
          class="caption d-inline-flex primary--text pb-0 mb-0"
        >
          <v-row class="caption d-inline-flex primary--text pb-2">
            <v-col cols="3" class="align-self-center">
              <v-select v-model="numberOfRows" :items="rowsperpage" dense />
            </v-col>
            <v-col cols="5" class="align-self-center">
              <div class="caption grey--text pb-2" v-if="TotalRows > 1">
                {{ PageRange }}
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col xs="12" sm="12" md="5" lg="5" class="caption primary--text pb-2">
          <v-pagination
            v-model="page"
            :length="TotalPages"
            :total-visible="7"
            circle
            :value="page"
			next-icon="mdi-arrow-right-bold"
			prev-icon="mdi-arrow-left-bold"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  props: {
    TotalPages: Number,
    TotalRows: Number,
    selectedFilter: String,
    isFilterActive: Boolean,
  },
  data() {
    return {
      page: 1,
      numberOfRows: 25,
      rowsperpage: [10, 15, 20, 25, 50, 100],
    };
  },
  methods: {
    ChangePage() {
      console.log(`pagination - ${this.page}`);
      this.$emit('pagechange', this.page, this.numberOfRows);
    },
  },
  computed: {
    PageRange() {
      let lastRow = this.page * this.numberOfRows;
      let startingRow = this.page * this.numberOfRows - this.numberOfRows + 1;
      lastRow = lastRow > this.TotalRows ? this.TotalRows : lastRow;
      return `per page. ${startingRow} - ${lastRow} of ${this.TotalRows} records`;
    },
  },
  watch: {
    page: 'ChangePage',
    numberOfRows: 'ChangePage',
	selectedFilter(value) {
		if(value) {
			this.page = 1
		}
	},
	isFilterActive(value) {
		if(value === true) {
			this.page = 1
		}
	}
  },
};
</script>
