import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
// import { colors } from "vuetify/lib";
// import light from "./theme";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        menubackground: '#FFF',
        drawerbackgrund: '#1D2F41',
        background: '#F7F7F7',
        pagetitle: '#16cbe8',
        contenttext: '#00344D',
        menubackgroundother: '95D8EB',
        menuback1: '#001730',
        primary: '#005b86', //'#004F76',
        secondary: '#ebf2f3',
        primaryBlue: '005b86',
        background4: '#ade8f4',
        grey: '#555555', // '#0e0e0e',
        highlight: '#860025',
        workorderCard: '#f0f8ff',
      },
    },
  },
});
