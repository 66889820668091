<template>
  <v-card outlined dense class="pb-10">
    <v-card-title
      color="blue"
      class="primary--text caption font-weight-bold  mx-2 mb-3 mt-2"
    >
      <v-icon left small class="white primary--text">mdi-clock-outline</v-icon>
      Resource utilization history
    </v-card-title>

    <v-row color="green" class="px-10">
      <v-divider></v-divider>
    </v-row>
    <div
      v-for="(resource, resourceIndex) in resourceHistory"
      :key="resourceIndex"
    >
      <v-row dense class="px-7 py-1 mt-3">
        <v-col xs="6" sm="4" md="1" lg="1" align-self="center">
          <DeleteAction
            Message="Are you sure to delete this utilization ?"
            @delete="deleteResourceUtilitzation(resourceIndex)"
          />
        </v-col>
        <v-col lg="1">
          <div class="caption grey--text ">Date</div>
          <div class="caption primary--text">
            {{ resource.utilizationDate | formatToLocalDate }}
          </div>
        </v-col>
        <v-col lg="1">
          <div class="caption grey--text">Allocation</div>
          <div class="caption primary--text ">
            {{ resource.allocatedResourceName }}
          </div>
        </v-col>
        <v-col lg="1">
          <div class="caption grey--text">Resource</div>
          <div class="caption primary--text ">
            {{ resource.resourceName }}
          </div>
        </v-col>
        <v-col lg="1">
          <div class="caption grey--text">Rate</div>
          <div class="caption primary--text ">
            {{ resource.rate }} {{ `${resolveUnit(resource.utilization_unit)}` }}
          </div>
        </v-col>
        <v-col lg="1">
          <div class="caption grey--text text-right mr-2">Duration</div>
          <div class="caption primary--text text-right mr-2">
            {{ resource.duration }}
          </div>
        </v-col>
        <v-col lg="2">
          <div class="caption grey--text">Comments</div>
          <div class="caption primary--text ">
            {{ resource.comments }}
          </div>
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-divider></v-divider>
      </v-row>
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import DeleteAction from './DeleteAction';
export default {
  props: {
    resourceHistory: Array,
  },
  components: {
    DeleteAction,
  },
  data() {
    return {};
  },
  methods: {
    deleteResourceUtilitzation(rowIndex) {
      this.$emit('deleteUtilization', rowIndex);
    },
	resolveUnit(unit) {
		return unit === 1 ? 'Hourly' : 'Fixed'
	}
  },
  computed: {
    ...mapGetters(['userId']),
  },
};
</script>
