<template>
  <div>
    <v-snackbar top v-model="displayStatus" :timeout="5000" color="success">
      <span>Material issue saved successfully</span>
      <v-btn class="mx-2 pl-2 " icon @click="displayStatus = false">
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>

    <v-row no-gutters>
      <v-col xs="12" sm="12" md="12" lg="12" cols="12">
        <!-- <v-card class="ma-2" outlined dense height="280px"> -->
          <v-card-title
            color="blue"
            class="primary--text caption font-weight-bold  mx-2 mb-2"
          >
            <v-icon left small class="white primary--text">
              mdi-sitemap
            </v-icon>
            Material Issue/Return
          </v-card-title>
          <v-row dense class="mx-4 mb-4">
            <v-col xs="12" sm="12" md="2" lg="3" cols="12" class="mb-0">
              <v-select
                :items="locations"
                item-text="locationName"
                item-value="locationCode"
                v-model="productionStageLocation"
                class="caption mb-0"
                dense
                outlined
                label="Location"
                placeholder="Location"
                :hide-details="hide_details_false"
                id="locationCode"
				@change="changeInput"
              >
              </v-select>
			</v-col>
              <!-- <v-row class="d-flex flex-row ma-0 pa-0" dense> -->
                <v-col xs="6" sm="5" md="4" lg="2" cols="12" >
                  <v-radio-group
                    v-model="issueType"
                    row
                    class="caption primary--text ma-0 pa-0" dense hide-details
					@change="changeInput"
                  >
                    <v-radio :value="issueTypeIssue">
                      <template v-slot:label>
                        <div class="caption primary--text">Issue</div>
                      </template>
                    </v-radio>
                    <v-radio :value="issueTypeReturn">
                      <template v-slot:label>
                        <div class="caption primary--text">Return</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>

                <v-col lg="2" md="2" cols="12">
                  <WorkorderDate
                    :label="getCaption"
                    :woDate="issueDate"
                    v-on:dateChange="updateIssueDate($event)"
                    class="caption pt-4"
                    id="IssueDate"
                  />
                </v-col>
              <!-- </v-row> -->
              <!-- <v-row class="d-flex flex-row ma-0 pa-0 mb-3" dense> -->
                <v-col xs="6" sm="5" md="2" lg="2" cols="12">
                  <v-select
                    :items="allocations"
                    item-text="allocationName"
                    item-value="allocationCode"
                    v-model="resourceAllocationId"
                    class="caption"
                    dense
                    outlined
                    label="Resource"
                    placeholder="Select resource"
                    hide-details="false"
                    id="resourceAllocationId"
                    @change="getAllocatedQuantity"
                  >
                  </v-select>
                </v-col>
                <v-col lg="2" md="2" cols="12">
                  <v-select
                    :items="workcentres"
                    item-text="name"
                    item-value="id"
                    v-model="workcenterCode"
                    class="caption mt-0 pt-0"
                    dense
                    outlined
                    label="Workcenter"
                    placeholder="Select Workcenter"
                    hide-details="false"
                    id="workcentre"
					@change="clearErrorMessage"
                  >
                  </v-select>
                </v-col>
          </v-row>
        <!-- </v-card> -->
      </v-col>
    </v-row>
	<v-divider></v-divider>
    <v-row class="mb-2" no-gutters>
      <v-col xs="12" sm="12" md="12" lg="12">
        <!-- <v-card class="ma-2" outlined dense> -->
          <v-card-title
            color="blue"
            class="primary--text caption font-weight-bold  mx-2 mb-0 pb-0 "
          >
            <!-- <v-row  justify="space-between" no-gutters>
              <v-col xs="6" sm="7" md="6" lg="6" cols="12">
                <v-icon left small class="white primary--text">
                  mdi-sitemap
                </v-icon>
                {{ `Materials ${caption}` }}
              </v-col>
              <v-col class="caption" cols="12" md="4" lg="4" >
                <div class="d-flex justify-end">
                  <v-radio-group
                    v-model="issueFrom"
                    row
                    class="caption primary--text justify-end d-inline-block"
                    hide-details="auto" dense
                  >
                    <v-radio :value="issueFromBomHistory" class="">
                      <template v-slot:label>
                        <div class="caption primary--text">History</div>
                      </template>
                    </v-radio>
                    <v-radio :value="issueFromBom">
                      <template v-slot:label>
                        <div class="caption primary--text">BOM</div>
                      </template>
                    </v-radio>
                    <v-radio :value="issueFromNone" justify-end>
                      <template v-slot:label>
                        <div class="caption primary--text">None</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row> -->
			<v-row class=".d-inline-flex justify-space-between" no-gutters>
              <v-col xs="6" sm="7" md="8" lg="6" cols="12">
                <v-icon left small class="white primary--text">
                  mdi-sitemap
                </v-icon>
                {{ `Materials ${caption}` }}
              </v-col>
              <v-col auto class="caption" xs="6" sm="7" md="4" lg="4" cols="12" >
                <div class="d-flex justify-end">
                  <v-radio-group
                    v-model="issueFrom"
                    row dense
                    class="caption primary--text justify-end d-inline-block ma-0"
                    hide-details="true" @change="changeInput"
                  >
                    <v-radio :value="issueFromBomHistory" class="">
                      <template v-slot:label>
                        <span class="caption primary--text">History</span>
                      </template>
                    </v-radio>
                    <v-radio :value="issueFromBom">
                      <template v-slot:label>
                        <div class="caption primary--text">BOM</div>
                      </template>
                    </v-radio>
                    <v-radio :value="issueFromNone" justify-end>
                      <template v-slot:label>
                        <div class="caption primary--text">None</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <hr class="hr_line my-4" />
          <v-card
            :id="RowIndex"
            v-for="(material, RowIndex) in materials"
            :key="RowIndex"
            dense
            flat
          >
            <v-row class="pb-3 mx-4 pt-0">
              <v-col xs="auto" sm="2" md="1" lg="3" class="" cols="12">
                <v-autocomplete
                  ref="field"
                  :items="locationStock"
                  item-text="itemName"
                  item-value="stockId"
                  v-model="material.ItemCode"
                  class="caption"
                  dense
                  outlined
                  label="Item Name"
                  placeholder="Select item"
                  hide-details="false"
                  :id="getRef('itemname', RowIndex)"
                  @change="updateItemDetails(material.ItemCode, RowIndex)"
                  v-if="material.MaterialFrom == issueFromNone"
                ></v-autocomplete>
                <v-text-field
                  label="Item name"
                  outlined
                  dense
                  hide-details
                  readonly
                  v-model="material.ItemName"
                  class="caption"
                  :id="getRef('ItemName', RowIndex)"
                  v-else
                >
                </v-text-field>
              </v-col>
              <v-col xs="6" sm="2" md="1" lg="2" class="caption" cols="6">
                <v-text-field
                  label="Item code"
                  outlined
                  dense
                  hide-details
                  v-model="material.ItemCode"
                  class="caption"
                  readonly
                  :id="getRef('ItemCode', RowIndex)"
                >
                </v-text-field>
              </v-col>

              <v-col xs="6" sm="2" md="1" lg="1" class="rate" cols="6">
                <v-text-field
                  label="Rate"
                  outlined
                  dense
                  hide-details
                  reverse
                  readonly
                  v-model="material.UnitQuantity"
                  class="caption"
                  :id="getRef('UnitQuantity', RowIndex)"
                >
                </v-text-field>
              </v-col>
              <v-col xs="6" sm="2" md="1" lg="2" cols="6">
                <v-text-field
                  label="Stock"
                  outlined
                  dense
                  hide-details
                  reverse
                  readonly
                  v-model="material.StockQuantity"
                  class="caption"
                  :id="getRef('StockQuantity', RowIndex)"
                >
                </v-text-field>
              </v-col>
              <v-col xs="6" sm="2" md="1" lg="1" class="" cols="6">
                <v-text-field
                  ref="entry"
                  @click="select"
                  :label="getQuantityCaption"
                  outlined
                  dense
                  hide-details
                  reverse
                  v-model="material.IssueQuantity"
                  class="caption"
                  :id="getRef('IssueQuantity', RowIndex)"
                  v-on:change="checkIssue(RowIndex, $event)"
				  @keypress="clearErrorMessage"
                >
                </v-text-field>
              </v-col>
              <v-col xs="6" sm="2" md="1" lg="1" class="" cols="6">
                <v-text-field
                  label="Units"
                  outlined
                  dense
                  hide-details
                  readonly
                  v-model="material.Unit"
                  :id="getRef('Unit', RowIndex)"
                  class="caption"
                >
                </v-text-field>
              </v-col>

              <v-col xs="6" sm="1" md="1" lg="1" align-self="start">
                <div class="caption grey--text text-center mt-n2">Delete</div>
                <div class="caption red--text text-center">
                  <v-icon
                    @click="deleteRow(RowIndex)"
                    class="red--text"
                    :disabled="
                      material.MaterialFrom == issueFromNone ? false : true"
                  >
                    mdi-delete
                  </v-icon>
                </div>
              </v-col>
              <v-col
                xs="6"
                sm="1"
                md="1"
                lg="1"
                align-text="center"
                v-show="RowIndex == materials.length - 1"
              >
                <div class="caption grey--text text-left mt-n2">New</div>
                <div class="caption green--text text-left">
                  <v-icon @click="addNewRow()" class="green--text">
                    mdi-plus-box
                  </v-icon>
                </div>
              </v-col>
            </v-row>
            <hr class="hr_line_light" />
          </v-card>

          <v-row dense class="pb-4 mx-4 ">
            <v-col cols="12" class="center">
              <div v-show="errorMessage.length > 1">
                <v-alert dense text type="error" class="caption">
                  {{ errorMessage }}
                </v-alert>
              </div>
              <v-btn
                small
                depressed
                color="yellow accent-2"
                class="primary--text"
                @click.stop="saveMaterialIssue"
                :disabled="saveInProgress"
              >
                <v-icon left color="primary">
                  mdi-content-save
                </v-icon>
                {{ `Save Material ${caption}` }}
              </v-btn>
            </v-col>
          </v-row>
        <!-- </v-card> -->
      </v-col>
    </v-row>
    <template v-if="materialsHistory.length > 0">
	<v-divider></v-divider>
      <MaterialHistory :materialsHistory="materialsHistory" />
    </template>
  </div>
</template>
<script>
import Workorders from '@/Services/workorders';
import WorkorderDate from './WorkorderDate';
import ItemList from '@/Utils/Materials.js';
import moment from 'moment';
import MaterialHistory from './MaterialIssueHistory2';
import { StatusCodes } from 'http-status-codes';
export default {
  name: 'AssignmentAllocation',
  props: {
    scheduleCode: String,
  },
  components: {
    WorkorderDate,
    MaterialHistory,
  },
  data() {
    return {
      hide_details_true: true,
      hide_details_false: false,
      snackbar: false,
      workorderId: null,
      workorderReference: null,
      workorderStartDate: null,
      workorderEndDate: null,
      scheduleStartDate: null,
      scheduleEndDate: null,
      issueDate: moment().format('YYYY-MM-DD'),
      itemQuantity: null,
      itemUnits: null,
      itemCode: null,
      itemName: null,
      productionStageName: null,
      prodStageCode: null,
      productionStageLocation: null,
      isAccepted: 0,
      acceptance: null,
      isClosed: 0,
      closed: null,
      productionStageColor: null,
      workorderStatusCode: null,
      workorderStatus: null,
      workorderStatusColor: null,
      productionStageProgress: 0,
      statusComment: '',
      resourceCode: '',
      locationCode: '',
      bomItems: [],
      bomHistoryItems: [],
      materials: [],
      locations: [],
      allocations: [],
      displayStatus: false,
      errorMessage: '',
      resourceAllocationId: null,
      allocatedQuantity: 0,
      locationStock: [],
      issueType: 0,
      issueTypeIssue: 0,
      issueTypeReturn: 1,
      issueFrom: 3,
      issueFromBomHistory: 1,
      issueFromBom: 2,
      issueFromNone: 3,
      caption: 'Issue',
      workcentres: [],
      workcenterCode: null,
      materialsHistory: [],
      saveInProgress: false,
      materialTotal: null,
      labourTotal: null,
      issueValue: null,
      isIssueValueAlert: false,
    };
  },
  created() {
	// this.$store.commit('showProgressCircular', 500)
	// setTimeout(() => {
    	this.$store.dispatch('showMyAssignment', true);
	// }, 0);
	this.getResourceAllocations();
    this.getWorkcentres();
    this.getLocations();
    this.setupMaterials();
  },
  methods: {
    async getLocations() {
      const response = await Workorders.getLocations();
      this.locations = response.data.data;
    },
    async getWorkcentres() {
      const response = await Workorders.getWorkcentres();
      this.workcentres = response.data.data;
    },
    async getBom() {
      const response = await Workorders.getBom(
        this.itemCode,
        this.productionStageLocation
      );
      this.bomItems = response.data.data;
      this.setupMaterials(this.bomItems, this.issueFromBom);
    },
    async getBomHistory() {
      const response = await Workorders.getBomHistory(
        this.itemCode,
        // this.workcenterCode
        this.allocatedQuantity,
        this.productionStageLocation
      );
      this.bomHistoryItems = response.data.data;
      this.setupMaterials(this.bomHistoryItems, this.issueFromBomHistory);
    },
    async getComponents(productionStageLocation) {
      const response = await Workorders.getComponents(productionStageLocation);
      this.locationStock = response.data.data;
    },
    async getMaterialIssueReturnHistory() {
      const response = await Workorders.getMaterialIssueReturnHistory(
        this.workorderId,
        this.getScheduleCode,
        this.prodStageCode,
        this.resourceAllocationId
      );
      this.materialsHistory = response.data.data;
      console.log(this.materialsHistory);
    },
    async getMaterialFromBOM() {
      switch (this.issueFrom) {
        case this.issueFromBom:
          this.getBom();
          break;
        case this.issueFromBomHistory:
          this.getBomHistory();
          break;
        case this.issueFromNone:
          this.setupMaterials([], this.issueFromNone);
          break;
      }
    },
    async calculateIssueQunatity() {
      for (let index = 0; index < this.materials.length; index++) {
        if (this.materials[index].MaterialFrom != this.issueFromNone) {
          this.materials[index].IssueQuantity =
            this.materials[index].UnitQuantity * this.allocatedQuantity;
        }
      }
    },
    checkIssue(RowIndex) {
      const focusRef = this.$refs.entry;
      const issueValue = focusRef[RowIndex].value;
      this.issueValue = issueValue;
      if (issueValue <= 0) {
        this.errorMessage = 'Missing Issue Quantity';
      } else {
        this.errorMessage = '';
      }
    },
    async saveMaterialIssue() {
      try {
        if (!this.validateMaterialIssue()) return;
        this.saveInProgress = true;
        let materialissue = {
          workorderId: this.workorderId,
          reference: this.workorderReference,
          issueDate: this.issueDate,
          locationCode: this.productionStageLocation,
          workcenterCode: this.workcenterCode,
          productionStageId: this.prodStageCode,
          woschedule: this.getScheduleCode,
          allocationId: this.resourceAllocationId,
          issueType: this.issueType,
          issueDetails: [],
        };
        this.materials.forEach((resource) => {
          materialissue.issueDetails.push({
            stockId: resource.ItemCode,
            qtyIssued: resource.IssueQuantity,
            componentCost: resource.UnitQuantity,
          });
        });
        if (this.issueValue >= 0) {
          const response = await Workorders.saveMaterialIssue(materialissue);
          if (response.data.header.success) {
			this.$store.commit('showProgressCircular', 2000)
			setTimeout(() => {
				this.displayStatus = true;
      		}, 2000)
            this.issueFrom = this.issueFromNone;
            this.getMaterialFromBOM();
            this.getMaterialIssueReturnHistory();
            this.getComponents(this.productionStageLocation);
          }
        }
      } catch (error) {
        if (error.response.status == StatusCodes.PRECONDITION_FAILED) {
          this.errorMessage = error.response.data.header.message;
        }
      }
      this.saveInProgress = false;
	  this.$emit('saveDone')
    },
    async getResourceAllocations() {
      const response = await Workorders.getResourceAllocations(
        this.getScheduleCode
      );
      let workorder = response.data.data;
      this.materialTotal = workorder.materialCost;
      this.labourTotal = workorder.resourceCost;
      this.setupWorkorderDetails(workorder);
      this.getComponents(workorder.productionStageLocation);
    },
    setupWorkorderDetails(workorder) {
      console.log(workorder);
      this.workorderId = workorder.workorderId;
      this.workorderReference = workorder.workorderReference;
      this.prodStageCode = workorder.productionStageId;
      this.productionStageName = workorder.productionStageName;
      this.workorderStartDate = workorder.woScheduleStartDate;
      this.workorderEndDate = workorder.woScheduleStartDate;
      this.scheduleStartDate = workorder.scheduledStartDate;
      this.scheduleEndDate = workorder.scheduledEndDate;
      this.itemQuantity = workorder.itemQuantity;
      this.itemUnits = workorder.itemUnits;
      this.itemCode = workorder.itemCode;
      this.itemName = workorder.itemDescription;
      this.isAccepted = workorder.isAccepted;
      this.acceptance = workorder.acceptance;
      this.isClosed = workorder.isClosed;
      this.closed = workorder.closed;
      this.productionStageColor = workorder.productionStageColor;
      this.workorderStatusCode = workorder.workorderStatusCode;
      this.workorderStatus = workorder.workorderStatus;
      this.workorderStatusColor = workorder.workorderStatusColor;
      this.productionStageProgress = workorder.productionStageProgress;
      this.resourceCode = workorder.resourceCode;
      this.statusComment = workorder.statusComment;
      this.locationCode = workorder.locationCode;
      this.productionStageLocation = workorder.productionStageLocation;
      this.setupAllocations(workorder.allocation);
    },
    setupAllocations(allocations) {
      allocations.forEach((allocation) => {
        this.allocations.push({
          allocationCode: allocation.resourceAllocationId,
          allocationName: allocation.resourceName,
          allocatedQuantity: allocation.allocatedQuantity,
        });
      });
    },
    setupMaterials(items = [], itemFrom) {
      this.materials = [];
      if (items.length == 0) {
        this.materials.push(this.getNewMaterial());
      } else {
        items.forEach((item) => {
          let SlNo = 1;
          this.materials.push(
            new ItemList(
              SlNo,
              item.componentCode,
              item.componentName,
              item.currentStock,
              item.componentCost,
              item.componentQuantity,
              item.componentUnit,
              item.componentQuantity * this.allocatedQuantity,
              itemFrom
            )
          );
          SlNo += 1;
        });
      }
    },
    getNewMaterial(slno = 1) {
      let SlNo = slno;
      let ItemCode = 0;
      let ItemName = '';
      let StockQuantity = 0;
      let UnitQuantity = 0;
      let IssueQuantity = 0;
      let Unit = '';
      let Price = 0;
      return new ItemList(
        SlNo,
        ItemCode,
        ItemName,
        StockQuantity,
        UnitQuantity,
        IssueQuantity,
        Unit,
        Price,
        this.issueFromNone
      );
    },
    addNewRow() {
      this.materials.push(this.getNewMaterial(this.materials.length + 1));
	//   this.changeInput()
    },
    deleteRow(index) {
      this.materials.splice(index, 1);
      if (this.materials.length == 0) {
        this.addNewRow();
      }
	  this.changeInput()
    },
    getRef(fieldname, rowIndex) {
      return `${fieldname}-${rowIndex}`;
    },

    updateIssueDate(updatedDate) {
      this.errorMessage = '';
      this.issueDate = updatedDate;
	  this.changeInput()
    },
    updateItemDetails(itemCode, RowIndex) {
      let itemdetail = this.locationStock.filter((item) => {
        return item.stockId == itemCode;
      });
      this.materials[RowIndex].ItemName = itemdetail[0].itemName;
      this.materials[RowIndex].StockQuantity = itemdetail[0].stockQty;
      this.materials[RowIndex].IssueQuantity = 0;
      this.materials[RowIndex].Unit = itemdetail[0].itemUnits;
      this.materials[RowIndex].UnitQuantity = itemdetail[0].componentCost;
      this.focusOnIssue(RowIndex);
	//   this.clearErrorMessage();
    },
    select(event) {
      event.target.setSelectionRange(0, 1);
    },
    focusOnIssue(RowIndex) {
      const focusRef = this.$refs.entry;
      console.log(focusRef);
      focusRef[RowIndex].focus();
      const issueValue = focusRef[RowIndex].value;
      this.issueValue = issueValue;
      console.log(this.issueValue);
    },
    validateMaterialIssue() {
      this.errorMessage = '';
      let issueQuantityExists = false;

      if (!this.resourceAllocationId) {
        this.errorMessage = `Select resource`;
        document.getElementById('resourceAllocationId').focus();
        return false;
      }

      if (!this.workcenterCode) {
        this.errorMessage = `Select workcenter`;
        document.getElementById('workcentre').focus();
        return false;
      }

      for (let index = 0; index < this.materials.length; index++) {
        const material = this.materials[index];

        if (!material.ItemName) {
          let fieldname = this.getRef('itemname', index);
          this.errorMessage = `Select Item`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (!material.IssueQuantity) {
          let fieldname = this.getRef('Quantity', index);
          this.errorMessage = `Enter ${this.getQuantityCaption}`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (isNaN(material.IssueQuantity)) {
          let fieldname = this.getRef('IssueQuantity', index);
          this.errorMessage = `Invalid Issue Quantity`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (material.IssueQuantity > material.StockQuantity) {
          let fieldname = this.getRef('IssueQuantity', index);
          this.errorMessage = `Issue quantity can not be greater than stock quantity`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (material.IssueQuantity > 0) {
          issueQuantityExists = true;
        }
      }

      if (!issueQuantityExists) {
        this.errorMessage =
          'Quantity should be greater than 0 for at least one line item';
        return false;
      }
      return true;
    },
    getAllocatedQuantity() {
    //   this.clearErrorMessage();
      let allocation = this.allocations.filter((element) => {
        return element.allocationCode == this.resourceAllocationId;
      });
      this.allocatedQuantity =
        allocation.length > 0 ? allocation[0].allocatedQuantity : 0;
    },

    setupCaptions() {
      this.caption = this.issueType == this.issueTypeIssue ? 'Issue' : 'Return';
    },
    clearErrorMessage() {
      this.errorMessage = '';
	  this.changeInput()
    },
	changeInput() {
		this.$emit('userChange')
	},
  },
  computed: {
    getCaption() {
      return `${this.caption} Date `;
    },
    getQuantityCaption() {
      return `${this.caption}`;
    },
	getScheduleCode() {
		return localStorage.getItem('scheduleCode');
	}
  },
  watch: {
    issueFrom: function() {
      this.getMaterialFromBOM();
    },
    allocatedQuantity: function() {
      this.calculateIssueQunatity();
    },
    issueType: function() {
      this.setupCaptions();
    },
    resourceAllocationId: function() {
      this.getMaterialIssueReturnHistory();
    },
    productionStageLocation: function() {
      this.materials = [];
      this.addNewRow();
      this.getComponents(this.productionStageLocation);
    },
  },
};
</script>
<style scoped>
.hr_line {
  border-top: 3px solid saddlebrown;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 10px;
}
.hr_line_light {
  border-top: 1px #97c7e7;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 15px;
}

v-text-field {
  width: 50px;
}

.v-text-field__slot {
  width: 50px;
}

.v-input__slot {
  width: 50px;
}

.v-input__control {
  width: 50px;
}

.rate {
  width: 50px;
}

.v-input--radio-group .v-input__slot {
    margin-bottom: 4px;
}
</style>
