<template>
  <div>
    <v-row v-if="messages.lenght > 0">
      <v-col cols="12">
        <v-card dense outlined>
          <v-card-title
            color="blue"
            class="pagetitle--text overline font-weight-bold"
          >
            <v-icon left small class="white secondary--text"> mdi-bell</v-icon>
            Notifications
          </v-card-title>

          <ul
            flat
            color="purple darken-4"
            class="red--text overline font-weight-bold mx-5 pb-4"
            style="list-style-type:square;"
          >
            <li v-for="message in messages" :key="message.id">
              {{ message.message_text }}
            </li>
          </ul>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <WorkorderList></WorkorderList>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="6" cols="12">
        <!-- <AttendanceView></AttendanceView> -->
      </v-col>
      <v-col xs="12" sm="12" md="6" cols="12">
        <!-- <Holidays></Holidays> -->
      </v-col>
    </v-row>
    <v-row> </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import User from '@/Services/user';
// import AttendanceView from '@/components/Attendance/AttendanceCalendar';
// import Holidays from '@/components/Leave/Holidays';
import WorkorderList from '@/components/Workorder/WorkorderList2';

export default {
  components: {
    WorkorderList,
    // AttendanceView,
    // Holidays,
  },
  data() {
    return {
      userImage: null,
      messages: [],
    };
  },
  created() {
    // this.getUserImage(this.userId);
  },
  methods: {
    async getUserImage(empId) {
      const response = await User.getUserImage(empId);
      var mimeType = response.headers['content-type'].toLowerCase();
      var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
      this.userImage = 'data:' + mimeType + ';base64,' + imgBase64;
    },
  },
  computed: {
    ...mapGetters(['dashboard', 'userId']),
  },
  watch: {
    dashboard: function(val) {
      if (val) {
        this.messages = val.messages;
      }
    },
  },
};
</script>

<style scoped></style>
