<template>
  <v-card max-width="400" flat>
    <v-img
      :src="productImage"
      height="200px"
      width="400px"
      class="product"
      contain
    ></v-img>
    <!-- <h6 class="caption highlight--text text-center mt-1">
      {{ ProductName }}
    </h6> -->
  </v-card>
</template>
<script>
import Workorders from '@/Services/workorders';
export default {
  name: 'product',
  props: {
    name: String,
    productCode: String,
  },
  data() {
    return {
      productImage: '',
    };
  },
  mounted() {
    this.getProductImage();
  },
  methods: {
    async getProductImage() {
    //   if (!this.productCode) return;
      const response = await Workorders.getProductImage(this.productCode);
      var mimeType = response.headers['content-type'].toLowerCase();
      var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
      this.productImage = 'data:' + mimeType + ';base64,' + imgBase64;
    },
  },
  computed: {
    ProductName() {
      return `${this.name} (${this.productCode})`;
    },
  },
  watch: {
	  productCode(value) {
		  if(value) {
			  this.getProductImage();
		  }
	  }
  }
};
</script>
<style scoped>
/* .product:hover {
  transform: scale(2);
} */
</style>
