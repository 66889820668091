export default class ItemList {
  constructor(
    SlNo = null,
    WorkerCode = null,
    StartDate = null,
    EndDate = null,
    Quantity = 0,
    Unit = null,
    Price = 0,
    RateType = '',
    Progress = 0,
    QcStatus = 0,
    Issues = false,
    AllocationID = 0
  ) {
    this.SlNo = SlNo;
    this.WorkerCode = WorkerCode;
    this.StartDate = StartDate;
    this.EndDate = EndDate;
    this.Quantity = Quantity;
    this.Price = Price;
    this.Unit = Unit;
    this.RateType = RateType;
    this.Progress = Progress;
    this.QcStatus = QcStatus;
    this.Issues = Issues;
    this.AllocationID = AllocationID;
  }
}
