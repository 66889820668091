<template>
  <div>
    <div class="caption grey--text">{{ LabelText }}</div>
    <div class="caption primary--text pb-2">
      {{ LabelValue }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'Label',
  props: {
    LabelText: String,
    LabelValue: null,
  },
};
</script>
