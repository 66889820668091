<template>
  <div>
    <Search
      v-on:FilterData="SearchData"
      :rowFilters="rowFilters"
      class="mb-2"
    />
    <v-card class="mx-0 px-0">
		<v-card-title>Outstanding Workorders</v-card-title>
		 <v-card-text >
			<v-data-table
			dense
        :headers="headers"
        :items="workorderList"
        item-key="workorderId"
        :items-per-page="numberOfRows"
		calculate-widths
		no-data-text="No Data Avaialable"
		loading-text="Please wait as we load data...!!!"
		class="elevation-2 ma-1"
		fixed-header
		height="800px"
		show-expand
		single-expand
		hide-default-footer
		:page="pageNumber"
		:item-class="itemRowBackground"
		:loading="loading"
      >
		  <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
        <v-icon @click="expand(!isExpanded)">{{ isExpanded ? 'mdi-unfold-less-horizontal': 'mdi-unfold-more-horizontal' }} </v-icon>
      </template>

	  <template #[`item.woQuantity`]="{item}">
			<span > {{ `${ item.woQuantity } ${ item.units }` }} </span> <br>
    </template>
	  <template #[`item.workorderId`]="{item}">
			<div class="flex-column">
			<span class="text--primary">{{ `${ item.workorderId }` }} </span> <br>
    	<small>  {{ `${ item.workorderRef }` }} </small>
		</div>	
    </template>
	   <template #[`item.itemCode`]="{item}">
				<v-container >
				<v-row  >
					<v-col md="4">
						<product :name="item.itemDescription" :productCode="item.itemCode"></product>
						</v-col>
					<v-col md="8">
						<span class="font-weight-semibold text-truncate text-left">{{ item.itemCode }}</span><br>
          <small class="text-left ml-3" > {{ item.itemDescription }}</small>
					</v-col>
		  </v-row>
				</v-container>
    </template>
	  
	  <template #[`item.woReleasedDate`]="{item}">
      <small class="text-left">Order:</small> <span class="font-weight-bold" > {{ `${ formatDate(item.woReleasedDate) }` }}</span> <br>
	  <small class="text-left" >Required:</small><span class="font-weight-bold" >{{ `${ formatDate(item.woRequiredDate) }` }} </span>
    </template>

		<template v-slot:[`item.woProgress`]="{ item }">
<progress-bar :progress="item.woProgress" :productCode="item.workorderId" ></progress-bar>
    </template>

	  <template #[`item.materialCost`]="{item}" >
      <small>Material: </small> <span class="font-weight-bold"> {{ item.materialCost }}</span> <br>
	  <small>Labour:</small> <span class="font-weight-bold">{{ `${ item.resourceCost }` }} </span>
    </template>

	<template #expanded-item="{ headers, item }">
      <td :colspan="headers.length">
	  <v-container fluid>
	  <v-row dense class="pb-0 mb-0">
		  <v-col xs="6" sm="3" md="3" lg="3" class="ml-2" cols="12">
		  <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
          <p class="caption grey--text">
        Type:
          <span class="caption primary--text pb-2">
			  {{ item.workorderType }}
		  </span>
			  </p>
          <p class="caption grey--text">
            Location:
          <span class="caption primary--text pb-2">
			  {{ item.locationName }}
		  </span>
			  </p>
			  <p class="caption grey--text">
            Stage Status:
          <span class="caption primary--text pb-2">
			  {{ item.scheduleStatus }}
		  </span>
		  </p>
		  </v-card>
      </v-col>
      <v-col xs="6" sm="3" md="3" lg="3" class="ml-2" cols="12">
		<v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
          <p class="caption grey--text">
            Workflow:
          <span class="caption primary--text pb-2">
			  {{ item.workflowName }}
		  </span>
			  </p>
          <p class="caption grey--text">
            Delivered To:
          <span class="caption primary--text pb-2">
			  {{ item.workorderId }}
		  </span>
			  </p>
			   <p class="caption grey--text">
            Sales Ref:
          <span class="caption primary--text pb-2">
			  {{ item.salesReference }}
		  </span>
			  </p>
			  </v-card>
      </v-col>
      <v-col xs="6" sm="3" md="3" lg="2" class="ml-2">
		<v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
          <p class="caption grey--text">
            Mfgd:
          <span class="caption primary--text pb-2">
			  {{ item.woProducedQuantity }}
		  </span>
			  </p>
          <p class="caption grey--text">
            Work Type:
          <span class="caption primary--text pb-2">
			  {{ item.workType }}
		  </span>
			  </p>
			   <p class="caption grey--text">
            Sales Person:
          <span class="caption primary--text pb-2">
			  {{ item.salesMan }}
		  </span>
			  </p>
			</v-card>
      </v-col>
      <v-col xs="6" sm="3" md="3" lg="3" class="ml-2">
		<v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
          <p class="caption grey--text">
            Assigned To:
          <span class="caption primary--text pb-2">
			  {{ item.salesReference }}
		  </span>
			  </p>
          
          <p class="caption grey--text">
            WO Remarks:
          <span class="caption primary--text pb-2">
		  </span>
			  </p>
			  </v-card>
      </v-col>
	  </v-row>
	</v-container>
      </td>
    </template>
    </v-data-table>
	<WoPagination
        :TotalPages="totalPages"
        :TotalRows="totalNumberOfRows"
        v-on:pagechange="ChangePage"
      />
		</v-card-text>
		<v-card-text flat class="mx-0 px-0">
    </v-card-text>
    </v-card>

		<!-- <div v-else-if="isListandNoFilter">
    <div v-for="workorder in workorderList" :key="workorder.workorderId">
      <workorder :workorder="workorder" />
		</div> -->
    
    <!-- <v-card-text flat class="mx-0 px-0">
      <WoPagination
        :TotalPages="totalPages"
        :TotalRows="totalNumberOfRows"
        v-on:pagechange="ChangePage"
        v-show="totalPages > 1"
      />
    </v-card-text> -->
	</div>
  <!-- </div> -->
</template>
<script>
// import Workorder from '@/components/Workorder/Workorder';
import Workorders from '@/Services/workorders';
import WoPagination from '@/components/Workorder/Pagination';
import Search from '@/components/Workorder/Search';
import Utils from '@/Services/utils';
import { mapGetters } from 'vuex';
import moment from 'moment'
import product from './ProductImage.vue';
import progressBar from './ProgressBar.vue';
import { mdiArrowExpandVertical } from '@mdi/js'

export default {
  name: 'workorderList',
  components: {
    // Workorder,
    WoPagination,
    Search,
	product,
	progressBar
  },
  data() {
    return {
      workorderList: [],
      totalPages: 0,
      pageNumber: 1,
      numberOfRows: 25,
      totalNumberOfRows: 0,
      dataFilters: {},
      rowFilters: null,
	  isNoData: true,
	  headers: [
		{ text: '#', value: 'workorderId', align: 'start', divider: true, width: '12%', class: 'header' },
		{ text: 'Item', value: 'itemCode', align: 'center', divider: true, width: '25%', class: 'header' },
		{ text: 'Quantity', value: 'woQuantity', align: 'center', divider: true, width: '9%', class: 'header' },
		{ text: 'Date', value: 'woReleasedDate',align: 'center', divider: true, width: '15%', class: 'header' },
		{ text: 'Customer', value: 'customerName',align: 'center', divider: true, width: '10%', class: 'header' },
		{ text: 'Progress', value: 'woProgress',align: 'center', divider: true , width: '10%', class: 'header'},
		{ text: 'Cost', value: 'materialCost',align: 'center', width: '12%', class: 'header'  },
		{ text: 'For More Details', value: 'data-table-expand',align: 'center', width: '15%', class: 'header'}
	  ],
	  date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
	  icons: {
				mdiArrowExpandVertical
		},
		loading: false
    };
  },
  created() {
	this.$store.commit('showProgressCircular', 800)
	setTimeout(() => {	  
	this.$store.dispatch('showWorkorders', false);
	}, 0);
    this.rowFilters = Utils.getDataFilters();
    this.loadFilters();
    this.getWorkorders();
  },
  beforeDestroy() {
    this.$store.dispatch('updateWorkorderDataFilters', this.dataFilters);
  },
  methods: {
    async getWorkorders() {
		this.loading = true
		try {
      const response = await Workorders.getWOInquiry(
        this.pageNumber,
        this.numberOfRows,
        this.dataFilters
      );
      this.workorderList = response.data.data;
      this.totalPages = response.data.pageinfo.totalPages;
      this.totalNumberOfRows = response.data.pageinfo.totalRecords;
	  this.loading = false
		} catch(err) {
			this.loading = false
		}
    },
    ChangePage(moveToPage, numberOfRows) {
      this.pageNumber = moveToPage;
      this.numberOfRows = numberOfRows;
      this.getWorkorders();
    },
    SearchData(searchConditions) {
		this.$store.commit('showProgressCircular', 500)
		setTimeout(() => {
			this.dataFilters = searchConditions;
			this.getWorkorders();
		}, 0);
    },
    loadFilters() {
      if (this.workorderDataFilters) {
        this.dataFilters = this.workorderDataFilters;
      }
      for (var key in this.dataFilters) {
        this.updateFilterValue(key, this.dataFilters[key]);
      }
    },
    updateFilterValue(filterName, filterText) {
      this.rowFilters = this.rowFilters.map((element) => {
        if (element.code == filterName) {
          return {
            name: element.name,
            code: element.code,
            value: filterText,
          };
        }
        return element;
      });
    },
	formatDate(value) {
			return value === null ? '' : moment(String(value)).format('DD-MM-YYYY')
	},
	splitString1(sentence) {
		const splitted = sentence.split(' ');
			const word1 = splitted.slice(0, 2).join(' ') + " ";
			// const word2 = splitted.slice(18, splitted.length).join(' ')
			return `${word1}`
	},
	splitString2(sentence) {
		const splitted = sentence.split(' ');
			// const word1 = splitted.slice(0, 18).join(' ') + " ";
			const word2 = splitted.slice(2, splitted.length).join(' ')
			return `${word2}`
	},
	itemRowBackground(item) {
     	return item.woRequiredDate > this.date ? 'row-1' : 'row-2'
  	}
  },
  computed: {
    ...mapGetters(['workorderDataFilters']),
	isNoListandNoFilter() {
		return this.workorderList.length < 0 && Object.keys(this.dataFilters).length === 0  ? true : false
	},
	isListandNoFilter() {
		return this.workorderList.length > 0 ? true : false
	},
	isNoListandFilter() {
		return this.workorderList.length === 0 && Object.keys(this.dataFilters).length > 0 ? true : false
	},

  },
};
</script>

<style >
small{
	word-break: break-word;
	white-space:nowwrap;
	line-height: 1.4;
}
.row-1 {
  background-color: #BBDEFB
}
.row-2 {
  background-color: #E3F2FD
}
.header {
  background-color: #C5CAE9
}
.theme--light.v-data-table.v-data-table--fixed-header thead th{
	background-color: #C5CAE9
}
.v-progress-linear__content {
	position: absolute;
	z-index: 2 !important;
}
/* .v-progress-linear__content {
	position: absolute;
	z-index: 2;
} */
</style>
