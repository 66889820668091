<template>
  <div>
    <v-snackbar top v-model="displayStatus" :timeout="5000" color="success">
      <span>Assignment details saved successfully</span>
      <v-btn class="mx-2 pl-2 " icon @click="displayStatus = false">
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
    <v-row class="mb-0" no-gutters>
      <v-col xs="12" sm="12" md="12" lg="12" cols="12">
        <v-card outlined dense class="ma-2">
          <v-card-title
            color="blue"
            class="primary--text caption font-weight-bold  mx-2 "
          >
            <v-icon left small class="white primary--text">
              mdi-calendar-clock
            </v-icon>
            Schedule Details
          </v-card-title>
          <v-row dense class="pb-0 mx-4 mb-4">
            <v-col xs="8" sm="8" md="12" lg="12" cols="12">
              <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
				  <v-row>
					  <v-col lg="2" cols="6">
						  <div class="caption grey--text">Workorder:</div>
                <div class="caption primary--text pb-2">
                  {{ workorderId }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        color="yellow accent-4"
                        v-bind="attrs"
                        v-on="on"
                        v-if="scheduleComments"
                      >
                        mdi-android-messages
                      </v-icon>
                    </template>
                    <span class="caption">{{ scheduleComments }}</span>
                  </v-tooltip>
                </div>

				<div class="caption grey--text">Quantity:</div>
                <div class="caption primary--text pb-2">
                  {{ itemQuantity }} {{ itemUnits }}
                </div>
				</v-col>
					  <v-col lg="3" md="2" cols="6">
                <div class="caption grey--text">Customer:</div>
                <div class="caption primary--text pb-2">
                  {{ customerName }}
                </div>
					<div class="caption grey--text">Stage:</div>
                <div class="caption primary--text pb-2">
                  {{ productionStageName }}
                </div>
					  </v-col>
					  <v-col lg="3" cols="12">
                <div class="caption grey--text">Product :</div>
                <div class="caption primary--text pb-2">
                  {{ itemCode}} - <br> {{ itemName }}
                </div>
					  </v-col>
					  
					  
					  <v-col lg="2" cols="6">
						  <div class="caption grey--text">Start Date:</div>
                <div class="caption primary--text pb-2">
                  {{ scheduleStartDate | formatToLocalDate }}
                </div>
				<div class="caption grey--text">Material Total:</div>
                <div class="caption primary--text pb-2">{{ materialTotal }}
                </div>  
					</v-col>

					  <v-col lg="2" cols="6">
						  <div class="caption grey--text">End Date:</div>
                <div class="caption primary--text pb-2">
                  {{ scheduleEndDate | formatToLocalDate }}
                </div>

						  
						  <div class="caption grey--text">Labour Total:</div>
                <div class="caption primary--text pb-2"> {{ labourTotal }}
                </div>
					  </v-col>
				  </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card class="ma-2" outlined dense >
	<div>
    <v-expansion-panels accordion :mandatory="mandatory" flat focusable v-model="panel" hover>
      <v-expansion-panel v-model="viewPanel" @change="changePanel">
        <v-expansion-panel-header 
		class="font-weight-black overline" 
		expand-icon="mdi-view-list" 
		disable-icon-rotate :color="viewStatus" 
		>
			01. View
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
		<v-expand-transition mode="in-out">
			<view-assignment @saveDone="expansionClose" @userChange="expansionOpen" />
		</v-expand-transition>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel @change="changePanel" :disabled="disabled" >
        <v-expansion-panel-header
		class="font-weight-black overline" 
		expand-icon="mdi-ballot"
		disable-icon-rotate color="#E3F2FD"
		>
          02. Allocate
        </v-expansion-panel-header>
        <v-expansion-panel-content>
			<v-expand-transition mode="in-out">
        		<allocate-assignment @saveDone="expansionClose" @userChange="expansionOpen" />
			</v-expand-transition>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel @change="changePanel" :disabled="disabled">
        <v-expansion-panel-header
		class="font-weight-black overline"
		expand-icon="mdi-office-building-marker"
		disable-icon-rotate color="#BBDEFB"
		>
          03. Material
        </v-expansion-panel-header>
        <v-expansion-panel-content>
			<v-expand-transition mode="in-out">
        <material-issue @saveDone="expansionClose" @userChange="expansionOpen" />
			</v-expand-transition>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel @change="changePanel" :disabled="disabled">
        <v-expansion-panel-header
		class="font-weight-black overline"
		expand-icon="mdi-account-plus-outline"
		disable-icon-rotate color="#E3F2FD"
		>
          04. Resource
        </v-expansion-panel-header>
        <v-expansion-panel-content>
			<v-expand-transition mode="in-out">
          <resource-issue @saveDone="expansionClose" @userChange="expansionOpen"  />
			</v-expand-transition>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel @change="changePanel" :disabled="disabled">
        <v-expansion-panel-header
		class="font-weight-black overline"
		expand-icon="mdi-list-status"
		disable-icon-rotate color="#BBDEFB"
		>
          05. Status
        </v-expansion-panel-header>
        <v-expansion-panel-content>
			<v-expand-transition>
          <allocation-progress @saveDone="expansionClose" @userChange="expansionOpen"/>
			</v-expand-transition>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel @change="changePanel" :disabled="disabled">
        <v-expansion-panel-header
		class="font-weight-black overline"
		expand-icon="mdi-checkbox-multiple-marked"
		disable-icon-rotate color="#E3F2FD"
		>
          06. QC
        </v-expansion-panel-header>
        <v-expansion-panel-content>
			<v-expand-transition >
          <checklist @saveDone="expansionClose" @userChange="expansionOpen"/>
			</v-expand-transition>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
	<v-dialog
          v-model="confirmDialog"
          persistent
          max-width="470px"
          height="500px"
        >
          <v-card>
            <v-card-title>
              You have pending unsaved changes. Are you sure you want to continue?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                outlined
                @click.stop="cancelConfirm"
              >
                Cancel
              </v-btn>
              <v-btn color="success" @click.stop="okConfirm">
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';

import Workorders from '@/Services/workorders';
// import BomItems from './BomItems';
// import Comments from './comments';
import ViewAssignment from '@/components/Workorder/ViewAssignment';
import AllocateAssignment from '@/components/Workorder/AllocateAssignment';
import MaterialIssue from '@/components/Workorder/MaterialIssue2';
import ResourceIssue from '@/components/Workorder/ResourceIssue2';
import AllocationProgress from '@/components/Workorder/AllocationProgress2';
import Checklist from '@/components/Workorder/Checklist2';
// import User from "@/Services/user";

export default {
  name: 'workorder',
  props: {
    scheduleCode: String,
  },
  components: { ViewAssignment, AllocateAssignment, MaterialIssue, ResourceIssue , AllocationProgress, Checklist},
  data() {
    return {
		panel: [],
		readOnly: false,
		viewPanel: false,
		expansion: false,
		confirmDialog: false,
		olderPanel: 0,
		newPanel: 0,
		mandatory: false,
		change: false,
      snackbar: false,
      workorderId: null,
      workorderReference: null,
      workorderStartDate: null,
      workorderEndDate: null,
      scheduleStartDate: null,
      scheduleEndDate: null,
      itemQuantity: null,
      itemUnits: null,
      itemCode: null,
      itemName: null,
      productionStageName: null,
      prodStageCode: null,
      isAccepted: 1,
      acceptance: null,
      isClosed: 0,
      closed: null,
      productionStageColor: null,
      workorderStatusCode: null,
      workorderStatus: null,
      workorderStatusColor: null,
      productionStageProgress: 0,
      statusComment: '',
      scheduleComments: '',
      workflowName: '',
      resourceCode: '',
      bomItems: [],
      bomHistoryItems: [],
      displayStatus: false,
      accept: 1,
      reject: 0,
      saveInProgress: false,
      customerName: '',
	  materialTotal: null,
	  labourTotal: null,
	  allocation: [],
	  disabled: false
    };
  },
  created() {
	this.$store.commit('showProgressCircular', 500)
	setTimeout(() => {
    	this.$store.dispatch('showMyAssignment', true);
	}, 0);
    this.getSchedule();
	this.getResourceAllocations()
  },
  beforeDestroy() {
	  this.$store.dispatch('showMyAssignment', false);
  },
  methods: {
    async saveMyAssignment() {
      try {
        this.saveInProgress = true;
        const response = await Workorders.saveMyAssignment({
          workorderId: this.workorderId,
          productionStageId: this.prodStageCode,
          isAccepted: this.isAccepted,
          statusComment: this.statusComment,
          resourceCode: this.resourceCode,
          productionStageProgress: this.productionStageProgress,
          scheduleId: this.getScheduleCode,
        });
        console.log(response);
        if (response.data.header.success) {
			this.$store.commit('showProgressCircular', 2000)
			setTimeout(() => {
				this.displayStatus = true;
      		}, 2000)
        }
      } catch (error) {
        console.log(error);
      }
      this.saveInProgress = false;
    },
    async getSchedule() {
      const response = await Workorders.getSchedule(this.getScheduleCode);
      let workorder = response.data.data;
      if (workorder.length > 0) this.setupWorkorderDetails(workorder[0]);
    },
    setupWorkorderDetails(workorder) {
      console.log(workorder);
      this.workorderId = workorder.workorderId;
      this.workorderReference = workorder.workorderReference;
      this.prodStageCode = workorder.productionStageId;
      this.productionStageName = workorder.productionStageName;
      this.workorderStartDate = workorder.woScheduleStartDate;
      this.workorderEndDate = workorder.woScheduleStartDate;
      this.scheduleStartDate = workorder.scheduleStartDate;
      this.scheduleEndDate = workorder.scheduleEndDate;
      this.itemQuantity = workorder.woQuantity;
      this.itemUnits = workorder.itemUnits;
      this.itemCode = workorder.itemCode;
      this.itemName = workorder.itemDescription;
      this.isAccepted = workorder.isAccepted;
      this.acceptance = workorder.acceptance;
      this.isClosed = workorder.isClosed;
      this.closed = workorder.closed;
      this.productionStageColor = workorder.productionStageColor;
      this.workorderStatusCode = workorder.workorderStatusCode;
      this.workorderStatus = workorder.workorderStatus;
      this.workorderStatusColor = workorder.workorderStatusColor;
      this.workflowName = workorder.workflowName;
      this.productionStageProgress = workorder.productionStageProgress;
      this.resourceCode = workorder.resourceCode;
      this.statusComment = workorder.statusComment;
      this.scheduleComments = workorder.scheduleComments;
      this.customerName = workorder.custName;
      this.bomItems = workorder.bomItems;
      this.bomHistoryItems = workorder.bomHistoryItems;
      this.materialTotal = workorder.materialCost;
      this.labourTotal = workorder.resourceCost;
	  if(this.acceptance === 'Pending' || this.acceptance === 'Rejected') {
		this.disabled = true
	  } else if(this.acceptance === 'Accepted') {
		  this.disabled = false
	  }
    },
    scheduleWorkorder() {
      this.$router.push({
        name: 'scheduleworkorder',
        params: { workorderId: this.workorderId },
      });
    },
	async getResourceAllocations() {
      //   this.scheduleCode = 118;
      const response = await Workorders.getResourceAllocations(
        this.getScheduleCode
      );
      let workorder = response.data.data;
	  this.allocation = workorder.allocation
    //   this.setupWorkorderDetails(workorder);
    },
	cancelConfirm() {
		this.confirmDialog = false
		this.expansion = true
		this.panel = this.olderPanel
	},
	okConfirm() {
		this.confirmDialog = false
		this.change = false
	},
	expansionClose() {
		this.expansion = false
		this.change = false
		this.getSchedule()
		if(this.acceptance === 'Pending' || this.acceptance === 'Rejected') {
		this.disabled = true
	  } else if(this.acceptance === 'Accepted') {
		  this.disabled = false
	  }
	},
	expansionOpen() {
		this.change = true
		this.expansion = true
	},
	changePanel() {
		// this.mandatory = true
		if(this.expansion === false) {
			this.expansion = true
		} else if(this.expansion === true && this.change === true) {
			this.confirmDialog = true
		} 
	},
  },
  computed: {
    // ...mapGetters(['dashboard', 'userId', 'userImage', 'name', 'fullName']),
    getCaption() {
      return `My Assignment: ${this.productionStageName} `;
    },
    getButtonCaption() {
      return this.isAccepted == this.accept
        ? `Accept Assignment`
        : 'Reject Assignment';
    },
    getButtonIcon() {
      return this.isAccepted == this.accept ? `mdi-thumb-up` : 'mdi-thumb-down';
    },
	getScheduleCode() {
		return localStorage.getItem('scheduleCode');
	},
	viewStatus() {
		if(this.acceptance === 'Accepted') return '#00E676'
		else if (this.acceptance === 'Rejected') return '#E53935'
		else return '#FFEE58'
	},
	allocationStatus() {
      return this.allocation.length > 0 ? 'green' : 'yellow';
    },
  },
  watch: {
	  panel(value, old) {
		  this.olderPanel =  null
		  this.olderPanel = old
		  this.newPanel =  null
		  this.newPanel = value
	  }
  },
};
</script>

<style scoped>
/* Popup container - can be anything you want */
span[data-descr] {
  position: relative;
}

span[data-descr]:hover::after,
span[data-descr]:focus::after {
  content: attr(data-descr);
  position: absolute;
  left: 0;
  top: 24px;
  min-width: 200px;
  border: 1px #aaaaaa solid;
  border-radius: 10px;
  background-color: #0644ec;
  padding: 12px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  z-index: 1;
}
.hr_line {
  border-top: 2px solid saddlebrown;
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 0px;
}

.v-card__title {
	word-break: break-word;
	white-space:nowwrap;
	line-height: 1.4;
}

.v-enter-active{
	transition: all 0.10s ease-out;
}

.animate{
	animation: slide-fade 0.3s ease-out forwards;
}

@keyframes modal {
	0% {
		transform: translateY(0);
	}
	70% {
		transform: translateY(-120px);
	}
	100% {
		transform: translateY(-200px);
	}
	
}

.modal-enter-active {
  transition: all ease-out 10s;
}
.modal-leave-active {
  transition: all ease-in 10s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: translateX(100vw);
}

/* .modal-enter-active {
	animation: modal 0.15s ease-out forwards;
}
.modal-leave-active {
	animation: modal 0.25s ease-in backwards;
} */
</style>
