<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      :style="{ background: $vuetify.theme.themes.light.drawerbackgrund }"
      v-if="loggedIn"
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 primary--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title color="amber darken4">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.path" link :to="item.path">
            <v-list-item-action>
              <v-icon color="secondary">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="pagetitle--text font-weight-bold">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      dark
      dense
      :style="{ background: $vuetify.theme.themes.light.menubackground }"
      :elevate-on-scroll="elevate"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="loggedIn"
        class="white primary--text"
      ></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <!-- <span class="hidden-sm-and-down">Teal HRMS</span> -->
        <v-img
          height="50"
          width="200"
          src="@/assets/berryerp.png"
          alt="berryERP"
          contain
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <AttendanceEntry v-if="loggedIn" /> -->
      <v-btn icon v-if="loggedIn" v-show="false">
        <v-icon color="white">mdi-apps</v-icon>
      </v-btn>
      <v-btn icon v-if="loggedIn" v-show="false">
        <v-badge color="green" :content="messageContent" bottom>
          <v-icon color="white">mdi-bell</v-icon>
        </v-badge>
      </v-btn>
      <v-btn
        depressed
        fab
        x-small
        class="mx-2 drawerbackgrund"
        v-if="showWorkorders"
        to="dashboard"
      >
        <v-icon color="white">mdi-ballot-outline</v-icon>
      </v-btn>
      <v-btn
        depressed
        fab
        x-small
        class="mx-2 drawerbackgrund"
        v-if="showMyAssignment"
        to="myassignments"
      >
        <v-icon color="white">mdi-clipboard-account-outline</v-icon>
      </v-btn>
      <v-btn
        rounded
        route
        to="Login"
        v-if="!loggedIn"
        color="white"
        class="primary--text "
      >
        Login
      </v-btn>
      <v-menu
        :offset-y="offset"
        v-if="loggedIn"
        :open-on-hover="openOnHover"
        class="ml-2"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar size="32" v-bind="attrs" v-on="on" color="drawerbackgrund">
            <v-img alt="tealHRMS" :src="userImage" v-if="userImage" />
            <span class="menubackground--text caption" v-else>
              {{ userShortName }}
            </span>
          </v-avatar>
        </template>

        <v-list dense>
          <v-list-item dense route to="/login" @click="logout">
            <v-list-item-title class="pagetitle--text">
              <v-icon left small class="white drawerbackgrund--text">
                mdi-logout
              </v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
          <v-list-item dense route to="/changepassword">
            <v-list-item-title class="pagetitle--text">
              <v-icon left small class="white drawerbackgrund--text">
                mdi-lock
              </v-icon>
              Change Password
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <hr />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import AttendanceEntry from '@/components/Attendance/AttendanceEntry';
export default {
  props: {
    source: String,
  },
  components: {
    // AttendanceEntry,
  },
  created() {
    this.setupMenu();
  },
  data: () => ({
    messageContent: null,
    dialog: false,
    drawer: null,
    offset: true,
    elevate: true,
    openOnHover: true,
    items: [],
  }),
  computed: {
    ...mapGetters([
      'loggedIn',
      'dashboard',
      'userImage',
      'fullName',
      'isSupervisor',
      'showMyAssignment',
      'showWorkorders',
    ]),
    userShortName() {
      return this.fullName.substring(0, 2).toUpperCase();
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    setupMenu() {
      this.items = [];
      switch (this.isSupervisor) {
        case 1:
          this.items.push({
            icon: 'mdi-clipboard-account-outline',
            'icon-alt': 'MyAssignments',
            text: 'My Assignments',
            model: true,
            path: 'myassignments',
          });
          this.items.push({
            icon: 'mdi-clipboard-account-outline',
            'icon-alt': 'MyAssignments',
            text: 'Assignments',
            model: true,
            path: 'assignments',
          });
          break;

        default:
          this.items.push({
            icon: 'mdi-ballot-outline',
            'icon-alt': 'Application',
            text: 'Workorders',
            model: true,
            path: 'dashboard',
          });
        //   this.items.push({
        //     icon: 'mdi-ballot-outline',
        //     'icon-alt': 'Application',
        //     text: 'My Workorders',
        //     model: true,
        //     path: 'dashboards',
        //   });
          this.items.push({
            icon: 'mdi-ballot-outline',
            'icon-alt': 'Application',
            text: 'Outstanding Workorders',
            model: true,
            path: 'outstanding-workorders',
          });
          break;
      }
    },
  },
  watch: {
    isSupervisor: function() {
      this.setupMenu();
    },
  },
};
</script>
