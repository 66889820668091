<template>
  <v-container fluid>
    <v-row align="center" no-gutters>
      <v-col cols="12">
        <v-card class="mt-1" flat color="background">
          <div class="primary--text">
            <!-- <v-img height="200" width="400" src="@/assets/hrms.jpeg"></v-img> -->
            <!-- <v-card-title class="text-center"> -->
            <h1 class="text-h2  text-center pt-3 pb-1">
              Welcome to berryERP Workspace
            </h1>
            <!-- <h1 class="text-h2 text-center pa-1">to the future</h1>
            <h1 class="text-h2 text-center pa-1">Of workspace</h1> -->
            <!-- </v-card-title> -->
          </div>
          <br /><br />
          <v-card-text class="text-center black--text caption text-h5 ">
            <p>
              <span class="teal--text">berryERP</span> ~ a highly integrated
            </p>
            <p>Enterprise Resource Management System</p>
            <br />
            <br />
            <v-row no-gutters class="mt-5" align="center">
              <v-col cols="12" class="overline font-weigh-bold black--text">
                <div>Powered by</div>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" no-gutters>
              <v-col cols="2">
                <v-img
                  height="50"
                  width="200"
                  src="@/assets/company.png"
                  alt="berryERP"
                  contain
                  class="mb-0 pb-0"
                ></v-img>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="caption">
              <v-col cols="6">
                <div>&copy; Turqosoft Solutions Private Limited</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {},
};
</script>
<style scoped>
.home {
  background-color: #e6ecf5;
}
.home_bkg {
  background-color: #f8f8f8;
  background-image: url(http://18.141.191.145/wp-content/uploads/2019/03/mitech-slider-cybersecurity-global-image.png);
  background-repeat: no-repeat;
  background-position: center right 40px;
}
</style>
