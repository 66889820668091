<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
	:max-height="$vuetify.breakpoint.xs ? 380 : 500"
    offset-x
	:nudge-left="$vuetify.breakpoint.xs ? 100 : 300"
	:nudge-right="$vuetify.breakpoint.xs ? 100 : 0"
	bottom
	origin="right-top"
	transition="fab-transition"
	:max-width="$vuetify.breakpoint.xs ? '250' : '500'"
  >
    <template #activator="{ on: menu, attrs }">
	<v-tooltip bottom transition="scroll-y-transition" color="info" close-delay="0" :open-on-click="false" >
        <template #activator="{ on: tooltip }">
	<v-progress-linear
            :value="progress"
            height="20"
            color="lime darken-4"
            background-color="lime darken-2"
            rounded
			v-bind="attrs"
        v-on="{...tooltip, ...menu }"
		class="project-progress mt-1"
          >
            <strong class="caption white--text"> {{ progress }}% </strong>
          </v-progress-linear>
		  </template>
		   <span>Click here to see progress details</span>
      </v-tooltip>
		</template>
		<v-card>
			<v-card-text>
				<v-data-table
			dense
        :headers="headers"
        :items="progressdetails"
        item-key="schedule"
		calculate-widths
		no-data-text="No Data Avaialable"
		loading-text="Please wait as we load data...!!!"
		class="elevation-2"
		hide-default-footer
		:item-class="rowClass"
      >
	   <template #[`item.prodProgress`]="{item}" >
		<v-progress-linear
            :value="item.prodProgress"
            height="15"
            color="lime darken-4"
            background-color="lime darken-2"
            rounded
			class="project-progress mt-1"
          >
            <strong class="caption white--text"> {{ item.prodProgress }}% </strong>
          </v-progress-linear>

    </template>
	  </v-data-table>
			</v-card-text>
		</v-card>
</v-menu>
</template>
<script>
import Workorders from '@/Services/workorders';
export default {
  name: 'product',
  props: {
    progress: Number,
    productCode: Number,
  },
  data() {
    return {
      productImage: '',
	  menu: false,
	  progressdetails: [],
	  headers: [
		{ text: 'Schedule', value: 'schedule', align: 'start', divider: true, width: '12%', class: 'header' },
		{ text: 'Production Stage', value: 'prodStageName', align: 'center', divider: true, width: '25%', class: 'header' },
		{ text: 'Progress', value: 'prodProgress', align: 'center', divider: true, width: '9%', class: 'header' },
	  ],
    };
  },
  mounted() {
    this.getProgressDetails();
  },
  methods: {
	async getProgressDetails() {
      const response = await Workorders.getProgressDetails(
        this.productCode,
      );
      this.progressdetails = response.data.data;
    },
	itemRowBackground(item) {
     	return item.stageStatusColor
  	},
	rowClass() {
        return 'row-2'
    }
  }
};
</script>
<style lang="scss" scoped >
.row-1 {
  background-color: #BBDEFB
}
.row-2 {
  background-color: #E3F2FD
}
.header {
  background-color: #38db45
}
.theme--light.v-data-table.v-data-table--fixed-header thead th{
	background-color: #C5CAE9
}
.myclass {
  background-color: #E3F2FD;
}
.project-progress {
  min-width: 4rem;
}
</style>
