t<template>
  <div>
    <v-snackbar top v-model="displayStatus" :timeout="5000" color="success">
      <span>Workorder schedule saved successfully</span>
      <v-btn class="mx-2 pl-2 " icon @click="displayStatus = false">
        <v-icon small>
          mdi-close
        </v-icon></v-btn
      >
    </v-snackbar>
    <v-card outlined flat dense class="mb-3">
      <v-card-title
        color="blue"
        class="primary--text caption font-weight-bold  mx-2 mb-1 pb-0"
      >
        <v-icon left small class="white primary--text">
          mdi-ballot-outline
        </v-icon>
        Workorder Details
      </v-card-title>
      <hr class="hr_line my-4" />
      <v-row class="d-flex pb-0 mx-4 mb-1 mt-3" dense>
        <v-col xs="12" sm="3" md="2" lg="2">
          <v-card flat class="pa-1 ml-3 ">
            <div class="caption grey--text">Workorder Ref</div>
            <div class="caption font-weight-bold primary--text pb-2">
              {{ workorderRef }}
            </div>
          </v-card>
        </v-col>
        <v-col xs="12" sm="4" md="3" lg="3">
          <v-card flat class="pa-1 ml-3 ">
            <div class="caption grey--text">Product name</div>
            <div class="caption font-weight-bold primary--text pb-2">
              {{ itemDescription }}
            </div>
          </v-card>
        </v-col>
        <v-col xs="12" sm="4" md="2" lg="2">
          <v-card flat class="pa-1 ml-3 ">
            <div class="caption grey--text">Progress %</div>
            <v-progress-linear
              :value="woProgress"
              height="20"
              color="lime darken-4"
              background-color="lime darken-2"
              rounded
            >
              <strong class="caption white--text"> {{ woProgress }}% </strong>
            </v-progress-linear>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense class="pb-2 mx-4 mb-0 ">
        <v-col xs="6" sm="3" md="2" lg="2" class="ml-2">
          <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
            <div class="caption grey--text">
              Workorder ID:
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="yellow accent-4"
                    v-bind="attrs"
                    v-on="on"
                    v-if="workorderComments"
                  >
                    mdi-android-messages
                  </v-icon>
                </template>
                <span class="caption">{{ workorderComments }}</span>
              </v-tooltip>
            </div>
            <div class="caption primary--text pb-2">
              {{ getWorkorderId }}
            </div>
            <div class="caption grey--text">Workorder Date:</div>
            <div class="caption primary--text pb-2">
              {{ woDate | formatToLocalDate }}
            </div>
            <div class="caption grey--text">Required Date:</div>
            <div class="caption primary--text pb-2">
              {{ woRequiredDate | formatToLocalDate }}
            </div>
            <div class="caption grey--text">Quanity:</div>
            <div class="caption primary--text pb-2">
              {{ woQuantity }} {{ units }}
            </div>
          </v-card>
        </v-col>
        <v-col xs="6" sm="4" md="3" lg="3">
          <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
            <div class="caption grey--text">Stock Location:</div>
            <div class="caption primary--text pb-2">
              {{ locationName }}
            </div>
            <div class="caption grey--text">Workflow:</div>
            <div class="caption primary--text pb-2">
              {{ workflowName }}
            </div>
            <div class="caption grey--text ">Workorder Type:</div>
            <div class="caption primary--text pb-2">
              {{ workorderType }}
            </div>
            <div class="caption grey--text">Workorder Status:</div>
            <div class="caption primary--text pb-2">
              {{ workorderStatus }}
            </div>
          </v-card>
        </v-col>
        <v-col xs="6" sm="4" md="3" lg="3">
          <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
            <div class="caption grey--text">Customer Name:</div>
            <div class="caption primary--text pb-2">
              {{ customerName }}
            </div>

            <div class="caption grey--text">
              Sales Ref:
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="yellow accent-4"
                    v-bind="attrs"
                    v-on="on"
                    v-if="salesComments"
                  >
                    mdi-android-messages
                  </v-icon>
                </template>
                <span class="caption">{{ salesComments }}</span>
              </v-tooltip>
            </div>
            <div class="caption primary--text pb-2">
              {{ salesReference }}
            </div>
            <div class="caption grey--text">Salesman:</div>
            <div class="caption primary--text pb-2">
              {{ salesMan }}
            </div>
            <div class="caption grey--text">Schedule Status:</div>
            <div class="caption primary--text pb-2">
              {{ scheduleStatus }}
            </div>
          </v-card>
        </v-col>
        <v-col xs="6" sm="auto" md="auto" lg="3" class="justify-center">
          <v-card flat class="pa-2 rounded-lg">
            <template v-if="itemCode">
              <product
                :name="itemDescription"
                :productCode="itemCode"
              ></product>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- <Schedule :workorderId="workorderId"> </Schedule> -->
    <v-card flat dense outlined>
      <v-card-title
        color="blue"
        class="primary--text caption font-weight-bold  mx-2 mb-3 pb-2"
      >
        <v-icon left small class="white primary--text">
          mdi-calendar-clock
        </v-icon>
        Schedule Details
      </v-card-title>
      <v-row dense class="pb-0 mx-2 mb-0 pr-6">
        <v-col xs="12" sm="12" md="8" lg="8" class="pt-0 ma-2 d-flex ">
          <v-row dense class="ma-0">
            <v-col xs="8" sm="10" md="8" lg="8" class="ml-0 pl-0">
              <v-select
                :items="workflows"
                item-text="name"
                item-value="id"
                dense
                outlined
                label="Workflow"
                v-model="workflowMasterId"
                class="caption mb-4"
                hide-details="false"
                @change="isWorkflowChanged"
                :disabled="disableWorkflowModification()"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 justify-end">
            <v-col xs="6" sm="5" md="4" lg="4" class="mr-1">
              <div class="mb-4">
                <WorkorderDate
                  :woDate="workorderStartDate"
                  label="Start Date"
                  v-on:dateChange="updateStartDate($event)"
                  class="caption"
                  :disabled="disableStartDateModification()"
                />
              </div>
            </v-col>
            <v-col xs="6" sm="5" md="4" lg="4">
              <div class="mb-4">
                <WorkorderDate
                  :woDate="workorderEndDate"
                  label="End Date"
                  v-on:dateChange="updateEndDate($event)"
                  class="caption mb-2"
                  :disabled="disableEndDateModification()"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense class="pb-0 mx-2 mb-0 mt-n12 pr-4">
        <v-col xs="12" sm="12" md="8" lg="8" class="pt-0 ma-2">
          <table class="product_table" id="schedule_table">
            <thead class="table_head caption">
              <tr>
                <th>Production Stage</th>
                <th>Supervisior</th>
                <th>Contribution</th>
                <th class="table-number">Start Date</th>
                <th class="table-number">End Date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :id="RowIndex"
                v-for="(schedule, RowIndex) in workorderSchedules"
                :key="RowIndex"
                class="test pb-n4"
              >
                <td class="stagename caption">
                  {{ schedule.productionStageName }}
                </td>
                <td class="supervisior text-center">
                  <v-select
                    :items="workManagers"
                    item-text="resourceName"
                    item-value="resourceId"
                    v-model="schedule.resourceCode"
                    class="caption"
                    dense
                    outlined
                    hide-details="false"
                    :id="getRef('workManagers', RowIndex)"
                    @change="clearErrorMessage"
                  ></v-select>
                </td>
                <td class="contribution text-center">
                  <v-text-field
                    v-model.number="schedule.contribution"
                    :id="getRef('contribution', RowIndex)"
                    suffix="%"
                    dense
                    outlined
                    hide-details="false"
					type="number"
					:hide-spin-buttons="true"
                  ></v-text-field>
                </td>
                <td class="scheduleDate">
                  <WorkorderDate
                    :woDate="schedule.scheduleStartDate"
                    v-on:dateChange="updateScheduleStartDate($event, RowIndex)"
                    class="caption"
                    :id="getRef('scheduleStartDate', RowIndex)"
                    :disabled="
                      disableScheduleStartDateModification(
                        schedule.hasAllocations
                      )
                    "
                  />
                </td>
                <td class="scheduleDate">
                  <WorkorderDate
                    :woDate="schedule.scheduleEndDate"
                    v-on:dateChange="updateScheduleEndDate($event, RowIndex)"
                    class="caption pt-4"
                    :id="getRef('scheduleEndDate', RowIndex)"
                    :disabled="disableEndDateModification()"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
        <!-- <v-col xs="12" sm="12" md="4" lg="4">
          <WorkorderCalendar v-show="true"
        /></v-col> -->
      </v-row>
      <v-row dense class="pb-0 ml-2 mr-5 mb-0 d-flex mt-n8">
        <v-col xs="12" sm="12" md="8" lg="8" class="ma-2">
          <v-textarea
            name="input-7-4"
            label="Comments"
            rows="2"
            v-model="comments"
            class="caption"
            :id="comments"
            outlined
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row dense class="pb-0 mx-4 mb-0 d-flex mt-n5 pb-2">
        <v-col xs="12" sm="12" md="8" lg="8" class="">
          <template v-if="errorMessage.length > 1">
            <v-alert dense text type="error" class="caption">
              {{ errorMessage }}
            </v-alert>
          </template>
        </v-col>
      </v-row>
      <v-row dense class="pb-0 mx-4 mb-0 d-flex mt-n5 pb-2">
        <v-col xs="12" sm="12" md="8" lg="8" class="ma-0 pb-2">
          <v-btn
            small
            depressed
            color="yellow accent-2"
            class="primary--text"
            @click="saveWorkorder"
            :disabled="saveInProgress"
          >
            <v-icon left color="primary">
              mdi-content-save
            </v-icon>
            Save Schedule
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-card dense flat outlined class="mt-4">
      <WorkorderCalendar :workManagers="workManagers" v-show="true" />
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import WorkorderDate from './WorkorderDate';
import product from './Product';
// import Schedule from './Schedule';
import Workorders from '@/Services/workorders';
import WorkorderCalendar from './WorkorderCalendar';
import { StatusCodes } from 'http-status-codes';
export default {
  name: 'WorkorderSchedule',
  props: {
    workorderId: Number,
  },
  components: { product, WorkorderDate, WorkorderCalendar },
  data() {
    return {
      displayStatus: false,
      multiLine: true,
      snackbar: false,
      absolutepo: true,
      workorder: {},
      workorderRef: null,
      locationCode: null,
      locationName: null,
      woQuantity: null,
      units: null,
      woProducedQuantity: null,
      itemCode: null,
      itemDescription: null,
      woDate: null,
      woRequiredDate: null,
      woReleasedDate: null,
      workflowMasterId: null,
      workflowName: null,
      workorderComments: null,
      workorderType: null,
      workorderStatus: null,
      workType: null,
      salesReference: null,
      customerName: null,
      salesMan: null,
      salesComments: null,
      scheduleStatus: null,
      workorderStartDate: moment().format('YYYY-MM-DD'),
      workorderEndDate: moment().format('YYYY-MM-DD'),
      workflows: [],
      workManagers: [],
      workorderSchedules: [],
      prevWorkflowMasterId: null,
      workmanagerRules: [(value) => !!value || 'Select supervisior'],
      errorMessage: '',
      scheduleStartDate: null,
      scheduleEndDate: null,
      contribution: null,
      comments: '',
      woProgress: 0,
      hasAllocations: false,
      saveInProgress: false,
	  workflow: []
    };
  },
  async created() {
	this.$store.commit('showProgressCircular', 500)
	setTimeout(() => {
    	this.$store.dispatch('showWorkorders', true);
	}, 0);
	this.workorder = await this.getWorkorderSchedule();
    this.setupWorkorderDetails(this.workorder);
    this.setupScheduleDetails(this.workorder.schedule_detail);
    this.getWorkFlowTypes();
    this.getWorkManagers();
    if (this.scheduleStatus == 'PENDING SCHEDULE') {
      this.defaultContribution();
    }
  },
  methods: {
    async getWorkManagers() {
      const response = await Workorders.getWorkManagers();
      this.workManagers = response.data.data;
    },
    async getWorkorderSchedule() {
      this.workorder = {};
      this.workorderSchedules = [];
      const response = await Workorders.getWorkorderSchedule(
        this.getWorkorderId,
        this.workflowMasterId
      );
      return response.data.data;
    },
    async getWorkFlowTypes() {
      const response = await Workorders.getWorkFlowTypes();
      this.workflows = response.data.data;
    },

    async isWorkflowChanged() {
      if (!this.prevWorkflowMasterId) return;
      if (this.prevWorkflowMasterId != this.workflowMasterId) {
        this.workorder = await this.getWorkorderSchedule();
        this.setupScheduleDetails(this.workorder.schedule_detail);
        this.prevWorkflowMasterId = this.workflowMasterId;
        this.defaultContribution();
      }
	  this.clearErrorMessage();
    },
    setupWorkorderDetails(workorder = {}) {
      this.workorderRef = workorder.workorderRef;
      this.locationCode = workorder.locationCode;
      this.locationName = workorder.locationName;
      this.woQuantity = workorder.woQuantity;
      this.units = workorder.units;
      this.woProducedQuantity = workorder.woProducedQuantity;
      this.itemCode = workorder.itemCode;
      this.itemDescription = workorder.itemDescription;
      this.workflowMasterId = workorder.workflowMasterId;
      this.workflowName = workorder.workflowName;
      this.woDate = workorder.woDate;
      this.woRequiredDate = workorder.woRequiredDate;
      this.woReleasedDate = workorder.woReleasedDate;
      this.workorderComments = workorder.workorderComments;
      this.workorderType = workorder.workorderType;
      this.workorderStatus = workorder.workorderStatus;
      this.workType = workorder.workType;
      this.salesReference = workorder.salesReference;
      this.customerName = workorder.customerName;
      this.salesMan = workorder.salesMan;
      this.salesComments = workorder.salesComment;
      this.scheduleStatus = workorder.scheduleStatus;
      this.prevWorkflowMasterId = workorder.workflowMasterId;
      this.workorderStartDate = workorder.woScheduleStartDate
        ? workorder.woScheduleStartDate
        : this.workorderStartDate;
      this.workorderEndDate = workorder.woScheduleEndDate
        ? workorder.woScheduleEndDate
        : this.workorderEndDate;
      this.comments = workorder.woScheduleComments;
      this.woProgress = workorder.workorderProgress;
      this.hasAllocations = workorder.woHasAllocations;
    },
    setupScheduleDetails(scheduleDetail = []) {
      scheduleDetail.forEach((schedule) => {
        this.workorderSchedules.push({
          workflowId: schedule.workflowId,
          productionStageId: schedule.productionStageId,
          productionStageName: schedule.productionStageName,
          sequence: schedule.sequence,
          contribution: schedule.contribution,
          resourceCode: schedule.resourceCode,
          resourceName: schedule.resourceName,
          resourceStandardCost: schedule.resourceStandardCost,
          resourceType: schedule.resourceType,
          scheduleStartDate: schedule.scheduleStartDate
            ? schedule.scheduleStartDate
            : moment().format('YYYY-MM-DD'),
          scheduleEndDate: schedule.scheduleEndDate
            ? schedule.scheduleEndDate
            : moment().format('YYYY-MM-DD'),
          isScheduleAccepted: schedule.isScheduleAccepted,
          isScheduleClosed: schedule.isScheduleClosed,
          scheduledStartDate: schedule.scheduleStartDate
            ? schedule.scheduleStartDate
            : moment().format('YYYY-MM-DD'),
          scheduledEndDate: schedule.scheduleEndDate
            ? schedule.scheduleEndDate
            : moment().format('YYYY-MM-DD'),
          hasAllocations: schedule.hasAllocations,
          scheduleId: schedule.woScheduleId,
        });
      });
    },
    async defaultContribution() {
		const response = await Workorders.getWorkflows(this.workflowMasterId);
		this.workflow = response.data.data
		this.workorderSchedules.forEach(workorderSchedule =>{
			  const workflowItems = this.workflow.find(work => work.prod_stage_id === workorderSchedule.productionStageId)
			  workorderSchedule.contribution = workflowItems.contribution
		});
    },
    async saveWorkorder() {
      try {
        if (!this.validateSchedules()) return;
        this.saveInProgress = true;
        let woSchedule = {
          workorderId: this.getWorkorderId,
          workflowMasterId: this.workflowMasterId,
          woScheduleStartDate: moment(this.workorderStartDate).format(
            'YYYY-MM-DD'
          ),
          woScheduleEndDate: moment(this.workorderEndDate).format('YYYY-MM-DD'),
          woScheduleComments: this.comments,
          schedule: [],
        };
        this.workorderSchedules.forEach((workorderSchedule) => {
          woSchedule.schedule.push({
            woScheduleId: workorderSchedule.scheduleId,
            prodStage: workorderSchedule.productionStageId,
            resourceCode: workorderSchedule.resourceCode,
            contribution: workorderSchedule.contribution,
            scheduleStartDate: workorderSchedule.scheduledStartDate,
            scheduleEndDate: workorderSchedule.scheduledEndDate,
          });
        });
        const response = await Workorders.saveWorkorderSchedule(woSchedule);
        if (response.data.header.success) {
			this.$store.commit('showProgressCircular', 2000)
			setTimeout(() => {
				this.displayStatus = true;
      		}, 2000)
        }
		this.workorder = await this.getWorkorderSchedule();
		this.setupWorkorderDetails(this.workorder);
		this.setupScheduleDetails(this.workorder.schedule_detail);
      } catch (error) {
        if (error.response.status == StatusCodes.PRECONDITION_FAILED) {
          this.errorMessage = error.response.data.header.message;
        }
      }
      this.saveInProgress = false;
    },
    getRef(fieldname, rowIndex) {
      return `${fieldname}-${rowIndex}`;
    },
    updateScheduleStartDate(updatedDate, RowIndex) {
      this.errorMessage = '';
      this.workorderSchedules[RowIndex].scheduledStartDate = updatedDate;
    },
    updateScheduleEndDate(updatedDate, RowIndex) {
      this.errorMessage = '';
      this.workorderSchedules[RowIndex].scheduledEndDate = updatedDate;
    },
    updateStartDate(updatedDate) {
      this.errorMessage = '';
      this.workorderStartDate = updatedDate;
    },
    updateEndDate(updatedDate) {
      this.errorMessage = '';
      this.workorderEndDate = updatedDate;
    },
    validateSchedules() {
      this.clearErrorMessage();

      for (let index = 0; index < this.workorderSchedules.length; index++) {
        const schedule = this.workorderSchedules[index];
		const totalContribution = this.workorderSchedules.map(item => item.contribution).reduce((prev, curr) => {return prev + curr });

        if (!schedule.resourceCode) {
          let fieldname = this.getRef('workManagers', index);
          this.errorMessage = `Select supervisior for ${schedule.productionStageName}`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (!schedule.scheduledStartDate) {
          let fieldname = this.getRef('scheduleStartDate', index);
          this.errorMessage = `Select schedule start date for ${schedule.productionStageName}`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (schedule.scheduledStartDate < this.workorderStartDate) {
          let fieldname = this.getRef('scheduleStartDate', index);
          this.errorMessage = `Schedule start date for ${schedule.productionStageName} can not be less than workorder start date`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (schedule.scheduledStartDate > this.workorderEndDate) {
          let fieldname = this.getRef('scheduleStartDate', index);
          this.errorMessage = `Schedule start date for ${schedule.productionStageName} can not be greater than workorder end date`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (!schedule.scheduledEndDate) {
          let fieldname = this.getRef('scheduleEndDate', index);
          this.errorMessage = `Select schedule end date for ${schedule.productionStageName}`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (schedule.scheduledEndDate < this.workorderStartDate) {
          let fieldname = this.getRef('scheduleStartDate', index);
          this.errorMessage = `Schedule end date for ${schedule.productionStageName} can not be less than workorder start date`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (schedule.scheduledEndDate > this.workorderEndDate) {
          let fieldname = this.getRef('scheduleStartDate', index);
          this.errorMessage = `Schedule end date for ${schedule.productionStageName} can not be greater than workorder end date`;
          document.getElementById(fieldname).focus();
          return false;
        }

		if (totalContribution > 100) {
			this.errorMessage = `The total contribution is greater than 100`;
			return false;
        } else if(totalContribution < 100) {
			this.errorMessage = `The total contribution is less than 100`;
			return false;
		}

        // if (schedule.scheduledEndDate > nextSchedule.scheduledStartDate) {
        // 	this.errorMessage = `Schedule start date for a ${schedule.productionStageName} cannot be less than schedule end date of ${nextSchedule.productionStageName}`;
        // 	return false;
        // }
      }
      return true;
    },
    clearErrorMessage() {
      this.errorMessage = '';
    },
    disableWorkflowModification() {
      if (this.hasAllocations) return true;
      return false;
    },
    disableStartDateModification() {
      if (this.hasAllocations == true) return true;
      return false;
    },
    disableEndDateModification() {
      if (this.hasAllocations == true && this.woProgress == 100) return true;
      return false;
    },
    disableScheduleStartDateModification(hasAllocations) {
      if (hasAllocations == true) return true;
      return false;
    },
  },
  computed: {
    ProductCode() {
      return this.itemCode;
    },
	getWorkorderId() {
		return localStorage.getItem('workorderId');
	}
  },
  watch: {},
};
</script>

<style scoped>
/* Popup container - can be anything you want */
span[data-descr] {
  position: relative;
}

span[data-descr]:hover::after,
span[data-descr]:focus::after {
  content: attr(data-descr);
  position: absolute;
  left: 0;
  top: 24px;
  min-width: 200px;
  border: 1px #aaaaaa solid;
  border-radius: 10px;
  background-color: #0644ec;
  padding: 12px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  z-index: 1;
}

.product_table {
  min-width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  padding: 10px;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

.product_table thead tr {
  background-color: #005b86;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.product_table th,
.product_table td {
  padding: 8px 10px;
}

.product_table tbody tr {
  border-bottom: 0.5px solid #dddddd;
}

.product_table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
.product_table tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.product_table tbody tr:last-of-type {
  border-bottom: 1px solid #005b86;
}

.product_table tbody {
  border-left: 2px solid #005b86;
  border-right: 2px solid #005b86;
}

.table-number {
  text-align: center;
}
h4 {
  color: #009879;
}

.product_table tbody tr:hover {
  background-color: #f0f8ff;
}

.stagename {
  width: 140px;
}

.hr_line {
  border-top: 3px solid saddlebrown;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 10px;
}

.v-text-field__slot {
  width: 100px;
}

.contribution {
  width: 50px;
}

/* .inputContribution input[type='number'] {
    -moz-appearance:textfield;
}
.inputContribution input::-webkit-outer-spin-button,
.inputContribution input::-webkit-inner-spin-button {
    -webkit-appearance: none;
} */

</style>
