import Vue from 'vue';
import Vuex from 'vuex';
import user from './user';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    progressCircular: {
      show: false,
    },
  },
  mutations: {
    showProgressCircular(state, timeout) {
		// const timeout = 3000;
      state.progressCircular.show = true;
      setTimeout(() => {
        state.progressCircular.show = false;
      }, timeout);
    },
  },
  modules: {
    user,
  },
});
