import Service from './services';
class Workorders {
  getOutstandingWorkorders(pageNo = 1, numOfRecords = 10, dataFilters) {
    let apiService = Service.getApiService();
    return Object.keys(dataFilters).length > 0
      ? apiService.get(`/manufac/wooutstanding/${pageNo}/${numOfRecords}`, {
          params: {
            ...dataFilters,
          },
        })
      : apiService.get(`/manufac/wooutstanding/${pageNo}/${numOfRecords}`);
  }
  getProductImage(productId) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/woitemimage/${productId}`, {
      responseType: 'arraybuffer',
    });
  }

  getWorkorderSchedule(workorderId, wokflowMasterId) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/woschedule/${workorderId}`, {
      params: {
        wfmid: wokflowMasterId,
      },
    });
  }

  saveWorkorderSchedule(workorderSchedule) {
    let apiService = Service.getApiService();
    return apiService.post(`/manufac/savewoschedule`, workorderSchedule);
  }

  getWorkFlowTypes() {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/workflowtypes`);
  }

  getWorkManagers() {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/workmanagers`);
  }

  getSchedules(resourceCode, fromDate, toDate) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/workschedules`, {
      params: {
        resourceCode: resourceCode,
        fromDate: fromDate,
        toDate: toDate,
      },
    });
  }
  // , fromDate, toDate
  getResourceSchedules(resourceCode) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/resourceschedule/${resourceCode}`);
    //  , {
    //   params: {
    //     fromDate: fromDate,
    //     toDate: toDate,
    //   },
    // }
  }
  getUserSchedules(userCode, pageNo = 1, numOfRecords = 25, dataFilters) {
    let apiService = Service.getApiService();
	return Object.keys(dataFilters).length > 0
    ? apiService.get(
        `/manufac/userschedule/${userCode}/${pageNo}/${numOfRecords}`,
        {
          params: {
            ...dataFilters,
          },
        }
      )
    : apiService.get(
        `/manufac/userschedule/${userCode}/${pageNo}/${numOfRecords}`
      );
  }
  getSchedule(scheduleCode) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/viewschedule/${scheduleCode}`);
  }
  getMyAssignment(resourceCode, workorderId, productionStageId) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/myassignment/${resourceCode}`, {
      params: {
        workorderId: workorderId,
        productionStageId: productionStageId,
      },
    });
  }

  saveMyAssignment(myAssignment) {
    let apiService = Service.getApiService();
    return apiService.post(`/manufac/savemyassignment`, myAssignment);
  }

  getResourceAllocations(scheduleCode) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/resourceallocations/${scheduleCode}`);
  }

  getResources() {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/resources`);
  }
  saverRsourceAllocations(allocations) {
    let apiService = Service.getApiService();
    return apiService.post(`/manufac/resourceallocations`, allocations);
  }

  saveMaterialIssue(materialIssue) {
    let apiService = Service.getApiService();
    return apiService.post(`/manufac/materialissue`, materialIssue);
  }
  getLocations() {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/locations`);
  }

  getWorkcentres() {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/workcentres`);
  }

  getBom(itemCode, productionStageLocation) {
    let apiService = Service.getApiService();
    return apiService.get(
      `/manufac/bom/${itemCode}?locCode=${productionStageLocation}`
      //   {
      //     params: {
      //       prodStage: productionStageId,
      //     },
      //   }
    );
  }

  getBomHistory(itemCode, allocatedQuantity, productionStageLocation) {
    let apiService = Service.getApiService();
    return apiService.get(
      `/manufac/bomfromhistory/${itemCode}/${allocatedQuantity}?locCode=${productionStageLocation}`
      //   {
      //     params: {
      //       prodStage: productionStageId,
      //     },
      //   }
    );
  }

  getComponents(prodlocationCode) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/components/${prodlocationCode}`);
  }

  getMaterialIssueReturnHistory(
    workorderId,
    scheduleId,
    productionStageId,
    allocationId
  ) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/materialissue/${workorderId}`, {
      params: {
        woScheduleId: scheduleId,
        prodStage: productionStageId,
        allocationId: allocationId,
      },
    });
  }

  getAllocationProgress(scheduleId, allocationId) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/materialissue/${scheduleId}`, {
      params: {
        resourceAllocationId: allocationId,
      },
    });
  }

  getResourceAllocationProgress(scheduleId, allocationId) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/resourceallocationprogress/${scheduleId}`, {
      params: {
        resourceAllocationId: allocationId,
      },
    });
  }
  saveAllocationProgress(progressDetails) {
    let apiService = Service.getApiService();
    return apiService.post(
      `/manufac/scheduleAssignmentProgress`,
      progressDetails
    );
  }

  getQcChecklist(scheduleId, allocationId) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/qcchecklist/${scheduleId}/${allocationId}`);
  }

  saveQcChecklist(qcChecklistDetails) {
    let apiService = Service.getApiService();
    return apiService.post(`/manufac/qcchecklist`, qcChecklistDetails);
  }

  saveResourceUtilization(resourceUtilization) {
    let apiService = Service.getApiService();
    return apiService.post(`/manufac/resutilization`, resourceUtilization);
  }

  getResourceUtilization(scheduleCode) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/resutilization/${scheduleCode}`);
  }

  deleteResourceUtilization(resourceUtilization) {
    let apiService = Service.getApiService();
    return apiService.delete(`/manufac/resutilization`, {
      data: { ...resourceUtilization },
    });
  }

  saveFavouriteSchedule(scheduleDetails) {
    let apiService = Service.getApiService();
    return apiService.post(`/manufac/setScheduleFavourite`, scheduleDetails);
  }

  getWorkflows(workflowId) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/workflows?id=${workflowId}`);
  }
  getWOInquiry(pageNo = 1, numOfRecords = 10, dataFilters) {
    let apiService = Service.getApiService();
    return Object.keys(dataFilters).length > 0
      ? apiService.get(`/manufac/woinquiry/1/25`, {
          params: {
            ...dataFilters,
          },
        })
      : apiService.get(`/manufac/woinquiry/${pageNo}/${numOfRecords}`);
  }
  getProgressDetails(workorderId) {
    let apiService = Service.getApiService();
    return apiService.get(`/manufac/woprogress/${workorderId}`);
  }
}

export default new Workorders();
