<template>
  <v-card outlined dense>
    <v-row class="d-flex pb-0 mx-4 mb-0 mt-3" dense>
      <v-col xs="12" sm="3" md="2" lg="2">
        <v-card flat class="pa-1 ml-3 ">
          <div class="caption grey--text">Workorder Ref</div>
          <div class="caption font-weight-bold primary--text pb-2">
            {{ workorderRef }}
          </div>
        </v-card>
      </v-col>
      <v-col xs="12" sm="5" md="3" lg="3">
        <v-card flat class="pa-1 ml-3 ">
          <div class="caption grey--text">Product name</div>
          <div class="caption font-weight-bold primary--text pb-2">
            {{ itemDescription }}
          </div>
        </v-card>
      </v-col>
      <v-col xs="12" sm="3" md="2" lg="2">
        <v-card flat class="pa-1 ml-3 ">
          <div class="caption grey--text">Progress %</div>
          <v-progress-linear
            :value="woProgress"
            height="20"
            color="lime darken-4"
            background-color="lime darken-2"
            rounded
          >
            <strong class="caption white--text"> {{ woProgress }}% </strong>
          </v-progress-linear>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="pb-0 mx-4 mb-0">
      <v-col xs="6" sm="3" md="2" lg="2" class="ml-2">
        <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
          <div class="caption grey--text">
            Workorder ID:
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="yellow accent-4"
                  v-bind="attrs"
                  v-on="on"
                  v-if="workorderComments"
                >
                  mdi-android-messages
                </v-icon>
              </template>
              <span class="caption">{{ workorderComments }}</span>
            </v-tooltip>
          </div>
          <div class="caption primary--text pb-2">
            {{ workorderId }}
          </div>
          <div class="caption grey--text">Workorder Date:</div>
          <div class="caption primary--text pb-2">
            {{ woDate | formatToLocalDate }}
          </div>
          <div class="caption grey--text">Required Date:</div>
          <div class="caption primary--text pb-2">
            {{ woRequiredDate | formatToLocalDate }}
          </div>
          <div class="caption grey--text">Quanity:</div>
          <div class="caption primary--text pb-2">
            {{ woQuantity }} {{ units }}
          </div>
        </v-card>
      </v-col>
      <v-col xs="6" sm="5" md="3" lg="3">
        <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
          <div class="caption grey--text">Stock Location:</div>
          <div class="caption primary--text pb-2">
            {{ locationName }}
          </div>
          <div class="caption grey--text">Workflow:</div>
          <div class="caption primary--text pb-2">
            {{ workflowName }}
          </div>
          <div class="caption grey--text ">Workorder Type:</div>
          <div class="caption primary--text pb-2">
            {{ workorderType }}
          </div>
          <div class="caption grey--text">Workorder Status:</div>
          <div class="caption primary--text pb-2">
            {{ workorderStatus }}
          </div>
        </v-card>
      </v-col>
      <v-col xs="6" sm="3" md="3" lg="3">
        <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
          <div class="caption grey--text">Customer Name:</div>
          <div class="caption primary--text pb-2">
            {{ customerName }}
          </div>

          <div class="caption grey--text">
            Sales Ref:
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="yellow accent-4"
                  v-bind="attrs"
                  v-on="on"
                  v-if="salesComments"
                >
                  mdi-android-messages
                </v-icon>
              </template>
              <span class="caption">{{ salesComments }}</span>
            </v-tooltip>
          </div>
          <div class="caption primary--text pb-2">
            {{ salesReference }}
          </div>
          <div class="caption grey--text">Salesman:</div>
          <div class="caption primary--text pb-2">
            {{ salesMan }}
          </div>
          <div class="caption grey--text">Schedule Status:</div>
          <div class="caption primary--text pb-2">
            {{ scheduleStatus }}
          </div>
        </v-card>
      </v-col>
      <v-col xs="6" sm="auto" md="auto" lg="3">
        <v-card flat class="pa-2 rounded-lg">
          <template v-if="itemCode">
            <product :name="itemDescription" :productCode="itemCode"></product>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="pb-4 mx-4 mt-0 ml-5">
      <v-col cols="1" class="center">
        <v-btn
          small
          depressed
          color="yellow accent-2"
          class="primary--text"
          @click="scheduleWorkorder"
        >
          <v-icon left color="primary">
            mdi-account-clock
          </v-icon>
          Schedule Workorder
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import product from './Product';
// import User from "@/Services/user";
import Workorders from '@/Services/workorders';

export default {
  name: 'workorder',
  props: {
    workorder: Object,
  },
  components: { product },
  data() {
    return {
      multiLine: true,
      snackbar: false,
      absolutepo: true,
      workorderId: this.workorder.workorderId,
      workorderRef: this.workorder.workorderRef,
      locationCode: this.workorder.locationCode,
      locationName: this.workorder.locationName,
      woQuantity: this.workorder.woQuantity,
      units: this.workorder.units,
      woProducedQuantity: this.workorder.woProducedQuantity,
      itemCode: this.workorder.itemCode,
      itemDescription: this.workorder.itemDescription,
      woDate: this.workorder.woDate,
      woRequiredDate: this.workorder.woRequiredDate,
      woReleasedDate: this.workorder.woReleasedDate,
      workflowMasterId: this.workorder.workflowMasterId,
      workflowName: this.workorder.workflowName,
      workorderComments: this.workorder.workorderComments,
      workorderType: this.workorder.workorderType,
      workorderStatus: this.workorder.workorderStatus,
      workType: this.workorder.workType,
      salesReference: this.workorder.salesReference,
      customerName: this.workorder.customerName,
      salesMan: this.workorder.salesMan,
      salesComments: this.workorder.salesComment,
      scheduleStatus: this.workorder.scheduleStatus,
      woProgress: this.workorder.woProgress,
	  workManagers: [],
	  supervisors: []

    };
  },
  created() {
    //this.getUserImage(this.userId);
    // if (this.dashboard) {
    //   this.setEmployee(this.dashboard.profile[0]);
    // }
	this.getWorkManagers()
  },
  methods: {
    scheduleWorkorder() {
      this.$router.push({
        name: 'scheduleworkorders',
        params: { workorderId: this.workorderId },
      });
	   localStorage.setItem('workorderId', this.workorderId);
    },
	async getWorkManagers() {
      const response = await Workorders.getWorkManagers();
      this.workManagers = response.data.data;
      this.supervisors = response.data.data.map(item => item.resourceName);
	  this.$store.state.workManagers = this.supervisors
	  localStorage.setItem('workManagers', JSON.stringify(this.supervisors))
    },
  },
  computed: {
    ...mapGetters(['dashboard', 'userId', 'userImage', 'name', 'fullName']),
  },
  watch: {},
};
</script>

<style scoped>
/* Popup container - can be anything you want */
span[data-descr] {
  position: relative;
}

span[data-descr]:hover::after,
span[data-descr]:focus::after {
  content: attr(data-descr);
  position: absolute;
  left: 0;
  top: 24px;
  min-width: 200px;
  border: 1px #aaaaaa solid;
  border-radius: 10px;
  background-color: #0644ec;
  padding: 12px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  z-index: 1;
}
</style>
