import Service from './services';
class Utils {
  getDashboard() {
    let apiService = Service.getApiService();
    return apiService.get('utils/Dashboard');
  }

  refreshToken() {
    let apiService = Service.getApiService();
    return apiService.get('token/refresh');
  }

  getDataFilters() {
    let rowFilters = [
      {
        name: 'Workorder ID',
        code: 'woid',
        value: '',
      },
      {
        name: 'Workorder No',
        code: 'woref',
        value: '',
      },
      {
        name: 'Customer',
        code: 'customer',
        value: null,
      },
      {
        name: 'Item Name',
        code: 'item',
        value: null,
      },
      {
        name: 'Item Code',
        code: 'itemcode',
        value: null,
      },
      {
        name: 'Salesman',
        code: 'salesman',
        value: null,
      },
    ];
    return rowFilters;
  }
}

export default new Utils();
