<template>
  <!-- <v-card outlined dense class="ma-2"> -->
	  <div>
    <v-card-title
      color="blue"
      class="primary--text caption font-weight-bold  mx-2 "
    >
      <v-icon left small class="white primary--text">
        mdi-android-messages
      </v-icon>
      Comments
    </v-card-title>
    <v-alert color="workorderCard" class="caption greay--text mx-4">
      {{ commentText }}
    </v-alert>
    <div class="caption black--text pb-2 mx-8"></div>
	</div>
    <!-- </v-card> -->
  <!-- </v-card> -->
</template>
<script>
export default {
  name: 'comments',
  props: {
    commentText: String,
  },
};
</script>
