<template>
  <div>
    <v-snackbar top v-model="displayStatus" :timeout="5000" color="success">
      <span>Assignment status saved successfully</span>
      <v-btn class="mx-2 pl-2 " icon @click="displayStatus = false">
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>

    <v-row class="mb-2" no-gutters>
      <v-col xs="12" sm="12" md="6" lg="6">
        <v-card outlined dense class="ma-2">
          <v-card-title
            color="blue"
            class="primary--text caption font-weight-bold  mx-2 "
          >
            <v-icon left small class="white primary--text">
              mdi-calendar-clock
            </v-icon>
            Schedule Details
          </v-card-title>
          <v-row dense class="pb-0 mx-4 mb-4">
            <v-col xs="8" sm="8" md="8" lg="8">
              <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
                <div class="caption grey--text">Workorder:</div>
                <div class="caption primary--text pb-2">
                  {{ workorderId }}
                </div>
                <div class="caption grey--text">Product Name:</div>
                <div class="caption primary--text pb-2">
                  {{ itemName }}
                </div>
                <div class="caption grey--text">Product Code:</div>
                <div class="caption primary--text pb-2">
                  {{ itemCode }}
                </div>
                <div class="caption grey--text">Production Stage:</div>
                <div class="caption primary--text pb-2">
                  {{ productionStageName }}
                </div>
              </v-card>
            </v-col>
            <v-col xs="4" sm="4" md="4" lg="4">
              <v-card flat class="pa-2 pl-5 rounded-lg" color="workorderCard">
                <div class="caption grey--text">Quanity:</div>
                <div class="caption primary--text pb-2">
                  {{ itemQuantity }} {{ itemUnits }}
                </div>
                <div class="caption grey--text">Start Date:</div>
                <div class="caption primary--text pb-2">
                  {{ scheduleStartDate | formatToLocalDate }}
                </div>
                <div class="caption grey--text">End Date:</div>
                <div class="caption primary--text pb-2">
                  {{ scheduleEndDate | formatToLocalDate }}
                </div>
                <div class="caption grey--text">Allocated Quantity:</div>
                <div class="caption primary--text pb-2">
                  {{ allocatedQuantity }} {{ itemUnits }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="6">
        <v-card
          class="ma-2"
          outlined
          dense
          max-height="330px"
          min-height="280px"
        >
          <v-card-title
            color="blue"
            class="primary--text caption font-weight-bold  mx-2 mb-2"
          >
            <v-icon left small class="white primary--text">
              mdi-list-status
            </v-icon>
            Assignement Status
          </v-card-title>
          <v-row dense class="mx-4 mb-4">
            <v-col xs="6" sm="5" md="3" lg="3" class="ma-1 pa-0">
              <WorkorderDate
                label="Status Date"
                :woDate="statusDate"
                v-on:dateChange="updateStatusDate($event)"
                class="caption pt-4"
                id="StatusDate"
              />
            </v-col>
            <v-col auto>
              <v-select
                :items="allocations"
                item-text="allocationName"
                item-value="allocationCode"
                v-model="resourceAllocationId"
                class="caption mt-0 pt-0"
                dense
                outlined
                label="Resource"
                placeholder="Select resource"
                hide-details="false"
                id="resourceAllocationId"
                @change="getAllocatedQuantity"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row mx-4" dense>
            <v-col xs="6" sm="5" md="3" lg="3" class="ma-1 pa-0">
              <v-select
                :items="progressPercentages"
                v-model="progressPercentage"
                class="caption mt-0 pt-0"
                dense
                outlined
                label="Progress %"
                placeholder="Select progress"
                hide-details="false"
                id="progressPercentage"
              >
              </v-select>
            </v-col>
            <v-col auto class="ma-0 pa-0">
              <v-radio-group
                v-model="activityStatus"
                row
                class="caption primary--text  ma-0 pa-0 mt-2 ml-4"
              >
                <v-radio :value="activityStatusProgress">
                  <template v-slot:label>
                    <div class="caption primary--text">IN Progress</div>
                  </template>
                </v-radio>
                <!-- <v-radio :value="activityStatusNo">
                  <template v-slot:label>
                    <div class="caption primary--text">NO Activity</div>
                  </template>
                </v-radio> -->
                <v-radio :value="activityStatusHold">
                  <template v-slot:label>
                    <div class="caption primary--text">ON Hold</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row mx-4" dense>
            <v-col xs="6" sm="5" md="3" lg="3" class="ma-1 pa-0">
              <v-select
                :items="workedHours"
                v-model="workedHour"
                class="caption mt-0 pt-0"
                dense
                outlined
                label="No: Hours"
                placeholder="Select Hours"
                hide-details="false"
                id="workedHour"
              >
              </v-select>
            </v-col>
            <v-col auto class="mt-0 pt-1 px-1">
              <v-textarea
                outlined
                name="input-7-4"
                label="Comments"
                rows="1"
                dense
                class="caption"
                v-model="statusComment"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense class="pb-0 mx-4 mb-0 d-flex mt-n5 pb-2">
            <v-col xs="12" sm="12" md="8" lg="8">
              <div v-show="errorMessage">
                <v-alert dense text type="error" class="caption">
                  {{ errorMessage }}
                </v-alert>
              </div>
            </v-col>
          </v-row>

          <v-btn
            small
            depressed
            color="yellow accent-2"
            class="primary--text ml-5 mt-n5 mb-0"
            @click="saveAllocationProgress"
            :disabled="saveInProgress"
          >
            <v-icon left color="primary">
              mdi-content-save
            </v-icon>
            Save Progress
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-card outlined dense class="pb-10">
      <v-card-title
        color="blue"
        class="primary--text caption font-weight-bold  mx-2 mb-3"
      >
        <v-icon left small class="white primary--text"
          >mdi-clock-outline</v-icon
        >
        <span class="primary--text"> Status History</span>
      </v-card-title>

      <v-row color="green" class="px-10">
        <v-divider></v-divider>
      </v-row>
      <div v-for="(progress, RowIndex) in progressHistory" :key="RowIndex">
        <v-row dense class="px-7 py-1 mt-3">
          <v-col lg="1">
            <div class="caption grey--text">SL No</div>
            <div class="caption primary--text ">
              {{ RowIndex + 1 }}
            </div>
          </v-col>
          <v-col lg="2">
            <div class="caption grey--text ">Date</div>
            <div class="caption primary--text">
              {{ progress.progressDate | formatToLocalDate }}
            </div>
          </v-col>

          <v-col lg="2">
            <div class="caption grey--text ">Progress</div>
            <!-- <div class="caption blue-grey--text">
              {{ progress.progressPercentage }} %
            </div> -->
            <v-progress-linear
              v-model="progress.progressPercentage"
              height="15"
              color="green"
            >
              <strong class="caption white--text">
                {{ progress.progressPercentage }}%
              </strong>
            </v-progress-linear>
          </v-col>
          <v-col lg="2">
            <div class="caption grey--text">Status</div>
            <div class="caption primary--text ">
              {{ progress.activityStatusDetails }}
            </div>
          </v-col>
          <v-col lg="4">
            <div class="caption grey--text">Comments</div>
            <div class="caption primary--text ">
              {{ progress.progressComment }}
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-divider></v-divider>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import Workorders from '@/Services/workorders';
import WorkorderDate from './WorkorderDate';
import moment from 'moment';
import { StatusCodes } from 'http-status-codes';
export default {
  name: 'AssignmentAllocation',
  props: {
    scheduleCode: String,
  },
  components: {
    WorkorderDate,
  },
  data() {
    return {
      hide_details_true: true,
      hide_details_false: false,
      snackbar: false,
      workorderId: null,
      workorderReference: null,
      workorderStartDate: null,
      workorderEndDate: null,
      scheduleStartDate: null,
      scheduleEndDate: null,
      statusDate: moment().format('YYYY-MM-DD'),
      progressDate: moment().format('YYYY-MM-DD'),
      itemQuantity: null,
      itemUnits: null,
      itemCode: null,
      itemName: null,
      productionStageName: null,
      prodStageCode: null,
      productionStageLocation: null,
      isAccepted: 0,
      acceptance: null,
      isClosed: 0,
      closed: null,
      productionStageColor: null,
      workorderStatusCode: null,
      workorderStatus: null,
      workorderStatusColor: null,
      productionStageProgress: 0,
      statusComment: '',
      resourceCode: '',
      locationCode: '',
      locations: [],
      allocations: [],
      displayStatus: false,
      errorMessage: '',
      resourceAllocationId: null,
      allocatedQuantity: 0,
      locationStock: [],
      activityStatus: 0,
      activityStatusProgress: 0,
      activityStatusNo: 1,
      activityStatusHold: -1,
      progressPercentage: 0,
      progressPercentages: [],
      progressHistory: [],
      workedHour: 0,
      workedHours: [],
      saveInProgress: false,
    };
  },
  created() {
	this.$store.commit('showProgressCircular', 500)
	setTimeout(() => {
    	this.$store.dispatch('showMyAssignment', true);
	}, 0);
	this.getResourceAllocations();
    this.setupProgressPercentages();
    this.setupWorkedHours();
  },
  methods: {
    setupProgressPercentages() {
      for (let index = 0; index <= 20; index++) {
        this.progressPercentages.push(index * 5);
      }
    },
    setupWorkedHours() {
      for (let index = 0; index <= 100; index++) {
        this.workedHours.push(index);
      }
    },
    async saveAllocationProgress() {
      try {
        if (!this.validatAllocationProgress()) return;
        this.saveInProgress = true;
        let resource = this.allocations.filter((allocation) => {
          return allocation.allocationCode == this.resourceAllocationId;
        });
        const response = await Workorders.saveAllocationProgress({
          scheduleId: this.getScheduleCode,
          progressPercentage: this.progressPercentage,
          resourceAllocationId: this.resourceAllocationId,
          progressDate: this.progressDate,
          activityStatus: this.activityStatus,
          progressComment: this.statusComment,
          effort: this.workedHour,
          resourceCode: resource[0].resourceCode,
        });

        if (response.data.header.success) {
			this.$store.commit('showProgressCircular', 2000)
			setTimeout(() => {
				this.displayStatus = true;
      		}, 2000)
          this.getResourceAllocationProgress();
        }
      } catch (error) {
        if (error.response.status == StatusCodes.PRECONDITION_FAILED) {
          this.errorMessage = error.response.data.header.message;
        }
      }
      this.saveInProgress = false;
    },
    async getResourceAllocations() {
      const response = await Workorders.getResourceAllocations(
        this.getScheduleCode
      );
      let workorder = response.data.data;
      this.setupWorkorderDetails(workorder);
    },
    async getResourceAllocationProgress() {
      const response = await Workorders.getResourceAllocationProgress(
        this.getScheduleCode,
        this.resourceAllocationId
      );

      this.progressHistory = response.data.data;
    },

    setupWorkorderDetails(workorder) {
      this.workorderId = workorder.workorderId;
      this.workorderReference = workorder.workorderReference;
      this.prodStageCode = workorder.productionStageId;
      this.productionStageName = workorder.productionStageName;
      this.workorderStartDate = workorder.woScheduleStartDate;
      this.workorderEndDate = workorder.woScheduleStartDate;
      this.scheduleStartDate = workorder.scheduledStartDate;
      this.scheduleEndDate = workorder.scheduledEndDate;
      this.itemQuantity = workorder.itemQuantity;
      this.itemUnits = workorder.itemUnits;
      this.itemCode = workorder.itemCode;
      this.itemName = workorder.itemDescription;
      this.isAccepted = workorder.isAccepted;
      this.acceptance = workorder.acceptance;
      this.isClosed = workorder.isClosed;
      this.closed = workorder.closed;
      this.productionStageColor = workorder.productionStageColor;
      this.workorderStatusCode = workorder.workorderStatusCode;
      this.workorderStatus = workorder.workorderStatus;
      this.workorderStatusColor = workorder.workorderStatusColor;
      this.productionStageProgress = workorder.productionStageProgress;
      this.resourceCode = workorder.resourceCode;
      this.locationCode = workorder.locationCode;
      this.productionStageLocation = workorder.productionStageLocation;
      this.setupAllocations(workorder.allocation);
    },
    setupAllocations(allocations) {
      allocations.forEach((allocation) => {
        this.allocations.push({
          allocationCode: allocation.resourceAllocationId,
          allocationName: allocation.resourceName,
          allocatedQuantity: allocation.allocatedQuantity,
          resourceCode: allocation.resourceCode,
        });
      });
    },

    updateStatusDate(updatedDate) {
      this.errorMessage = '';
      this.progressDate = updatedDate;
    },

    validatAllocationProgress() {
      this.errorMessage = '';

      if (!this.resourceAllocationId) {
        this.errorMessage = `Select resource`;
        document.getElementById('resourceAllocationId').focus();
        return false;
      }

      return true;
    },
    getAllocatedQuantity() {
      let allocation = this.allocations.filter((element) => {
        return element.allocationCode == this.resourceAllocationId;
      });
      this.allocatedQuantity =
        allocation.length > 0 ? allocation[0].allocatedQuantity : 0;
    },
  },
  computed: {
    getCaption() {
      return `${this.caption} Date `;
    },
    getQuantityCaption() {
      return `${this.caption} Quantity `;
    },
	getScheduleCode() {
		return localStorage.getItem('scheduleCode');
	}
  },
  watch: {
    resourceAllocationId: function() {
      this.getResourceAllocationProgress();
    },
  },
};
</script>
<style scoped></style>
