var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"max-height":_vm.$vuetify.breakpoint.xs ? 380 : 500,"offset-x":"","nudge-left":_vm.$vuetify.breakpoint.xs ? 100 : 300,"nudge-right":_vm.$vuetify.breakpoint.xs ? 100 : 0,"bottom":"","origin":"right-top","transition":"fab-transition","max-width":_vm.$vuetify.breakpoint.xs ? '250' : '500'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","transition":"scroll-y-transition","color":"info","close-delay":"0","open-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-progress-linear',_vm._g(_vm._b({staticClass:"project-progress mt-1",attrs:{"value":_vm.progress,"height":"20","color":"lime darken-4","background-color":"lime darken-2","rounded":""}},'v-progress-linear',attrs,false),Object.assign({}, tooltip, menu)),[_c('strong',{staticClass:"caption white--text"},[_vm._v(" "+_vm._s(_vm.progress)+"% ")])])]}}],null,true)},[_c('span',[_vm._v("Click here to see progress details")])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.progressdetails,"item-key":"schedule","calculate-widths":"","no-data-text":"No Data Avaialable","loading-text":"Please wait as we load data...!!!","hide-default-footer":"","item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.prodProgress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{staticClass:"project-progress mt-1",attrs:{"value":item.prodProgress,"height":"15","color":"lime darken-4","background-color":"lime darken-2","rounded":""}},[_c('strong',{staticClass:"caption white--text"},[_vm._v(" "+_vm._s(item.prodProgress)+"% ")])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }