<template>
  <v-menu
    :close-on-content-click="true"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="200px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formatWoDate"
        append-icon="event"
        readonly
        dense
        outlined
        :label="label"
        v-bind="attrs"
        v-on="on"
        class="caption"
        hide-details="false"
        :disabled="disabled"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectedDate"
      v-on:click="$emit('dateChange', $event.target.value)"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import moment from 'moment';

export default {
  name: 'WorkorderDate',
  props: {
    woDate: String,
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedDate: this.woDate,
    };
  },
  computed: {
    formatWoDate() {
      return this.selectedDate
        ? moment(this.selectedDate).format('DD/MM/YYYY')
        : '';
    },
  },
  watch: {
    selectedDate(newValue) {
      this.$emit('dateChange', newValue);
    },
    woDate(newValue) {
      this.selectedDate = newValue;
    },
  },
};
</script>
