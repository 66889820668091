<template>
  <!-- <v-card dense outlined class="ma-2"> -->
	  <div>
    <v-card-title
      color="blue"
      class="primary--text caption font-weight-bold  mx-2"
    >
      <v-icon left small class="white primary--text">mdi-account-box</v-icon>
      {{ heading }}
    </v-card-title>
    <!-- <v-row dense class="pt-0 pb-0 mx-4 mb-0 mt-n7">
      <v-col xs="12" sm="12" md="12" lg="12" class="pt-0 mt-4"> -->
        <!-- <table class="product_table ">
          <thead class="table_head caption">
            <tr>
              <th>Product code</th>
              <th>Product name</th>
              <th class="table-number">Quantity</th>
              <th class="table-number">Units</th>
              <th class="table-number">Stock</th>
              <th>Production stage</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :id="RowIndex"
              v-for="(boomItem, RowIndex) in boomItems"
              :key="RowIndex"
              class="caption"
            >
              <td>{{ boomItem.componentCode }}</td>
              <td>{{ boomItem.componentName }}</td>
              <td class="table-number">
                {{ boomItem.componentQuantity | formatToLocaleNumber }}
              </td>
              <td class="table-number">{{ boomItem.componentUnit }}</td>
              <td class="table-number">
                {{ boomItem.currentStock | formatToLocaleNumber }}
              </td>
              <td>{{ boomItem.productionStageName }}</td>
            </tr>
          </tbody>
        </table> -->
		<v-data-table
    :headers="headers"
    :items="boomItems"
	dense
	hide-default-footer
	class="mb-2 elevation-1">
	 <template #[`item.componentQuantity`]="{item}">
      {{ item.componentQuantity | formatToLocaleNumber }}
    </template>
	 <template #[`item.currentStock`]="{item}">
      {{ item.currentStock | formatToLocaleNumber }}
    </template>
  </v-data-table>
      <!-- </v-col>
    </v-row> -->
	</div>
  <!-- </v-card> -->
</template>

<script>
export default {
  props: {
    heading: String,
    boomItems: Array,
  },
  data() {
	  return{
		headers: [
        { text: 'PRODUCT CODE', value: 'componentCode' },
        { text: 'PRODUCT NAME', value: 'componentName' },
        { text: 'QUANTITY', value: 'componentQuantity' },
        { text: 'UNITS', value: 'componentUnit' },
        { text: 'STOCK', value: 'currentStock' },
        { text: 'PRODUCT STAGE', value: 'productionStageName' },
      ],
	  }
  },
  methods: {
    formatToLocaleNumber(value) {
      if (!value) return 0;
      if (NaN(value)) return 0;
      return value.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>

<style>
.product_table {
  min-width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  padding: 10px;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

.product_table thead tr {
  background-color: #618394;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.product_table th,
.product_table td {
  padding: 8px 10px;
}

.product_table tbody tr {
  border-bottom: 0.5px solid #dddddd;
}

.product_table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
.product_table tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.product_table tbody tr:last-of-type {
  border-bottom: 1px solid #618394;
}

.product_table tbody {
  border-left: 2px solid #618394;
  border-right: 2px solid #618394;
}

.table-number {
  text-align: right;
}
h4 {
  color: #009879;
}

.product_table tbody tr:hover {
  background-color: #f0f8ff;
}
</style>
