import axios from 'axios';
// const BASE_URL = process.env.VUE_APP_BASE_URL;
const BASE_URL = 'https://berrydtaleapi.turqosoft.com/api/v1';
class Service {
  constructor() {}
  getToken() {
    return localStorage.getItem('token');
  }

  getApiService() {
    let token = this.getToken();
    const apiClient = axios.create({
      baseURL: BASE_URL,
    });
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return apiClient;
  }
}

export default new Service();
