import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatToLocalDate', function(value) {
  if (!value) return '';
  return `${moment(value).format('DD/MM/YYYY')}`;
});

Vue.filter('formatToLocaleNumber', function(value) {
  if (!value) return 0;
  if (isNaN(value)) return 0;
  return value.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
});
