<template>
  <div>
    <v-snackbar top v-model="displayStatus" :timeout="5000" color="success">
      <span>Resource allocation saved successfully</span>
      <v-btn class="mx-2 pl-2 " icon @click="displayStatus = false">
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
    <v-row class="mb-2">
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card outlined dense>
          <v-card-title
            color="blue"
            class="primary--text caption font-weight-bold  mx-2 "
          >
            <v-row class="d-inline-flex ">
              <v-col>
                <v-icon left small class="white primary--text">
                  mdi-calendar-clock
                </v-icon>
                Schedule Details
              </v-col>
            </v-row>
          </v-card-title>
          <v-row dense class="pb-0 mx-4 mb-0 d-flex justify-space-between">
            <v-col xs="6" sm="4" md="2" lg="1">
              <div class="caption grey--text">Workorder:</div>
              <div class="caption primary--text pb-2">
                {{ workorderId }}
              </div>
            </v-col>
            <v-col xs="6" sm="4" md="2" lg="2">
              <div class="caption grey--text">Stage name:</div>
              <div class="caption primary--text pb-2">
                {{ productionStageName }}
              </div>
            </v-col>
            <v-col xs="6" sm="4" md="2" lg="1">
              <div class="caption grey--text">Product Code:</div>
              <div class="caption primary--text pb-2">
                {{ itemCode }}
              </div>
            </v-col>
            <v-col xs="6" sm="6" md="3" lg="3">
              <div class="caption grey--text">Product Name:</div>
              <div class="caption primary--text pb-2">
                {{ itemName }}
              </div>
            </v-col>
            <v-col xs="6" sm="4" md="1" lg="1">
              <div class="caption grey--text">Quanity:</div>
              <div class="caption primary--text pb-2">
                {{ itemQuantity }} {{ itemUnits }}
              </div>
            </v-col>
            <v-col xs="6" sm="4" md="2" lg="1">
              <div class="caption grey--text">Start Date:</div>
              <div class="caption primary--text pb-2">
                {{ scheduledStartDate | formatToLocalDate }}
              </div>
            </v-col>
            <v-col xs="6" sm="4" md="2" lg="1">
              <div class="caption grey--text">End Date:</div>
              <div class="caption primary--text pb-2">
                {{ scheduledEndDate | formatToLocalDate }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-card outlined dense>
      <v-card-title
        color="blue"
        class="primary--text caption font-weight-bold  mx-2 "
      >
        <v-row class="d-inline-flex ">
          <v-col>
            <v-icon left small class="white primary--text"
              >mdi-account-box</v-icon
            >
            Job Allocation
          </v-col>
        </v-row>
      </v-card-title>
      <hr class="hr_line" />
      <v-card
        :id="RowIndex"
        v-for="(allocation, RowIndex) in allocations"
        :key="RowIndex"
        dense
        flat
      >
        <v-row dense class="pb-3 mx-4 pt-0">
          <v-col xs="12" sm="3" md="2" lg="2" class="caption">
            <v-autocomplete
              :items="workers"
              item-text="resourceName"
              item-value="resourceCode"
              v-model="allocation.WorkerCode"
              class="caption"
              dense
              outlined
              label="Worker"
              placeholder="Select worker"
              hide-details="false"
              :id="getRef('worker', RowIndex)"
              @change="updateRate(allocation.WorkerCode, RowIndex)"
              :disabled="
                disableModifyWorker(allocation.Progress, allocation.QcStatus)"
            ></v-autocomplete>
          </v-col>
          <v-col xs="12" sm="3" md="2" lg="2" class="">
            <WorkorderDate
              label="Start Date"
              :woDate="allocation.StartDate"
              v-on:dateChange="updateStartDate($event, RowIndex)"
              class="caption"
              :id="getRef('StartDate', RowIndex)"
              :disabled="
                disableModifyStartDate(allocation.Progress, allocation.Issues)"
            />
          </v-col>
          <v-col xs="12" sm="4" md="2" lg="2" class="">
            <WorkorderDate
              label="End Date"
              :woDate="allocation.EndDate"
              v-on:dateChange="updateEndDate($event, RowIndex)"
              class="caption pt-4"
              :id="getRef('EndDate', RowIndex)"
              :disabled="
                disableModifyEndDate(allocation.Progress, allocation.QcStatus)"
            />
          </v-col>
          <v-col xs="12" sm="3" md="1" lg="1" class="">
            <v-text-field
              label="Quantity"
              outlined
              dense
              hide-details
              reverse
              v-model="allocation.Quantity"
              class="caption"
              :id="getRef('Quantity', RowIndex)"
              :disabled="
                disableQuantity(allocation.Progress, allocation.QcStatus)"
            >
            </v-text-field>
          </v-col>
          <v-col xs="12" sm="3" md="1" lg="1" class="">
            <v-text-field
              label="Units"
              outlined
              dense
              hide-details
              readonly
              v-model="allocation.Unit"
              class="caption"
            >
            </v-text-field>
          </v-col>
          <v-col xs="12" sm="2" md="1" lg="1" class="caption">
            <v-text-field
              label="Rate"
              outlined
              dense
              reverse
              hide-details
              v-model="allocation.Price"
              class="caption"
              :id="getRef('Price', RowIndex)"
              :disabled="disableRateChange(allocation.QcStatus)"
            >
            </v-text-field>
          </v-col>
          <v-col xs="12" sm="2" md="1" lg="1" class="caption">
            <v-text-field
              label="Rate type"
              outlined
              dense
              reverse
              hide-details
              v-model="allocation.RateType"
              class="caption"
              :id="getRef('RateType', RowIndex)"
			  :readonly="true"
            >
            </v-text-field>
          </v-col>
          <v-col xs="6" sm="auto" md="1" lg="1" align-self="start">
            <div class="caption grey--text text-center mt-n2">Delete</div>
            <div class="caption red--text text-center">
              <v-icon
                @click="deleteRow(RowIndex)"
                class="red--text"
                :disabled="
                  disableDelete(allocation.Progress, allocation.Issues)"
              >
                mdi-delete
              </v-icon>
            </div>
          </v-col>
          <v-col
            xs="6"
            sm="auto"
            md="1"
            lg="1"
            align-text="center"
            v-show="RowIndex == allocations.length - 1"
          >
            <div class="caption grey--text text-left mt-n2">New</div>
            <div class="caption green--text text-left">
              <v-icon @click="addNewRow" class="green--text">
                mdi-plus-box
              </v-icon>
            </div>
            <!-- </v-col>
            </v-row>
          </v-card> -->
          </v-col>
        </v-row>
        <hr class="hr_line_light" />
      </v-card>
      <v-row dense class="pb-4 mx-4 ">
        <v-col cols="9" class="center">
          <div v-if="errorMessage.length > 1">
            <v-alert dense text type="error" class="caption">
              {{ errorMessage }}
            </v-alert>
          </div>
          <v-btn
            small
            depressed
            color="yellow accent-2"
            class="primary--text"
            @click="saverRsourceAllocations"
            :disabled="saveInProgress"
          >
            <v-icon left color="primary">
              mdi-content-save
            </v-icon>
            Save Allocation
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import Workorders from '@/Services/workorders';
import WorkorderDate from './WorkorderDate';
import ItemList from '@/Utils/Assignment.js';
import moment from 'moment';
import { StatusCodes } from 'http-status-codes';
export default {
  name: 'AssignmentAllocation',
  props: {
    scheduleCode: String,
  },
  components: {
    WorkorderDate,
  },
  data() {
    return {
      snackbar: false,
      workorderId: null,
      productionStageId: null,
      productionStageProgress: 0,
      scheduledStartDate: null,
      scheduledEndDate: null,
      isAccepted: -1,
      resourceCode: null,
      acceptance: null,
      isClosed: 0,
      closed: null,
      productionStageName: null,
      productionStageColor: null,
      workorderReference: null,
      itemCode: null,
      workorderStatusCode: 0,
      itemQuantity: null,
      workorderStatus: null,
      workorderStatusColor: null,
      itemName: null,
      itemUnits: null,
      workflowName: null,
      workorderStartDate: null,
      workorderEndDate: null,
      allocations: [],
      displayStatus: false,
      workers: [],
      errorMessage: '',
      progress: 0,
      qcStatus: 0,
      qcPassed: 1,
      saveInProgress: false,
    };
  },
  created() {
	this.$store.commit('showProgressCircular', 500)
	setTimeout(() => {
    	this.$store.dispatch('showMyAssignment', true);
	}, 0);
	this.getResourceAllocations();
    this.getResources();
  },
  methods: {
    async getResources() {
      const response = await Workorders.getResources();
      this.workers = response.data.data;
    },
    async saverRsourceAllocations() {
      try {
        if (!this.validateAllocations()) return;
        this.saveInProgress = true;
        let resourceAllocations = {
          schedule: this.getScheduleCode,
          allocations: [],
        };
        this.allocations.forEach((resource) => {
          resourceAllocations.allocations.push({
            resourceCode: resource.WorkerCode,
            planStartDate: resource.StartDate,
            planEndDate: resource.EndDate,
            allocatedQuantity: resource.Quantity,
            allocatedRate: resource.Price,
            resourceAllocationId: resource.AllocationID,
          });
        });
        const response = await Workorders.saverRsourceAllocations(
          resourceAllocations
        );
        if (response.data.header.success) {
			this.$store.commit('showProgressCircular', 2000)
			setTimeout(() => {
				this.displayStatus = true;
      		}, 2000)
		}
      } catch (error) {
        if (error.response.status == StatusCodes.PRECONDITION_FAILED) {
          this.errorMessage = error.response.data.header.message;
        }
      }
      this.saveInProgress = false;
    },
    async getResourceAllocations() {
      //   this.scheduleCode = 118;
      const response = await Workorders.getResourceAllocations(
        this.getScheduleCode
      );
      let workorder = response.data.data;
      this.setupWorkorderDetails(workorder);
    },
    setupWorkorderDetails(workorder) {
      this.workorderId = workorder.workorderId;
      this.productionStageId = workorder.productionStageId;
      this.productionStageProgress = workorder.productionStageProgress;
      this.scheduledStartDate = moment(workorder.scheduledStartDate).format(
        'YYYY-MM-DD'
      );
      this.scheduledEndDate = moment(workorder.scheduledEndDate).format(
        'YYYY-MM-DD'
      );
      this.isAccepted = workorder.isAccepted;
      this.resourceCode = workorder.resourceCode;
      this.acceptance = workorder.acceptance;
      this.isClosed = workorder.isClosed;
      this.closed = workorder.closed;
      this.productionStageName = workorder.productionStageName;
      this.productionStageColor = workorder.productionStageColor;
      this.workorderReference = workorder.workorderReference;
      this.itemCode = workorder.itemCode;
      this.workorderStatusCode = workorder.workorderStatusCode;
      this.itemQuantity = workorder.itemQuantity;
      this.workorderStatus = workorder.workorderStatus;
      this.workorderStatusColor = workorder.workorderStatusColor;
      this.itemName = workorder.itemDescription;
      this.itemUnits = workorder.itemUnits;
      this.setupAssignmentItems(workorder.allocation);
    },
    setupAssignmentItems(items = []) {
      this.allocations = [];
      if (items.length == 0) {
        this.allocations.push(this.getNewAllocationItem());
      } else {
        items.forEach((item) => {
          let SlNo = 1;
          this.allocations.push(
            new ItemList(
              SlNo,
              item.resourceCode,
              item.planStartDate,
              item.planEndDate,
              item.allocatedQuantity,
              this.itemUnits,
              this.$options.filters.formatToLocaleNumber(item.allocatedRate),
              item.resourcePaymentType,
              item.progress,
              item.qcStatus,
              item.issues,
              item.resourceAllocationId
            )
          );
          SlNo += 1;
        });
      }
    },
    getNewAllocationItem(slno = 1) {
      let SlNo = slno;
      let WorkerCode = 0;
      let StartDate = this.scheduledStartDate;
      let EndDate = this.scheduledEndDate;
      let Quantity = this.itemQuantity;
      let Unit = this.itemUnits;
      let Price = 0;
      let RateType = '';
      let Progress = 0;
      let QcStatus = 0;
      let Issues = false;
      let AllocationID = 0;
      return new ItemList(
        SlNo,
        WorkerCode,
        StartDate,
        EndDate,
        Quantity,
        Unit,
        Price,
        RateType,
        Progress,
        QcStatus,
        Issues,
        AllocationID
      );
    },
    addNewRow() {
      this.allocations.push(
        this.getNewAllocationItem(this.allocations.length + 1)
      );
    },
    deleteRow(index) {
      this.allocations.splice(index, 1);
      if (this.allocations.length == 0) {
        this.addNewRow();
      }
    },
    getRef(fieldname, rowIndex) {
      return `${fieldname}-${rowIndex}`;
    },
    updateStartDate(updatedDate, RowIndex) {
      this.errorMessage = '';
      this.allocations[RowIndex].StartDate = updatedDate;
    },
    updateEndDate(updatedDate, RowIndex) {
      this.errorMessage = '';
      this.allocations[RowIndex].EndDate = updatedDate;
    },
    updateRate(resourceCode, RowIndex) {
      let worker = this.workers.filter((worker) => {
        return worker.resourceCode == resourceCode;
      });
      console.log(worker);
      this.allocations[
        RowIndex
      ].Price = this.$options.filters.formatToLocaleNumber(
        worker[0].resourceCost
      );

      this.allocations[RowIndex].RateType = worker[0].resourceUtilizationUnit;
    },
    validateAllocations() {
      this.errorMessage = '';
      let totalQuantity = 0;
      for (let index = 0; index < this.allocations.length; index++) {
        const allocation = this.allocations[index];

        if (!allocation.WorkerCode) {
          let fieldname = this.getRef('worker', index);
          this.errorMessage = `Select worker`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (!allocation.StartDate) {
          let fieldname = this.getRef('StartDate', index);
          this.errorMessage = `Select start date`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (allocation.StartDate < this.scheduledStartDate) {
          let fieldname = this.getRef('StartDate', index);
          this.errorMessage = `Start date can not be less than scheduled start date`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (allocation.StartDate > this.scheduledEndDate) {
          let fieldname = this.getRef('StartDate', index);
          this.errorMessage = `Start date can not be greater than scheduled end date`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (!allocation.EndDate) {
          let fieldname = this.getRef('EndDate', index);
          this.errorMessage = `Select end date`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (allocation.EndDate < this.scheduledStartDate) {
          let fieldname = this.getRef('EndDate', index);
          this.errorMessage = `End date can not be less than scheduled start date`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (allocation.EndDate > this.scheduledEndDate) {
          let fieldname = this.getRef('EndDate', index);
          this.errorMessage = `End date can not be greater than scheduled end date`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (!allocation.Quantity) {
          let fieldname = this.getRef('Quantity', index);
          this.errorMessage = `Enter Quantity`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (isNaN(allocation.Quantity)) {
          let fieldname = this.getRef('Quantity', index);
          this.errorMessage = `Invalid Quantity`;
          document.getElementById(fieldname).focus();
          return false;
        }
        if (allocation.Quantity <= 0) {
          let fieldname = this.getRef('Quantity', index);
          this.errorMessage = `Quantity should be greater than 0.`;
          document.getElementById(fieldname).focus();
          return false;
        }

        if (!allocation.Price) {
          let fieldname = this.getRef('Price', index);
          this.errorMessage = `Enter Rate`;
          document.getElementById(fieldname).focus();
          return false;
        }
		if (!allocation.RateType) {
          let fieldname = this.getRef('RateType', index);
          this.errorMessage = `Enter Rate Type`;
          document.getElementById(fieldname).focus();
          return false;
        }
        if (isNaN(allocation.Price)) {
          let fieldname = this.getRef('Price', index);
          this.errorMessage = `Invalid Rate`;
          document.getElementById(fieldname).focus();
          return false;
        }
        if (allocation.Price <= 0) {
          let fieldname = this.getRef('Price', index);
          this.errorMessage = `Rate should be greater than 0.`;
          document.getElementById(fieldname).focus();
          return false;
        }
        totalQuantity = totalQuantity + parseInt(allocation.Quantity);
      }

      if (totalQuantity > this.itemQuantity) {
        let index = this.allocations.length - 1;
        let fieldname = this.getRef('Quantity', index);
        this.errorMessage = `Total quantity can not be greater than scheduled quantity`;
        document.getElementById(fieldname).focus();
        return false;
      }
      return true;
    },
    disableModifyWorker(Progress, QcStatus) {
      if (Progress == 100) return true;
      if (QcStatus == this.qcPassed) return true;
      return false;
    },
    disableModifyStartDate(Progress, Issues) {
      if (Progress > 0) return true;
      if (Issues) return true;
      return false;
    },
    disableModifyEndDate(Progress, QcStatus) {
      if (Progress == 100) return true;
      if (QcStatus == this.qcPassed) return true;
      return false;
    },
    disableQuantity(Progress, QcStatus) {
      if (Progress == 100) return true;
      if (QcStatus == this.qcPassed) return true;
      return false;
    },
    disableRateChange(QcStatus) {
      if (QcStatus == this.qcPassed) return true;
      return false;
    },
    disableDelete(Progress, Issues) {
      if (Progress > 0) return true;
      if (Issues) return true;
      return false;
    },
  },
  computed: {
    getCaption() {
      return `Schedule`; //: ${this.productionStageName} `;
    },
	getScheduleCode() {
		return localStorage.getItem('scheduleCode');
	}
  },
};
</script>
<style scoped>
.hr_line {
  border-top: 3px solid saddlebrown;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
}
.hr_line_light {
  border-top: 1px #97c7e7;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 15px;
}
</style>
