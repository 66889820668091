<template>
  <div class="d-flex">
    <v-card
      flat
      tile
      outlined
      class="white--text mr-1"
      width="20"
      height="20"
      :color="progress.stageStatusColor"
      v-for="(progress, rowIndex) in woProgress"
      :key="rowIndex"
    >
      <span class="pa-1 caption">
        {{ progress.prodStageAbbr }}
      </span>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'ScheduleStatus',
  props: {
    woProgress: Array,
  },
};
</script>
