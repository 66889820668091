<template>
  <v-container>
    <v-card
      flat
      width="600"
      class="mx-auto mt-5 pa-12"
      :style="{ background: $vuetify.theme.themes.light.background }"
    >
      <!-- <v-card-title class="text-h5 font-weight bold primary--text">
        Login
      </v-card-title> -->
      <v-row align="center">
        <v-col cols="12">
          <div class="text-center">
            <v-btn class="mx-2" fab dark depressed small color="pink">
              <v-icon small color="white">mdi-lock-outline</v-icon>
            </v-btn>
          </div>
          <h2 class="text-center">
            Sign in
          </h2>
          <v-alert dense type="error" v-show="errorMessage">
            {{ errorMessage }}
          </v-alert>
        </v-col>
      </v-row>

      <v-card-text class="pt-0">
        <br />
        <v-form>
          <v-text-field
            label="Username"
            v-model="userLogin.name"
            @keypress.enter="login"
            :rules="userNameRules"
            @blur="clearMessage"
            prepend-inner-icon="mdi-account-circle"
            placeholder="username"
            color="primary"
            class="button"
            autocomplete="username"
            outlined
          />
          <v-text-field
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            v-model="userLogin.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            prepend-inner-icon="mdi-lock"
            @click:append="showPassword = !showPassword"
            @keypress.enter="login"
            :rules="passwordRules"
            @blur="clearMessage"
            placeholder="password"
            color="primary"
            class="button"
            autocomplete="current-password"
            outlined
          />
        </v-form>
      </v-card-text>
      <v-card-actions dense>
        <v-row class="mx-2">
          <v-col xs="12" sm="6" md="6" lg="6" align="center">
            <v-btn color="primary" @click="login" class="white--text" block>
              <v-icon left> mdi-login </v-icon>
              Sign In
            </v-btn>
          </v-col>
          <v-col xs="12" sm="6" md="6" lg="6" align="center">
            <v-btn color="primary" to="Home" class="white--text" block>
              <v-icon left> mdi-home</v-icon>
              Home
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import User from '@/Services/user';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      userLogin: {
        name: '',
        password: '',
        company: '0',
      },
      showPassword: false,
      errorMessage: null,
      userNameRules: [(value) => !!value || 'Username Required'],
      passwordRules: [(value) => !!value || 'Password Required'],
    };
  },

  methods: {
    async login() {
      try {
        const response = await User.userLogin(this.userLogin);
        this.$store.dispatch('login', response.data.data);
        if (this.loggedIn) {
          switch (this.isSupervisor) {
            case 1:
              this.$router.push({ name: 'myassignments' });
              break;

            default:
              this.$router.push({ name: 'dashboard' });
              break;
          }
        }
      } catch (error) {
        console.log({ error });
        //this.$store.dispatch("logout");
        this.errorMessage = error.response.data.header.message;
      }
    },
    clearMessage() {
      this.errorMessage = null;
    },
  },
  computed: {
    ...mapGetters(['loggedIn', 'isSupervisor']),
  },
};
</script>
