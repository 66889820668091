<template>
  <div>
    <!-- <div class="caption grey--text pb-0 mb-0">Delete</div> -->
    <!-- <div class="caption red--text "> -->
      <v-btn @click="dialog = true" icon class="ma-0 pa-0">
        <v-icon class="red--text" >
          mdi-delete
        </v-icon>
      </v-btn>
    <!-- </div> -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6">
          Delete
        </v-card-title>
        <v-card-text>{{ Message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class="white--text"
            depressed
            @click="deleteConfirmed"
            small
          >
            Yes
          </v-btn>
          <v-btn
            color="red darken-1"
            class="white--text"
            depressed
            @click="dialog = false"
            small
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    Message: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    deleteConfirmed(rowIndex) {
      this.dialog = false;
      this.$emit('delete', rowIndex);
    },
  },
};
</script>
