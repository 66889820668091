<template>
  <div>
    <Search
      v-on:FilterData="SearchData"
      :rowFilters="rowFilters"
	  @updateFilterText="updateFilterText($event)"
      @updateFilter="updateFilter($event)"
	  @clearFilter="clearFilter"
      class="mb-2"
    />
    <!-- <v-card class="mx-0 px-0"> -->
	<v-card outlined dense v-if="isNoListandNoFilter">
		<v-container fluid>
    <v-row dense align="center" justify="center" align-content="center" class="pa-10">
	  <v-card-title class="text-no-wrap pt-1 ps-2">
		  Sorry No data available...!
        </v-card-title>
	</v-row>
		</v-container>
	</v-card>
	<v-card outlined dense v-else-if="isNoListandFilter">
		<v-container fluid>
    <v-row dense align="center" justify="center" align-content="center" class="pa-10">
	  <v-card-title class="text-no-wrap pt-1 ps-2">
          Sorry, we couldn't find the workorder you are looking for!
        </v-card-title>
	</v-row>
		</v-container>
	</v-card>

		<div v-else-if="isListandNoFilter">
    <div v-for="workorder in workorderList" :key="workorder.workorderId">
      <workorder :workorder="workorder" />
		</div>
    
    <v-card flat class="mx-0 px-0">
      <WoPagination
        :TotalPages="totalPages"
        :TotalRows="totalNumberOfRows"
        v-on:pagechange="ChangePage"
        v-show="totalPages > 1"
		:selectedFilter="selectedFilter"
        :isFilterActive="isFilterActive"
      />
    </v-card>
	</div>
  </div>
</template>
<script>
import Workorder from '@/components/Workorder/Workorder2';
import Workorders from '@/Services/workorders';
import WoPagination from '@/components/Workorder/Pagination';
import Search from '@/components/Workorder/Search';
import Utils from '@/Services/utils';
import { mapGetters } from 'vuex';
export default {
  name: 'workorderList',
  components: {
    Workorder,
    WoPagination,
    Search,
  },
  data() {
    return {
      workorderList: [],
      totalPages: 0,
      pageNumber: 1,
      numberOfRows: 25,
      totalNumberOfRows: 0,
      dataFilters: {},
      rowFilters: null,
	  isNoData: true,
	  selectedFilter: '',
	  isFilterActive: false
    };
  },
  created() {
	this.$store.commit('showProgressCircular', 500)			  
	this.$store.dispatch('showWorkorders', false);
    this.rowFilters = Utils.getDataFilters();
    this.loadFilters();
    this.getWorkorders();
  },
  mounted() {
	  console.log(Object.keys(this.dataFilters).length);
  },
  beforeDestroy() {
    this.$store.dispatch('updateWorkorderDataFilters', this.dataFilters);
  },
  methods: {
    async getWorkorders() {
      const response = await Workorders.getOutstandingWorkorders(
        this.pageNumber,
        this.numberOfRows,
        this.dataFilters
      );
      this.workorderList = response.data.data;
      this.totalPages = response.data.pageinfo.totalPages;
      this.totalNumberOfRows = response.data.pageinfo.totalRecords;
    },
    ChangePage(moveToPage, numberOfRows) {
      this.pageNumber = moveToPage;
      this.numberOfRows = numberOfRows;
      this.getWorkorders();
    },
    SearchData(searchConditions) {
		this.$store.commit('showProgressCircular', 500)
		setTimeout(() => {
			this.dataFilters = searchConditions;
			this.getWorkorders();
		}, 0);
    },
    loadFilters() {
      if (this.workorderDataFilters) {
        this.dataFilters = this.workorderDataFilters;
      }
      for (var key in this.dataFilters) {
        this.updateFilterValue(key, this.dataFilters[key]);
      }
    },
    updateFilterValue(filterName, filterText) {
      this.rowFilters = this.rowFilters.map((element) => {
        if (element.code == filterName) {
          return {
            name: element.name,
            code: element.code,
            value: filterText,
          };
        }
        return element;
      });
    },
	updateFilterText(selectedFilter) {
      this.selectedFilter = selectedFilter;
    },
	updateFilter() {
      this.isFilterActive = true;
    },
	clearFilter() {
      this.isFilterActive = false;
    },
  },
  computed: {
    ...mapGetters(['workorderDataFilters']),
	isNoListandNoFilter() {
		return this.workorderList.length < 0 && Object.keys(this.dataFilters).length === 0  ? true : false
	},
	isListandNoFilter() {
		return this.workorderList.length > 0 ? true : false
	},
	isNoListandFilter() {
		return this.workorderList.length === 0 && Object.keys(this.dataFilters).length > 0 ? true : false
	},

  },
};
</script>
