import Service from './services';
class Users {
  userLogin(user) {
    let apiService = Service.getApiService();
    return apiService.post('/login', user);
  }
  getUserProfile(empId) {
    let apiService = Service.getApiService();
    return apiService.get(`employees/profile/${empId}`);
  }
  getUserImage(empId) {
    let apiService = Service.getApiService();
    return apiService.get(`employees/avatar/${empId}`, {
      responseType: 'arraybuffer',
    });
  }
  updatePassword(password) {
    let apiService = Service.getApiService();
    return apiService.post('users/pwdchange', password);
  }
}
export default new Users();
