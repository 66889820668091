<template>
  <v-container>
    <v-snackbar
      top
      v-model="displayStatus"
      :timeout="5000"
      color="success"
      width="250"
    >
      <span>Password updated successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="displayStatus = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-card
      flat
      width="600"
      class="mx-auto py-3 px-12"
      :style="{ background: $vuetify.theme.themes.light.background }"
      color="white"
    >
      <v-card-title class="text-h5 font-weight bold primary--text">
        Reset Password
      </v-card-title>
      <v-row align="center" v-show="errorMessage">
        <v-col cols="12">
          <v-alert dense type="error">
            {{ errorMessage }}
          </v-alert>
        </v-col>
      </v-row>
      <v-card-text class="pt-0">
        <br />
        <v-form ref="resetPassword">
          <v-text-field
            label="Current Password"
            :type="showCurrentPassword ? 'text' : 'password'"
            v-model="currentPassword"
            :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            prepend-inner-icon="mdi-lock"
            @click:append="showCurrentPassword = !showCurrentPassword"
            @keypress.enter="updatePassword"
            :rules="passwordRules"
            @blur="clearMessage"
            outlined
            placeholder="Current password"
            color="primary"
            class="button"
          />
          <v-text-field
            label="New Password"
            :type="showNewPassword ? 'text' : 'password'"
            v-model="newPassword"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            prepend-inner-icon="mdi-lock"
            @click:append="showNewPassword = !showNewPassword"
            @keypress.enter="updatePassword"
            :rules="passwordRules"
            @blur="clearMessage"
            outlined
            placeholder="New password"
            color="primary"
            class="button"
          />
          <v-text-field
            label="Confirm New Password"
            :type="showConfirmPassword ? 'text' : 'password'"
            v-model="confirmPassword"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            prepend-inner-icon="mdi-lock"
            @click:append="showConfirmPassword = !showConfirmPassword"
            @keypress.enter="updatePassword"
            :rules="matchingPassword"
            @blur="clearMessage"
            outlined
            placeholder="Confirm New password"
            color="primary"
            class="button"
          />
        </v-form>
      </v-card-text>
      <v-card-actions dense>
        <v-row>
          <v-col cols="6" align="center">
            <v-btn
              color="primary"
              @click="updatePassword"
              class="mx-2 white--text"
              block
            >
              <v-icon left> mdi-lock </v-icon>
              Update Password
            </v-btn>
          </v-col>
          <v-col cols="6" align="center">
            <v-btn color="primary" :to="toLink" class="white--text" block>
              <v-icon left> mdi-close</v-icon>
              Close
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import User from '@/Services/user';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      company: '0',
	  showPassword: false,
      showCurrentPassword: false,
	  showNewPassword: false,
      showConfirmPassword: false,
      errorMessage: null,
      displayStatus: false,
      passwordRules: [(value) => !!value || 'Password Required'],
      matchingPassword: [
        (value) => !!value || 'Password Required',
        (value) => {
          if (value != this.newPassword) {
            return 'New & Confirm password are different';
          } else {
            return true;
          }
        },
      ],
    };
  },
  methods: {
    async updatePassword() {
      if (!this.$refs.resetPassword.validate()) return;
      try {
		  const response = await User.updatePassword({
			  oldPassword: this.currentPassword,
          newPassword: this.newPassword,
        });
        if (response.data.header.success) {
			this.$store.commit('showProgressCircular', 500)
			this.clearInputFields()
			this.clearMessage()
			this.$refs.resetPassword.resetValidation()
			setTimeout(() => {
          		this.displayStatus = true;
			}, 500);
        }
      } catch (error) {
        console.log({ error });
        this.errorMessage = error.response.data.header.message;
      }
    },
	clearInputFields() {
		this.currentPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
	},
    clearMessage() {
      this.errorMessage = null;
    },
  },
  computed: {
    ...mapGetters([
		'loggedIn',
		'isSupervisor'
	]),
	toLink() {
		return this.isSupervisor === 1 ? 'myassignments' : 'dashboard'
	}
  },
};
</script>
