import axios from 'axios';
import Utils from '../Services/utils';
import User from '@/Services/user';
export default {
  state: {
    user: {
      name: '',
      token: '',
      company: '',
      id: '',
      fullName: '',
      userImage: '',
      baseLocation: '',
      baseLocationName: '',
      isSupervisor: -1,
	  workManagers : [],
    },
    dashboard: null,
    schedule: {
      dataFilters: null,
    },
    workorder: {
      dataFilters: null,
    },
    showMyAssignements: false,
    showWorkorders: false,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.user.token = token;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Autherization'] = `Bearer ${token}`;
    },
    SET_USERID(state, id) {
      state.user.id = id;
      localStorage.setItem('userid', id);
    },
    SET_NAME(state, name) {
      state.user.name = name;
    },
    SET_FULLNAME(state, fullName) {
      state.user.fullName = fullName;
    },
    SET_COMPANY(state, company) {
      state.user.company = company;
      localStorage.setItem('company', company);
    },
    SET_IMAGE(state, userImage) {
      state.user.userImage = userImage;
    },
    SET_DASHBOARD(state, dashboard) {
      state.dashboard = dashboard;
      state.user.baseLocation = dashboard.profile[0].baseLocation;
      state.user.baseLocationName = dashboard.profile[0].baseLocationName;
    },
    SET_SUPERVISOR(state, isSupervisor) {
      state.user.isSupervisor = isSupervisor;
      localStorage.setItem('isSupervisior', isSupervisor);
    },
    SET_SCHEDULE_DATAFILTERS(state, dataFilters) {
      state.schedule.dataFilters = dataFilters;
    },
    SET_WORKORDER_DATAFILTERS(state, dataFilters) {
      state.workorder.dataFilters = dataFilters;
    },
    SET_MYASSIGNEMTNTS(state, myAssignement) {
      state.showMyAssignements = myAssignement;
    },
    SET_SHOW_WORKORDERS(state, showWorkorders) {
      state.showWorkorders = showWorkorders;
    },
    CLEAR_TOKEN() {
      localStorage.removeItem('token');
      localStorage.removeItem('userid');
      localStorage.removeItem('company');
      localStorage.removeItem('isSupervisior');
	  localStorage.removeItem('scheduleCode');
	  localStorage.removeItem('workorderId');
      location.reload();
    },
    CLEAR_USER(state) {
      state.user.id = null;
      state.user.name = null;
      state.user.company = null;
      state.user.userImage = null;
      state.user.fullName = null;
      state.user.isSupervisor = null;
      localStorage.removeItem('token');
      location.reload();
    },
  },
  actions: {
    login({ commit }, loginData) {
      try {
        commit('SET_SUPERVISOR', loginData.isSupervisor);
        commit('SET_TOKEN', loginData.token);
        commit('SET_USERID', loginData.id);
        commit('SET_NAME', loginData.name);
        commit('SET_COMPANY', loginData.company);
        commit('SET_FULLNAME', loginData.fullName);
        // dispatch('getDashboard');
        // dispatch('getUserImage');
      } catch (error) {
        console.log(error);
      }
    },
    logout({ commit }) {
      commit('CLEAR_USER');
	  commit('CLEAR_TOKEN');
    },
    async getDashboard({ commit }) {
      const response = await Utils.getDashboard();
      commit('SET_DASHBOARD', response.data.data);
    },
    async getUserImage({ commit, getters }) {
      try {
        const response = await User.getUserImage(getters.userId);
        var mimeType = response.headers['content-type'].toLowerCase();
        var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
        commit('SET_IMAGE', 'data:' + mimeType + ';base64,' + imgBase64);
      } catch (error) {
        console.log(error);
      }
    },
    async refreshToken({ dispatch }) {
      const response = await Utils.refreshToken();
      dispatch('login', response.data.data);
    },
    updateScheduleDataFilters({ commit }, dataFilters) {
      commit('SET_SCHEDULE_DATAFILTERS', dataFilters);
    },
    updateWorkorderDataFilters({ commit }, dataFilters) {
      commit('SET_WORKORDER_DATAFILTERS', dataFilters);
    },
    showMyAssignment({ commit }, myAssign) {
      commit('SET_MYASSIGNEMTNTS', myAssign);
    },
    showWorkorders({ commit }, show_workordres) {
      commit('SET_SHOW_WORKORDERS', show_workordres);
    },
  },
  getters: {
    loggedIn(state) {
      return !!state.user.token;
    },
    userId(state) {
      return state.user.id;
    },
    company(state) {
      return state.user.company;
    },
    name(state) {
      return state.user.name;
    },
    fullName(state) {
      return state.user.fullName;
    },
    dashboard(state) {
      return state.dashboard;
    },
    userImage(state) {
      return state.user.userImage;
    },
    baseLocation(state) {
      return state.user.baseLocation;
    },
    baseLocationName(state) {
      return state.user.baseLocationName;
    },
    isSupervisor(state) {
      return state.user.isSupervisor;
    },
    scheduleDataFilters(state) {
      return state.schedule.dataFilters;
    },
    workorderDataFilters(state) {
      return state.workorder.dataFilters;
    },
    showMyAssignment(state) {
      return state.showMyAssignements;
    },
    showWorkorders(state) {
      return state.showWorkorders;
    },
  },
  modules: {},
};
