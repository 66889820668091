<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
	:nudge-left="150"
	:max-width="$vuetify.breakpoint.xs ? '250' : '500'"
	max-height="500"
    offset-x
	disable-keys
	rounded="rounded.length - 1"
	origin="right-top"
	transition="fab-transition"
  >
    <template #activator="{ on: menu, attrs }">
	<v-tooltip bottom :open-on-focus="true" transition="scroll-y-transition" eager color="info" >
        <template #activator="{ on: tooltip }">

	<v-avatar
           tile  height="50px" left max-width="50px"
		   v-bind="attrs"
        v-on="{ ...tooltip, ...menu }"
        >
          <v-img
            :src="productImage"
			aspect-ratio="1"   eager :lazy-src="productImage" transition="fade-transition"
          ></v-img>
        </v-avatar>
		</template>
		   <span>Click here to see the image</span>
      </v-tooltip>
		</template>
		<v-card :img="productImage">
			<v-img
            :src="productImage" 
			 contain 
			eager :lazy-src="productImage" transition="fade-transition" 
          ></v-img>
		</v-card>
</v-menu>
</template>
<script>
import Workorders from '@/Services/workorders';
export default {
  name: 'product',
  props: {
    name: String,
    productCode: String,
  },
  data() {
    return {
      productImage: '',
	  menu: false
    };
  },
  mounted() {
    this.getProductImage();
  },
  methods: {
    async getProductImage() {
    //   if (!this.productCode) return;
      const response = await Workorders.getProductImage(this.productCode);
      var mimeType = response.headers['content-type'].toLowerCase();
      var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
      this.productImage = 'data:' + mimeType + ';base64,' + imgBase64;
    },
  }
};
</script>
<style scoped>
/* .product:hover {
  transform: scale(2);
} */
</style>
