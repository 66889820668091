<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <MenuBar></MenuBar>
    <v-main class="ma-4">
      <router-view></router-view>
	  <circular-progress></circular-progress>
    </v-main>
  </v-app>
</template>
<script>
import MenuBar from '@/components/MenuBar.vue';
export default {
  name: 'App',

  components: {
    MenuBar,
		'circular-progress': require('@/components/Shared/CircularProgress.vue').default,
  },

  data: () => ({
    //
  }),
};
</script>
